import {
  GET_ALL_SALES_REQUEST,
  GET_ALL_SALES_SUCCESS,
  GET_ALL_SALES_FAILURE,
  INVENTORY_REPORT_BLINKIT_REQUEST,
  INVENTORY_REPORT_BLINKIT_SUCCESS,
  INVENTORY_REPORT_BLINKIT_FAILURE,
  INVENTORY_REPORT_INSTAMART_REQUEST,
  INVENTORY_REPORT_INSTAMART_SUCCESS,
  INVENTORY_REPORT_INSTAMART_FAILURE,
  INVENTORY_REPORT_ZEPTO_REQUEST,
  INVENTORY_REPORT_ZEPTO_SUCCESS,
  INVENTORY_REPORT_ZEPTO_FAILURE,
} from "../constants/SalesConstants";

export const getAllSalesRequest = () => {
  return {
    type: GET_ALL_SALES_REQUEST,
  };
};

export const getAllSalesSuccess = (totalSales) => {
  return {
    type: GET_ALL_SALES_SUCCESS,
    payload: totalSales,
  };
};

export const getAllSalesFailure = (error) => {
  return {
    type: GET_ALL_SALES_FAILURE,
    payload: error,
  };
};

export const inventoryReportBlinkitRequest = () => {
  return {
    type: INVENTORY_REPORT_BLINKIT_REQUEST,
  };
};

export const inventoryReportBlinkitSuccess = (report) => {
  return {
    type: INVENTORY_REPORT_BLINKIT_SUCCESS,
    payload: report,
  };
};
export const inventoryReportBlinkitFailure = (error) => {
  return {
    type: INVENTORY_REPORT_BLINKIT_FAILURE,
    payload: error,
  };
};

export const inventoryReportInstamartRequest = () => {
  return {
    type: INVENTORY_REPORT_INSTAMART_REQUEST,
  };
};

export const inventoryReportInstamartSuccess = (report) => {
  return {
    type: INVENTORY_REPORT_INSTAMART_SUCCESS,
    payload: report,
  };
};

export const inventoryReportInstamartFailure = (error) => {
  return {
    type: INVENTORY_REPORT_INSTAMART_FAILURE,
    payload: error,
  };
};

export const inventoryReportZeptoRequest = () => {
  return {
    type: INVENTORY_REPORT_ZEPTO_REQUEST,
  };
};

export const inventoryReportZeptoSuccess = (report) => {
  return {
    type: INVENTORY_REPORT_ZEPTO_SUCCESS,
    payload: report,
  };
};

export const inventoryReportZeptoFailure = (error) => {
  return {
    type: INVENTORY_REPORT_ZEPTO_FAILURE,
    payload: error,
  };
};
