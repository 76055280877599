import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const getGroupedData = (data) => {
  const currentDate = new Date();
  let filteredData = {};
  data.forEach((item) => {
    const poNumber = item["fulfillment_report_instamart.pocode"];
    const city = item["fulfillment_report_instamart.city"];
    const groupKey = `${poNumber}_${city}`;
    const podate = new Date(item["fulfillment_report_instamart.podate"]);
    const expirydate = new Date(item["fulfillment_report_instamart.expirydate"]);
    const lastInwardDate = item["fulfillment_report_instamart.last_inward_date"];

    if (expirydate > podate && podate < currentDate && lastInwardDate === "") {
      if (!filteredData[groupKey]) {
        filteredData[groupKey] = {
          po_number: poNumber,
          city: city,
          item_name: item["fulfillment_report_instamart.skuname"],
          po_state: item["fulfillment_report_instamart.postatus"],
          order_date: item["fulfillment_report_instamart.podate"],
          last_inward_date: item["fulfillment_report_instamart.last_inward_date"],
          expiry_date: item["fulfillment_report_instamart.expirydate"],
        };
      }
    }
  });
  return Object.values(filteredData);
};

const InstamartUpcomingPO = () => {
  const { instamartFulfillment } = useSelector((state) => state.fulfillment);
  const filteredData = getGroupedData(instamartFulfillment.data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
        mt: "1rem",
      }}
    >
      <div className="flex gap-4 items-center p-4 font-bold text-xl">
        <FaRegCalendarAlt />
        Upcoming PO's
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
          <TableHead>
            <TableRow>
              {[
                {
                  id: "po_number_city",
                  label: "PO Number/City",
                },
                {
                  id: "sku_list",
                  label: "SKU List",
                },

                {
                  id: "po_state",
                  label: "PO State",
                },
                {
                  id: "order_date",
                  label: "Order Date",
                },

                {
                  id: "last_inward_date",
                  label: "Last Inward Date",
                },

                {
                  id: "expiry_date",
                  label: "Expiry Date",
                },
              ].map((headCell) => (
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: `${headCell.id === "po_number_city" && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"}`,
                    zIndex: `${headCell.id === "po_number_city" && "3"}`,
                    minWidth: "200px",
                    position: `${headCell.id === "po_number_city" && "sticky"}`,
                    left: `${headCell.id === "po_number_city" && 0}`,
                    bgcolor: `${headCell.id === "po_number_city" && "white"}`,
                  }}
                  key={headCell.id}
                >
                  {headCell.label}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    position: "sticky",
                    left: 0,
                    bgcolor: "white",
                  }}
                >
                  {item.po_number + "_" + item.city}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {item.item_name}
                </TableCell>

                <TableCell sx={{ color: "#667085", fontSize: "1rem", width: "200px" }}>{item.po_state}</TableCell>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {dayjs(item.order_date).format("DD-MMM-YYYY")}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {item?.last_inward_date ? dayjs(item?.last_inward_date).format("DD-MMM-YYYY") : ""}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {dayjs(item.expiry_date).format("DD-MMM-YYYY")}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3", // Left shadow for this cell,
                    position: "sticky",
                    bgcolor: "white",
                    right: 0,
                  }}
                ></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default InstamartUpcomingPO;
