import {
  // Monthly Profitability Constants

  MONTHLY_PROFITABILITY_REQUEST,
  MONTHLY_PROFITABILITY_SUCCESS,
  MONTHLY_PROFITABILITY_FAILURE,

  // City Profitability Constants
  CITY_PROFITABILITY_SUCCESS,
  CITY_PROFITABILITY_REQUEST,
  CITY_PROFITABILITY_FAILURE,

  // Category Profitability Constants
  CATEGORY_PROFITABILITY_REQUEST,
  CATEGORY_PROFITABILITY_SUCCESS,
  CATEGORY_PROFITABILITY_FAILURE,
} from "../constants/profitabilityConstants";

const initialState = {
  // Monthly Profitability

  monthlyProfitability: {
    loading: false,
    data: [],
    error: null,
  },

  cityProfitability: {
    loading: false,
    data: [],
    error: null,
  },

  categoryProfitability: {
    loading: false,
    data: [],
    error: null,
  },
};

export const profitabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    // Monthly Profitability

    case MONTHLY_PROFITABILITY_REQUEST:
      return {
        ...state,
        monthlyProfitability: {
          ...state.monthlyProfitability,
          loading: true,
          error: null,
          data: [],
        },
      };
    case MONTHLY_PROFITABILITY_SUCCESS:
      return {
        ...state,
        monthlyProfitability: {
          ...state.monthlyProfitability,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case MONTHLY_PROFITABILITY_FAILURE:
      return {
        ...state,
        monthlyProfitability: {
          ...state.monthlyProfitability,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    //  City Profitability

    case CITY_PROFITABILITY_REQUEST:
      return {
        ...state,
        cityProfitability: {
          ...state.cityProfitability,
          loading: true,
          error: null,
          data: [],
        },
      };
    case CITY_PROFITABILITY_SUCCESS:
      return {
        ...state,
        cityProfitability: {
          ...state.cityProfitability,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case CITY_PROFITABILITY_FAILURE:
      return {
        ...state,
        cityProfitability: {
          ...state.cityProfitability,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    //Category Profitability
    case CATEGORY_PROFITABILITY_REQUEST:
      return {
        ...state,
        categoryProfitability: {
          ...state.categoryProfitability,
          loading: true,
          error: null,
          data: [],
        },
      };
    case CATEGORY_PROFITABILITY_SUCCESS:
      return {
        ...state,
        categoryProfitability: {
          ...state.categoryProfitability,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case CATEGORY_PROFITABILITY_FAILURE:
      return {
        ...state,
        categoryProfitability: {
          ...state.categoryProfitability,
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
};