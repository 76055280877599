import axios from "axios";

// // For Development
// export const BASE_URL = process.env.REACT_APP_DEVELOPMENT_BACKEND_URL;

// // For Staging
// export const BASE_URL = process.env.REACT_APP_STAGING_BACKEND_URL;

//For Production
export const BASE_URL = process.env.REACT_APP_BACKEND_URL;


export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
