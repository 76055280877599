import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField, // Import TextField for the search bar
} from "@mui/material";
import "./css/CategoryWiseSales.css";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";

// Normalize sales data based on the platform
const normalizeData = (data, platformName) => {
  if (platformName === "Zepto") {
    return {
      platform: "zepto",
      category: data["combined_sales_report_zepto.category"],
      subCategory: data["combined_sales_report_zepto.sub_category"],
      itemName: data["combined_sales_report_zepto.sku_name"],
      mrp: parseFloat(data["combined_sales_report_zepto.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_zepto.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_zepto.brands_choice"],
    };
  } else if (platformName === "Blinkit") {
    return {
      platform: "blinkit",
      category: data["combined_sales_report_blinkit.category"],
      subCategory: data["combined_sales_report_blinkit.sub_category"],
      itemName: data["combined_sales_report_blinkit.item_name"],
      mrp: parseFloat(data["combined_sales_report_blinkit.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_blinkit.qty_sold"]),
      gstRate: 0.18,
      city: data["combined_sales_report_blinkit.brands_choice"],
    };
  } else if (platformName === "Instamart") {
    return {
      platform: "instamart",
      category: data["combined_sales_report_instamart.category"],
      subCategory: data["combined_sales_report_instamart.sub_category"],
      itemName: data["combined_sales_report_instamart.product_name"],
      mrp: parseFloat(data["combined_sales_report_instamart.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_instamart.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_instamart.brands_choice"],
    };
  }
  return null;
};

// Group sales data by category, sub-category, and item
const groupData = (data, platformName) => {
  const grouped = {};

  data.forEach((item) => {
    const normalizedItem = normalizeData(item, platformName);
    if (!normalizedItem) return;

    const { category, subCategory, itemName, mrp, qtySold, gstRate } = normalizedItem;

    const gstAmount = mrp * gstRate;
    const mrpExGst = mrp - gstAmount;

    // Initialize Category
    if (!grouped[category]) {
      grouped[category] = {
        totalQtySold: 0,
        totalMrp: 0,
        totalGst: 0,
        totalMrpExGst: 0,
        subCategories: {},
      };
    }

    // Initialize Sub-Category
    if (!grouped[category].subCategories[subCategory]) {
      grouped[category].subCategories[subCategory] = {
        totalQtySold: 0,
        totalMrp: 0,
        totalGst: 0,
        totalMrpExGst: 0,
        items: {},
      };
    }

    // Initialize Item
    if (!grouped[category].subCategories[subCategory].items[itemName]) {
      grouped[category].subCategories[subCategory].items[itemName] = {
        qtySold,
        mrp,
        gst: gstAmount,
        mrpExGst,
      };
    } else {
      // Accumulate Item Values if duplicate entries exist
      grouped[category].subCategories[subCategory].items[itemName].qtySold += qtySold;
      grouped[category].subCategories[subCategory].items[itemName].mrp += mrp;
      grouped[category].subCategories[subCategory].items[itemName].gst += gstAmount;
      grouped[category].subCategories[subCategory].items[itemName].mrpExGst += mrpExGst;
    }

    // Accumulate Sub-Category Totals
    grouped[category].subCategories[subCategory].totalQtySold += qtySold;
    grouped[category].subCategories[subCategory].totalMrp += mrp;
    grouped[category].subCategories[subCategory].totalGst += gstAmount;
    grouped[category].subCategories[subCategory].totalMrpExGst += mrpExGst;

    // Accumulate Category Totals
    grouped[category].totalQtySold += qtySold;
    grouped[category].totalMrp += mrp;
    grouped[category].totalGst += gstAmount;
    grouped[category].totalMrpExGst += mrpExGst;
  });

  return grouped;
};

// Calculate overall totals from grouped data
const calculateOverallTotals = (groupedData) => {
  let overallTotals = {
    totalQtySold: 0,
    totalMrp: 0,
    totalGst: 0,
    totalMrpExGst: 0,
  };

  Object.keys(groupedData).forEach((category) => {
    overallTotals.totalQtySold += groupedData[category].totalQtySold;
    overallTotals.totalMrp += groupedData[category].totalMrp;
    overallTotals.totalGst += groupedData[category].totalGst;
    overallTotals.totalMrpExGst += groupedData[category].totalMrpExGst;
  });

  return overallTotals;
};

const CategoryWiseSalesTable = ({ platformName }) => {
  // Get sales data from Redux store
  const { totalSales } = useSelector((state) => state.sales);

  // Initialize state for category and sub-category open states, city filter, and search query
  const [openCategories, setOpenCategories] = useState({});
  const [openSubCategories, setOpenSubCategories] = useState({});
  const [selectedCity, setSelectedCity] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  // Display skeleton loader if no sales data
  if (!totalSales?.data?.length) {
    return <TableSkeleton />;
  }

  // Extract unique cities from sales data
  const cities = [...new Set(totalSales.data.map((item) => normalizeData(item, platformName)?.city))].filter(Boolean);

  // Toggle category open state
  const handleCategoryClick = (category) => {
    setOpenCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Toggle sub-category open state
  const handleSubCategoryClick = (category, subCategory) => {
    setOpenSubCategories((prev) => ({
      ...prev,
      [`${category}-${subCategory}`]: !prev[`${category}-${subCategory}`],
    }));
  };

  // Format numbers for display
  const formatNumber = (number) => {
    return number.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Filter data based on selected city and search query
  const filteredData = totalSales.data
    .filter((item) => (selectedCity === "All" ? true : normalizeData(item, platformName)?.city === selectedCity))
    .filter((item) =>
      (normalizeData(item, platformName)?.itemName?.toLowerCase() || "").includes(searchQuery.toLowerCase())
    );

  // Group filtered data by category, sub-category, and item
  const groupedData = groupData(filteredData, platformName);

  // Calculate overall totals for all categories
  const overallTotals = calculateOverallTotals(groupedData);

  return (
    <div className="w-full h-[70vh] overflow-y-auto">
      <div className="flex sticky-container min-w-[100%] justify-between items-center mb-0 bg-white p-3">
        <TextField
          variant="outlined"
          placeholder="Search Category, Sub-category, Product Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "400px",
            "& input::placeholder": {
              animation: "pulse 2s infinite",
            },
          }}
          className="search-bar"
        />
        <div>
          <Select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Select City" }}
            style={{ marginBottom: "10px" }}
          >
            <MenuItem value="All">All Cities</MenuItem>
            {cities.map((city) => (
              <MenuItem key={city} value={city}>
                {city}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <TableContainer className="overflow-x-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "250px", // Set your desired width here
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                }}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Total Quantity Sold
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Total MRP
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                GST
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Total MRP ex GST
              </TableCell>
              <TableCell
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedData).map((category) => {
              const categoryData = groupedData[category];
              return (
                <React.Fragment key={category}>
                  <TableRow
                    hover
                    onClick={() => handleCategoryClick(category)}
                    className={`${openCategories[category] ? "bg-gray-300" : ""} cursor-pointer flex items-center`}
                  >
                    <TableCell
                      sx={{
                        width: "250px",
                        color: "black",
                        fontSize: "1rem",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      }}
                      className="flex items-center justify-between"
                    >
                      <div className="flex flex-row justify-between">
                        <div className="mui-table-css text-left text-[#667085] shiny-text flex items-center">
                          {category}
                        </div>
                        <div>
                          {/* Conditionally render the icons based on the state */}
                          {openCategories[category] ? (
                            <FaCaretUp className="text-[#667085] text-base" />
                          ) : (
                            <FaCaretDown className="text-[#667085] text-base" />
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                      <div className="mui-table-css text-left text-[#667085]">
                        {formatNumber(categoryData.totalQtySold)}
                      </div>
                    </TableCell>
                    <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                      <div className="mui-table-css text-left text-[#667085]">
                        {formatNumber(categoryData.totalMrp)}
                      </div>
                    </TableCell>
                    <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                      <div className="mui-table-css text-left text-[#667085]">
                        {formatNumber(categoryData.totalGst)}
                      </div>
                    </TableCell>
                    <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                      <div className="mui-table-css text-left text-[#667085]">
                        {formatNumber(categoryData.totalMrpExGst)}
                      </div>
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{
                        color: "black",
                        fontSize: "1rem",
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3", // Left shadow for this cell,
                        position: "sticky",
                        bgcolor: "white",
                        right: 0,
                      }}
                    ></TableCell>
                  </TableRow>

                  {openCategories[category] &&
                    Object.keys(categoryData.subCategories).map((subCategory) => {
                      const subCategoryData = categoryData.subCategories[subCategory];
                      return (
                        <React.Fragment>
                          <TableRow
                            key={subCategory}
                            hover
                            onClick={() => handleSubCategoryClick(category, subCategory)}
                            className={`cursor-pointer ${openSubCategories[category] ? "bg-gray-100" : ""}`}
                          >
                            <TableCell
                              sx={{
                                width: "250px", // Set the same width for the body cells as the header cell
                                color: "black",
                                fontSize: "1rem",
                                boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              }}
                              className="flex items-center justify-between"
                            >
                              <div className="flex flex-row justify-between">
                                <div className="mui-table-css text-left text-[#667085] shiny-text">
                                  &nbsp;&nbsp;&nbsp; {subCategory}
                                </div>
                                <div>
                                  {/* Conditionally render the icons based on the state */}
                                  {openSubCategories[`${category}-${subCategory}`] ? (
                                    <FaCaretUp className="text-[#667085] text-base" />
                                  ) : (
                                    <FaCaretDown className="text-[#667085] text-base" />
                                  )}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "black",
                                fontSize: "1rem",
                              }}
                            >
                              <div className="mui-table-css text-left text-[#667085] shiny-text">
                                {formatNumber(subCategoryData.totalQtySold)}
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "black",
                                fontSize: "1rem",
                              }}
                            >
                              <div className="mui-table-css text-left text-[#667085] shiny-text">
                                {formatNumber(subCategoryData.totalMrp)}
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "black",
                                fontSize: "1rem",
                              }}
                            >
                              <div className="mui-table-css text-left text-[#667085] shiny-text">
                                {formatNumber(subCategoryData.totalGst)}
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "black",
                                fontSize: "1rem",
                              }}
                            >
                              <div className="mui-table-css text-left text-[#667085] shiny-text">
                                {formatNumber(subCategoryData.totalMrpExGst)}
                              </div>
                            </TableCell>
                            <TableCell
                              sx={{
                                boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                zIndex: "3",
                                position: "sticky",
                                right: 0,
                                bgcolor: "white",
                              }}
                            ></TableCell>
                          </TableRow>

                          {openSubCategories[`${category}-${subCategory}`] &&
                            Object.keys(subCategoryData.items).map((itemName) => {
                              const itemData = subCategoryData.items[itemName];
                              return (
                                <TableRow key={itemName} hover>
                                  <TableCell
                                    sx={{
                                      width: "200px", // Set the same width for the body cells as the header cell
                                      color: "black",
                                      fontSize: "1rem",
                                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    }}
                                  >
                                    <div className="mui-table-css text-left text-[#667085] shiny-text">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {itemName}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      color: "black",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    <div className="mui-table-css text-left text-[#667085] shiny-text">
                                      {formatNumber(itemData.qtySold)}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      color: "black",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    <div className="mui-table-css text-left text-[#667085] shiny-text">
                                      {formatNumber(itemData.mrp)}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      color: "black",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    <div className="mui-table-css text-left text-[#667085] shiny-text">
                                      {formatNumber(itemData.gst)}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      color: "black",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    <div className="mui-table-css text-left text-[#667085] shiny-text">
                                      {formatNumber(itemData.mrpExGst)}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
            {/* Total Row fot Total */}
            <TableRow>
              <TableCell className="sticky left-0 bg-gray-200">Total</TableCell>

              <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                <div className="mui-table-css text-left text-[#667085] shiny-text">
                  {formatNumber(overallTotals.totalQtySold)}
                </div>
              </TableCell>
              <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                <div className="mui-table-css text-left text-[#667085] shiny-text">
                  {formatNumber(overallTotals.totalMrp)}
                </div>
              </TableCell>
              <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                <div className="mui-table-css text-left text-[#667085] shiny-text">
                  {formatNumber(overallTotals.totalGst)}
                </div>
              </TableCell>
              <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                <div className="mui-table-css text-left text-[#667085] shiny-text">
                  {formatNumber(overallTotals.totalMrpExGst)}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CategoryWiseSalesTable;

// Use Case:

// Imports: Import necessary components and styles.
// Normalization Function: normalizeData standardizes data based on the platform.
// Grouping Function: groupData organizes data by category and sub-category.
// Totals Calculation: calculateOverallTotals computes aggregate metrics.
// Component State: Manages category, sub-category, city, and search query states.
// Rendering: Displays filtered and grouped data in a table format.
