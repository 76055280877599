import React from "react";
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

import { BiTargetLock } from "react-icons/bi";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Error from "../../common/Error/Error";
import NoDataFound from "../../common/NoDataFound/NoDataFound";


// This component renders the Daily Target table for a specific platform (Blinkit, Zepto, or Instamart)
const DailyTarget = ({ platformName }) => {
  // Retrieve targetData and allTargets from the Redux store
  const { targetData, allTargets } = useSelector((state) => state.target);

  // Show loading skeleton if the data is still loading from api
  if (targetData.loading || allTargets.loading) {
    return <TableSkeleton />;
  }

  if (targetData.error) {
    return <Error />;
  }

  // Show "No Data Found" message if there's no data available
  if (!targetData.data || targetData.data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
      }}
    >
      {/* Header for the table, including the title and an icon */}
      <div className="font-semibold text-2xl p-2 bg-white w-full flex gap-2 items-center">
        <BiTargetLock /> Target Tracker
      </div>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
          <TableHead>
            <TableRow>
              {/* Sticky Date column with customized styling */}
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  minWidth: "200px",
                  position: "sticky",
                  left: 0,
                  bgcolor: "white",
                }}
              >
                Date
              </TableCell>

              {/* Other column headers with general styling */}
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  minWidth: "200px",
                }}
              >
                Total Sales
              </TableCell>

              <TableCell
                align="right"
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  minWidth: "200px",
                }}
              >
                Target
              </TableCell>

              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "center",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  minWidth: "200px",
                }}
              >
                Percent Achieved
              </TableCell>

              {/* Empty sticky column to maintain table alignment */}
              <TableCell
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {/* Iterate over the targetData to populate the rows */}
            {targetData?.data?.map((item, index) => {
              // Extract the date based on the platform name
              let date =
                platformName == "Blinkit"
                  ? item["combined_sales_report_blinkit.normalized_date"]
                  : platformName == "Zepto"
                  ? item["combined_sales_report_zepto.normalized_date"]
                  : item["combined_sales_report_instamart.normalized_date"];
              date = date?.substring(0, 10); // Format date to YYYY-MM-DD

              // Find the corresponding target for this date from allTargets
              const target = allTargets.data?.find((targetItem) => {
                let day = targetItem["date"]?.substring(0, 10);
                return day == date;
              });

              // Extract GMV (Gross Merchandise Value) based on the platform name
              const gmv =
                platformName == "Blinkit"
                  ? item["combined_sales_report_blinkit.total_gmv"]
                  : platformName == "Zepto"
                  ? item["combined_sales_report_zepto.total_gross_merchandise_value"]
                  : item["combined_sales_report_instamart.total_gmv"];

              return (
                <TableRow key={index} hover>
                  {/* Sticky Date cell with styling */}
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      minWidth: "200px",
                      position: "sticky",
                      left: 0,
                      bgcolor: "white",
                    }}
                  >
                    {date}
                  </TableCell>

                  {/* GMV (Total Sales) cell */}
                  <TableCell
                    align="right"
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      textAlign: "left",
                    }}
                  >
                    {gmv}
                  </TableCell>

                  {/* Target value cell */}
                  <TableCell
                    align="right"
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      textAlign: "left",
                    }}
                  >
                    {target ? target.totalTarget : 0}
                  </TableCell>

                  {/* Percent Achieved cell with dynamic background color based on performance */}
                  <TableCell
                    align="center"
                    sx={{
                      color: "#ffffff",
                      fontSize: "1rem",
                      minWidth: "200px",
                    }}
                  >
                    {target ? (
                      <div
                        className={`inline-block px-2 py-1 rounded-full text-left ${
                          gmv / (target.totalTarget / 100) >= 50
                            ? "bg-green-500"
                            : item[3] > 20 && item[3] < 50
                            ? "bg-yellow-500"
                            : "bg-red-500"
                        }`}
                      >
                        {(gmv / (target.totalTarget / 100)).toFixed(2)}%
                      </div>
                    ) : (
                      <div className="inline-block px-2 py-1 rounded-full text-left bg-red-500">0%</div>
                    )}
                  </TableCell>

                  {/* Empty sticky cell for alignment */}
                  <TableCell
                    align="right"
                    sx={{
                      color: "black",
                      fontSize: "1rem",
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3",
                      position: "sticky",
                      bgcolor: "white",
                      right: 0,
                    }}
                  ></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DailyTarget;
