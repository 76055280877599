import React from "react";
import { useSelector } from "react-redux"; // Hook to access the Redux store's state
import { Skeleton } from "@mui/material"; // MUI Skeleton component for loading placeholders


import AverageAppointmentTurnAroundTime from "./AverageAppointmentTurnAroundTime";
import UpcomingAppointments from "./UpcomingAppointments";
import BookAppointments from "./BookAppointments";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const FulfillmentBlinkitSectionsContainer = () => {
  // Accessing blinkit fulfillment data and loading state from the Redux store
  const { blinkitFulfillment } = useSelector((state) => state.fulfillment);

  // Check if data is still loading
  if (blinkitFulfillment.loading) {
    return (
      <div>
        {/* Skeleton for a loading state - used as a placeholder */}
        <div className="mb-2 rounded p-2 flex gap-10">
          <Skeleton variant="rounded" sx={{ width: "30%", height: "150px" }} /> {/* Placeholder for rounded content */}
        </div>

        {/* Display skeleton tables while data is loading */}
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton /> {/* First table skeleton */}
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton /> {/* Second table skeleton */}
        </div>
      </div>
    );
  }

  // If there's no data or data is empty, show "No Data Found" component
  if (!blinkitFulfillment.data || blinkitFulfillment.data.length === 0) {
    return <NoDataFound />;
  }

  // If data is available, render the content
  return (
    <>
      <div className="w-full py-2">
        {/* Component to display average appointment turnaround time */}
        <AverageAppointmentTurnAroundTime data={blinkitFulfillment.data} />
      </div>

      <>
        {/* Component to display upcoming appointments */}
        <UpcomingAppointments data={blinkitFulfillment.data} />
        {/* Component to display booking appointments */}
        <BookAppointments data={blinkitFulfillment.data} />
      </>
    </>
  );
};

export default FulfillmentBlinkitSectionsContainer;
