import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchBlinkitData,
  fetchInstamartData,
  fetchZeptoData,
  fetchBlinkitWeeklyData,
  fetchInstamartWeeklyData,
  fetchZeptoWeeklyData,
} from "../../services/inventoryServices/localityAvailabilityServices";
import {
  getThirdPartyAvailabilityRequest,
  getThirdPartyAvailabilitySuccess,
  getThirdPartyAvailabilityFailure,
  fetchAvailabilityWeeklyRequest,
  fetchAvailabilityWeeklySuccess,
  fetchAvailabilityWeeklyFailure,
} from "../../redux/actions/thirdPartyActions";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import MapViewSectionsContainer from "../../components/inventory/LocalityAvailabilityComponents/MapViewSectionsContainer";
import SkuCityLevelAvailabilitySectionsContainer from "../../components/inventory/LocalityAvailabilityComponents/SkuCityLevelAvailabilitySectionsContainer";
import WeeklyLevelOverviewSectionsContainer from "../../components/inventory/LocalityAvailabilityComponents/WeeklyLevelOverviewSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";

// Options for the component switcher (map view, SKU city-level availability, weekly overview)
const options = ["Map View", "Sku City Level Availability", "Weekly Level Overview"];

// Object mapping platform names to data fetch functions for daily and weekly data
const dataFetchFunctions = {
  Blinkit: fetchBlinkitData,
  Instamart: fetchInstamartData,
  Zepto: fetchZeptoData,
};
const dataFetchWeeklyFunctions = {
  Blinkit: fetchBlinkitWeeklyData,
  Zepto: fetchZeptoWeeklyData,
  Instamart: fetchInstamartWeeklyData,
};

const LocalityAvailabilityScreen = () => {
  // Local state to track selected platform and option (view type)
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);

  // Hook to access the selected date from the date picker (single day)
  const { selectedDate } = useDate();

  // Redux dispatch function to trigger actions
  const dispatch = useDispatch();

  // Reset the selected option to default (0) whenever the platform changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  // Fetch data based on the selected platform, option, and date
  useEffect(() => {
    // Function to fetch daily data for the selected platform
    const fetchData = async () => {
      try {
        // Dispatch request action before starting API call
        dispatch(getThirdPartyAvailabilityRequest());
        // Call the API based on the platform and date, then dispatch success action
        const result = await dataFetchFunctions[platformName](selectedDate);
        dispatch(getThirdPartyAvailabilitySuccess(result));
      } catch (error) {
        // Dispatch failure action in case of an error
        dispatch(getThirdPartyAvailabilityFailure(error));
      }
    };

    // Function to fetch weekly data for the selected platform
    const fetchDataWeekly = async () => {
      dispatch(fetchAvailabilityWeeklyRequest());
      try {
        const result = await dataFetchWeeklyFunctions[platformName](selectedDate);
        dispatch(fetchAvailabilityWeeklySuccess(result));
      } catch (error) {
        dispatch(fetchAvailabilityWeeklyFailure(error));
      }
    };

    // Check if the selected option is weekly overview (option 2) or daily
    if (selectedOption === 2) {
      fetchDataWeekly(); // Fetch weekly data
    } else {
      fetchData(); // Fetch daily data
    }
  }, [platformName, selectedOption, selectedDate, dispatch]);

  return (
    <>
      {/* Heading bar component with platform selection and date picker */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />

      {/* Toolbar for switching between different views */}
      <Toolbar>
        {/* Component switch to toggle between map view, SKU city level, and weekly overview */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Render the corresponding container based on the selected option */}
        {selectedOption === 0 && <MapViewSectionsContainer />}
        {selectedOption === 1 && <SkuCityLevelAvailabilitySectionsContainer platformName={platformName} />}
        {selectedOption === 2 && <WeeklyLevelOverviewSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default LocalityAvailabilityScreen;
