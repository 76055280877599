import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getThirdPartyVisibilityRequest,
  getThirdPartyVisibilitySuccess,
  getThirdPartyVisibilityFailure,
} from "../../redux/actions/thirdPartyActions";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import {
  fetchPricingDetailDataBlinkit,
  fetchPricingDetailDataInstamart,
  fetchPricingDetailDataZepto,
} from "../../services/adsServices/pricingDetailServices";
import PricingDetailSectionsContainer from "../../components/ads/PricingDetailComponents/PricingDetailSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import Toolbar from "../../components/Toolbar/Toolbar";

// List of available options for the component switch
const options = ["Pricing Details"];

// Mapping platform names to their corresponding data-fetching functions
const dataFetchFunctions = {
  Blinkit: fetchPricingDetailDataBlinkit,
  Zepto: fetchPricingDetailDataZepto,
  Instamart: fetchPricingDetailDataInstamart,
};

const PricingDetailScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const dispatch = useDispatch();
  const { selectedDate } = useDate();

  // Reset the selected option to the first one whenever the platform name changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  // Fetch data for the selected platform and date whenever platformName or selectedDate changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getThirdPartyVisibilityRequest());

        // Fetch data for the selected platform and date
        const result = await dataFetchFunctions[platformName](selectedDate);
        dispatch(getThirdPartyVisibilitySuccess(result));
      } catch (error) {
        dispatch(getThirdPartyVisibilityFailure(error));
      }
    };
    fetchData();
  }, [platformName, selectedDate, dispatch]);

  return (
    <>
      {/* Heading bar component to switch platforms and display selected date */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />

      {/* Toolbar containing the ComponentSwitch and other UI elements */}
      <Toolbar>
        {/* ComponentSwitch to toggle between options (only "Pricing Details" is available now) */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Render PricingDetailSectionsContainer if the selected option is the first one (0) */}
        {selectedOption === 0 && <PricingDetailSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default PricingDetailScreen;
