import {
  TARGET_TRACKER_DATA_FAILURE,
  TARGET_TRACKER_DATA_REQUEST,
  TARGET_TRACKER_DATA_SUCCESS,
  TARGET_TRACKER_FAILURE,
  TARGET_TRACKER_REQUEST,
  TARGET_TRACKER_SUCCESS,
} from "../constants/tragetTrackerConstants";

const initialState = {
  allTargets: {
    loading: false,
    data: [],
    error: null,
  },
  targetData: {
    loading: false,
    data: [],
    error: null,
  },
};

export const targetTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    // Product Listing cases
    case TARGET_TRACKER_REQUEST:
      return {
        ...state,
        allTargets: {
          ...state.allTargets,
          loading: true,
          error: null,
          data: [],
        },
      };
    case TARGET_TRACKER_SUCCESS:
      return {
        ...state,
        allTargets: {
          ...state.allTargets,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case TARGET_TRACKER_FAILURE:
      return {
        ...state,
        allTargets: {
          ...state.allTargets,
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case TARGET_TRACKER_DATA_REQUEST:
      return {
        ...state,
        targetData: {
          ...state.targetData,
          loading: true,
          error: null,
          data: [],
        },
      };
    case TARGET_TRACKER_DATA_SUCCESS:
      return {
        ...state,
        targetData: {
          ...state.targetData,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case TARGET_TRACKER_DATA_FAILURE:
      return {
        ...state,
        targetData: {
          ...state.targetData,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};
