import Chart from "react-apexcharts";

const FunnelChart = ({ obj }) => {
  const options = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false, // Disable the hamburger menu (toolbar)
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,

        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      style: {
        fontSize: "14px",
        fontFamily: "IBM Plex Sans, sans-serif",

        colors: ["#000"], // Change the text color to Tomato
      },
      dropShadow: {
        enabled: false,
      },
    },
    title: {
      text: obj.cpm ? `CPM : ${(obj.cpm / obj.count).toFixed(0)}`:"",
      align: "center", // 'center' instead of 'middle' for correct alignment
      style: {
        fontSize: "14px", // Adjust font size here
        fontFamily: "IBM Plex Sans, sans-serif",
        color: "#000", // Adjust color if needed
      },
    },
    legend: {
      show: false,
    },
  };

  const series = [
    {
      name: "Metrics",
      data: [
        { x: "Impressions", y: obj.impressions },
        { x: "Add to Cart", y: obj.addToCarts },
        { x: "Total Quantity Sold", y: obj.totalQuantitiesSold },
      ],
    },
  ];

  if (!obj) {
    return <div>No data...</div>;
  }

  return (
    <div className="w-[30%] rounded-lg p-2 border bg-gray-50">
      <h1 className="font-bold text-sm text-center p-1 px-2 bg-gray-200  rounded-lg mt-1 capitalize">
        {obj.title}
      </h1>
      <h2 className="font-bold text-sm text-center">
        Total Spent: {obj.estimateBudget.toFixed(0)}
      </h2>
      <Chart options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default FunnelChart;
