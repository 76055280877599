import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaChartLine } from "react-icons/fa";
import { FiTable } from "react-icons/fi";
import { MenuItem, Select } from "@mui/material";
import PricingDetailBarChart from "./PricingDetailBarChart";
import PricingDetailsTable from "./PricingDetailsTable";
import SelectBoxSkeleton from "../../common/Skeletons/SelectBoxSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Error from "../../common/Error/Error";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const PricingDetailSectionsContainer = () => {
  const { thirdPartyVisibility } = useSelector((state) => state.thirdParty);
  const [selectedAreaName, setSelectedAreaName] = useState(
    thirdPartyVisibility?.data?.length > 0
      ? thirdPartyVisibility.data[0]["postgres_third_party_visibility.area_name_lm"] // Corrected key for areaname
      : null
  );


  const { loading, data, error } = thirdPartyVisibility;

  useEffect(() => {
    setSelectedAreaName(
      thirdPartyVisibility?.data?.length > 0
        ? thirdPartyVisibility.data[0]["postgres_third_party_visibility.area_name_lm"]
        : null
    );
  }, [thirdPartyVisibility.data]);

  // Extract unique  AreaName from the fetched data
  const areaNames = Array.from(new Set(data?.map((item) => item["postgres_third_party_visibility.area_name_lm"])));

  // Display skeleton loaders while data is being fetched
  if (loading) {
    return (
      <>
        <SelectBoxSkeleton /> {/* Show skeleton for the select box while loading */}
        <ChartSkeleton /> {/* Show skeleton for the chart while loading */}
        <TableSkeleton /> {/* Show skeleton for the table while loading */}
      </>
    );
  }

  // Display error component if an error occurs during data fetching
  if (error) {
    return <Error />; // Show error component if there's an issue with data fetching
  }

  // If there is no data or the data array is empty, show the NoDataFound component
  if (!data || data.length === 0) {
    return <NoDataFound />; // Show "No Data Found" component if the data is empty
  }

  return (
    <>
      {/* Dropdown for selecting a AreaName */}
      <div className="gap-3  mt-2 w-full flex font-semibold justify-end items-center p-4">
        <span className="font-bold text-xl">Select Area Name : </span>
        <Select
          className="bg-white"
          value={selectedAreaName}
          sx={{
            width: "20rem", // Set the width of the Select component
          }}
          onChange={(e) => setSelectedAreaName(e.target.value)} // Update selected AreaName on change
          displayEmpty
        >
          {/* Map over the unique areanames and render them as menu items */}
          {areaNames.map((areaName) => (
            <MenuItem key={areaName} value={areaName} selected={selectedAreaName == areaName}>
              {areaName}
            </MenuItem>
          ))}
        </Select>
      </div>

      {/* Section for displaying the pricing chart */}
      {selectedAreaName ? (
        <div className="bg-white flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max shadow-md rounded-xl">
          <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
            <div className="p-2 rounded-full bg-gray-200">
              <FaChartLine className="text-xs font-thin" /> {/* Chart icon */}
            </div>
            Price Chart
          </h1>
          <PricingDetailBarChart selectedAreaName={selectedAreaName} />
        </div>
      ) : (
        <ChartSkeleton /> // Show skeleton if selectedAreaName is not available yet
      )}

      {/* Section for displaying detailed table view */}
      {selectedAreaName ? (
        <div className="bg-white flex flex-col mt-[1rem] w-full overflow-hidden mb-10 h-[80vh] shadow-md rounded-xl">
          <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
            <div className="p-2 rounded-full bg-gray-200">
              <FiTable className="text-base font-thin" /> {/* Table icon */}
            </div>
            Detailed View
          </h1>
          {/* PricingDetailsTable component that shows a detailed table view for the selected areaname and SKU */}
          <PricingDetailsTable selectedAreaName={selectedAreaName} />
        </div>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default PricingDetailSectionsContainer;
