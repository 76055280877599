import {
  THIRDPARTY_AVAILABILITY_REQUEST,
  THIRDPARTY_AVAILABILITY_SUCCESS,
  THIRDPARTY_AVAILABILITY_FAILURE,
  THIRDPARTY_VISIBILITY_REQUEST,
  THIRDPARTY_VISIBILITY_SUCCESS,
  THIRDPARTY_VISIBILITY_FAILURE,
  FETCH_AVAILABILITY_WEEKLY_REQUEST,
  FETCH_AVAILABILITY_WEEKLY_SUCCESS,
  FETCH_AVAILABILITY_WEEKLY_FAILURE,
} from "../constants/thirdPartyConstants";

const initialState = {
  thirdPartyAvailability: {
    loading: false,
    data: [],
    error: null,
  },
  thirdPartyVisibility: {
    loading: false,
    data: [],
    error: null,
  },
  thirdPartyAvailabilityWeekly: {
    loading: false,
    data: [],
    error: null,
  },
};

export const thirdPartyReducer = (state = initialState, action) => {
  switch (action.type) {
    // Product Listing cases
    case THIRDPARTY_AVAILABILITY_REQUEST:
      return {
        ...state,
        thirdPartyAvailability: {
          ...state.thirdPartyAvailability,
          loading: true,
          error: null,
          data: [],
        },
      };
    case THIRDPARTY_AVAILABILITY_SUCCESS:
      return {
        ...state,
        thirdPartyAvailability: {
          ...state.thirdPartyAvailability,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case THIRDPARTY_AVAILABILITY_FAILURE:
      return {
        ...state,
        thirdPartyAvailability: {
          ...state.thirdPartyAvailability,
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    // Third Party Visibility cases
    case THIRDPARTY_VISIBILITY_REQUEST:
      return {
        ...state,
        thirdPartyVisibility: {
          ...state.thirdPartyVisibility,
          loading: true,
          error: null,
          data: [],
        },
      };
    case THIRDPARTY_VISIBILITY_SUCCESS:
      return {
        ...state,
        thirdPartyVisibility: {
          ...state.thirdPartyVisibility,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case THIRDPARTY_VISIBILITY_FAILURE:
      return {
        ...state,
        thirdPartyVisibility: {
          ...state.thirdPartyVisibility,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case FETCH_AVAILABILITY_WEEKLY_REQUEST:
      return {
        ...state,
        thirdPartyAvailabilityWeekly: {
          ...state.thirdPartyAvailabilityWeekly,
          loading: true,
          error: null,
          data: [],
        },
      };
    case FETCH_AVAILABILITY_WEEKLY_SUCCESS:
      return {
        ...state,
        thirdPartyAvailabilityWeekly: {
          ...state.thirdPartyAvailabilityWeekly,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_AVAILABILITY_WEEKLY_FAILURE:
      return {
        ...state,
        thirdPartyAvailabilityWeekly: {
          ...state.thirdPartyAvailabilityWeekly,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};
