export const makeChartDataByCategories = (newChartData, currentPlatform) => {
  let finalChartData = {};
  newChartData.forEach((element) => {
    //date fetch
    const date =
      currentPlatform == "Blinkit"
        ? element["combined_sales_report_blinkit.date"]
        : currentPlatform == "Instamart"
        ? element["combined_sales_report_instamart.date"]
        : element["combined_sales_report_zepto.date"];

    //category fetch
    const category =
      currentPlatform == "Blinkit"
        ? element["combined_sales_report_blinkit.category"]
        : currentPlatform == "Instamart"
        ? element["combined_sales_report_instamart.category"]
        : element["combined_sales_report_zepto.category"];

    //quantity sold fetch
    let qtySold =
      currentPlatform == "Blinkit"
        ? element["combined_sales_report_blinkit.qty_sold"]
        : currentPlatform == "Instamart"
        ? element["combined_sales_report_instamart.qty"]
        : element["combined_sales_report_zepto.qty"];
    if (qtySold === undefined) {
      qtySold = 0;
    }

    //mrp fetch
    const mrp =
      currentPlatform == "Blinkit"
        ? element["combined_sales_report_blinkit.gmv"]
        : currentPlatform == "Instamart"
        ? element["combined_sales_report_instamart.gmv"]
        : element["combined_sales_report_zepto.gmv"];
    if (!finalChartData[date]) {
      finalChartData[date] = {};
    }
    if (!finalChartData[date][category]) {
      finalChartData[date][category] = {
        qtySold: 0,
        sales: 0,
      };
    }

    finalChartData[date][category].qtySold += parseInt(qtySold);
    finalChartData[date][category].sales += parseFloat(mrp);
  });
  return finalChartData; // Adding return statement to get the final chart data
};
