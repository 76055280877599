import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider.js";
import {
  getMonthyProfitabilityRequest,
  getMonthyProfitabilityError,
  getMonthyProfitabilitySuccess,
  getCityProfitabilityRequest,
  getCategoryProfitabilityRequest,
  getCityProfitabilityError,
  getCityProfitabilitySuccess,
  getCategoryProfitabilityError,
  getCategoryProfitabilitySuccess,
} from "../../redux/actions/profitabilityActions.js";
import CityProfitability from "../../components/profitability/OverallProfitabilityComponents/CityProfitability.js";
import CategoryProfitability from "../../components/profitability/OverallProfitabilityComponents/CategoryProfitability.js";
import {
  fetchCategoryProfitabilityDataBlinkit,
  fetchCategoryProfitabilityDataInstamart,
  fetchCategoryProfitabilityDataZepto,
  fetchCityProfitabilityDataBlinkit,
  fetchCityProfitabilityDataInstamart,
  fetchCityProfitabilityDataZepto,
  fetchMonthlyProfitabilityDataBlinkit,
  fetchMonthlyProfitabilityDataInstamart,
  fetchMonthlyProfitabilityDataZepto,
} from "../../services/profitability/overallProfitabilityServices.js";
import MonthlyProfitability from "../../components/profitability/OverallProfitabilityComponents/MonthlyProfitability.js";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar.js";
import Toolbar from "../../components/Toolbar/Toolbar.js";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch.js";
import TableSkeleton from "../../components/common/Skeletons/TableSkeleton.js";
import NoDataFound from "../../components/common/NoDataFound/NoDataFound.js";

const options = ["Monthly Profitability", "City Profitablity", "Category Profitablity"];
const monthHeadings = ["MONTH NAME", "MRP", "GST", "NET SALES", "COGS", "CM1"];
const cityHeadings = ["CITY NAME", "MRP", "GST", "NET SALES", "COGS", "CM1"];
const categoryHeadings = ["CATEGORY NAME", "MRP", "GST", "NET SALES", "COGS", "CM1"];

const OverallProfitabilityScreen = () => {
  const { monthlyProfitability, cityProfitability, categoryProfitability } = useSelector(
    (state) => state.profitability
  );
  const [selectedOption, setSelectedOption] = useState(0);
  const [platformName, setPlatformName] = useState("Blinkit");
  const { selectedDate } = useDate();
  const dispatch = useDispatch();

  // Effect to reset selected option to 0 (Monthly Profitability) when platform changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  useEffect(() => {
    // Dispatch the request action based on the platform name
    if (selectedOption == 0) {
      dispatch(getMonthyProfitabilityRequest());
    } else if (selectedOption == 1) {
      dispatch(getCityProfitabilityRequest());
    } else {
      dispatch(getCategoryProfitabilityRequest());
    }

    // Function to fetch monthly profitability data based on platform
    const fetchDataMonthly = async () => {
      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchMonthlyProfitabilityDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchMonthlyProfitabilityDataZepto(selectedDate);
        } else {
          result = await fetchMonthlyProfitabilityDataInstamart(selectedDate);
        }

        if (!result.length) {
          dispatch(getMonthyProfitabilityError("No data available for the selected date."));
        } else {
          dispatch(getMonthyProfitabilitySuccess(result));
        }
      } catch (error) {
        dispatch(getMonthyProfitabilityError(error));
      }
    };

    // Function to fetch city profitability data based on platform
    const fetchCityData = async () => {
      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchCityProfitabilityDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchCityProfitabilityDataZepto(selectedDate);
        } else {
          result = await fetchCityProfitabilityDataInstamart(selectedDate);
        }

        if (result.length == 0) {
          dispatch(getCityProfitabilityError("No data available for the selected date."));
        } else {
          dispatch(getCityProfitabilitySuccess(result));
        }
      } catch (error) {
        dispatch(getCityProfitabilityError(error));
      }
    };

    // Function to fetch category profitability data based on platform
    const fetchCategoryData = async () => {
      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchCategoryProfitabilityDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchCategoryProfitabilityDataZepto(selectedDate);
        } else {
          result = await fetchCategoryProfitabilityDataInstamart(selectedDate);
        }
        if (result.length == 0) {
          dispatch(getCategoryProfitabilityError("No data available for the selected date."));
        } else {
          dispatch(getCategoryProfitabilitySuccess(result));
        }
      } catch (error) {
        dispatch(getCategoryProfitabilityError("Error fetching data. Please try again later."));
      }
    };

    // Fetch data based on the selected option (Monthly, City, or Category)
    if (selectedOption == 0) {
      fetchDataMonthly();
    } else if (selectedOption == 1) {
      fetchCityData();
    } else {
      fetchCategoryData();
    }
  }, [platformName, selectedOption, selectedDate]);

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 ? (
          <>
            {monthlyProfitability.loading ? (
              <TableSkeleton />
            ) : !monthlyProfitability.data || monthlyProfitability.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <MonthlyProfitability
                text={"Month Wise Profitability"}
                headings={monthHeadings}
                platformName={platformName}
              />
            )}
          </>
        ) : selectedOption === 1 ? (
          <>
            {cityProfitability.loading ? (
              <TableSkeleton />
            ) : !cityProfitability.data || cityProfitability.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <CityProfitability text={"City Wise Profitability"} headings={cityHeadings} platformName={platformName} />
            )}
          </>
        ) : (
          <>
            {categoryProfitability.loading ? (
              <TableSkeleton />
            ) : !categoryProfitability.data || categoryProfitability.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <CategoryProfitability
                text={"Category Wise Profitability"}
                headings={categoryHeadings}
                platformName={platformName}
              />
            )}
          </>
        )}
      </Toolbar>
    </>
  );
};

export default OverallProfitabilityScreen;
