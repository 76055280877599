export const groupDatabyCampaignNameZepto = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const key =
      item["marketting_report_zepto_campaign_performance.campaign_name"];

    if (!acc[key]) {
      acc[key] = {
        title:
          item["marketting_report_zepto_campaign_performance.campaign_name"],
        ccDate: item["marketting_report_zepto_campaign_performance.cc_date"],
        revenue: 0,
        roi: 0,
        status: new Set(),
        spend: 0,
        c_daily_budget: 0,
        impressions: 0,
        clicks: 0,
        isCampaign: true,
      };
    }

    // Aggregate the fields
    acc[key].revenue +=
      parseFloat(
        item["marketting_report_zepto_campaign_performance.revenue"]
      ) || 0;
    acc[key].roi +=
      parseFloat(item["marketting_report_zepto_campaign_performance.roi"]) || 0;
    acc[key].status.add(
      item["marketting_report_zepto_campaign_performance.status"]
    );
    acc[key].spend +=
      parseFloat(item["marketting_report_zepto_campaign_performance.spend"]) ||
      0;
    acc[key].c_daily_budget +=
      parseFloat(
        item["marketting_report_zepto_campaign_performance.curr_daily_budget"]
      ) || 0;
    acc[key].impressions +=
      parseInt(
        item["marketting_report_zepto_campaign_performance.impressions"],
        10
      ) || 0;
    acc[key].clicks +=
      parseInt(
        item["marketting_report_zepto_campaign_performance.clicks"],
        10
      ) || 0;


    return acc;
  }, {});

  return groupedData;
};

export const groupByDateZeptoPerformance = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const cc_date =
      item["marketting_report_zepto_campaign_performance.cc_date"];

    if (!acc[cc_date]) {
      acc[cc_date] = {
        reportDate:
          item["marketting_report_zepto_campaign_performance.date_of_report"],
        revenue: 0,
        tenant_id:
          item["marketting_report_zepto_campaign_performance.tenant_id"],
        page_type: [],
        camp_end_date: new Set(),
        roi: 0,
        status: new Set(),
        spend: 0,
        c_daily_budget: 0,
        impressions: 0,
        clicks: 0,
        current_date: new Set(),
      };
    }

    // Aggregate the fields
    acc[cc_date].revenue +=
      parseFloat(
        item["marketting_report_zepto_campaign_performance.revenue"]
      ) || 0;
    acc[cc_date].roi +=
      parseFloat(item["marketting_report_zepto_campaign_performance.roi"]) || 0;
    acc[cc_date].status.add(
      item["marketting_report_zepto_campaign_performance.status"]
    );
    acc[cc_date].spend +=
      parseFloat(item["marketting_report_zepto_campaign_performance.spend"]) ||
      0;
    acc[cc_date].c_daily_budget +=
      parseFloat(
        item["marketting_report_zepto_campaign_performance.curr_daily_budget"]
      ) || 0;
    acc[cc_date].impressions +=
      parseInt(
        item["marketting_report_zepto_campaign_performance.impressions"],
        10
      ) || 0;
    acc[cc_date].clicks +=
      parseInt(
        item["marketting_report_zepto_campaign_performance.clicks"],
        10
      ) || 0;
    acc[cc_date].current_date.add(
      item["marketting_report_zepto_campaign_performance.current_date"]
    );

    return acc;
  }, {});
  return groupedData;
};

// accordian table grouping
export function groupByCampaignPerformanceTable(data) {
  const grouped = {};

  data.forEach((item) => {
    const campaignName =
      item["marketting_report_zepto_campaign_performance.campaign_name"];

    // Initialize the campaign array if it doesn't exist
    if (!grouped[campaignName]) {
      grouped[campaignName] = [];
    }

    // Create a key-value pair from the item
    const keyValue = {
      campaignCreationDate:
        item["marketting_report_zepto_campaign_performance.cc_date"],
      revenue: item["marketting_report_zepto_campaign_performance.revenue"],
      roi: item["marketting_report_zepto_campaign_performance.roi"],
      status: item["marketting_report_zepto_campaign_performance.status"],
      spend: item["marketting_report_zepto_campaign_performance.spend"],
      currentDailyBudget:
        item["marketting_report_zepto_campaign_performance.curr_daily_budget"],
      impressions:
        item["marketting_report_zepto_campaign_performance.impressions"],
      clicks: item["marketting_report_zepto_campaign_performance.clicks"],

    };

    // Push the key-value pair into the corresponding campaign array
    grouped[campaignName].push(keyValue);
  });

  return grouped;
}
