import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";

const Layout = ({ children, collapsed, setCollapsed }) => {
  const { pathname } = useLocation();

  return (
    <div className="flex w-screen h-auto bg-bodyBackgroundColor">
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <div
        className={`flex flex-col flex-grow h-auto ${
          !collapsed
            ? "ml-[250px] max-w-[calc(100vw-250px)]"
            : "ml-20 max-w-[calc(100vw-80px)]"
        } overflow-x-hidden`}
      >
        {pathname !== "/login" && <Navbar />}
        {pathname !== "/login" && <hr />}
        {children}
      </div>
    </div>
  );
};

export default React.memo(Layout);
