import { Skeleton } from "@mui/material";
import React from "react";

const RowOfTable = () => (
  <div className="flex justify-between items-center mb-4">
    <Skeleton variant="rounded" sx={{ width: "15%", height: "2" }} />
    <Skeleton variant="rounded" sx={{ width: "15%", height: "2" }} />
    <Skeleton variant="rounded" sx={{ width: "15%", height: "2" }} />
    <Skeleton variant="rounded" sx={{ width: "15%", height: "2" }} />
    <Skeleton variant="rounded" sx={{ width: "15%", height: "2" }} />
    <Skeleton variant="rounded" sx={{ width: "15%", height: "2" }} />
  </div>
);

const TableSkeleton = () => {
  return (
    <div className="p-4">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
        <RowOfTable key={index} />
      ))}
    </div>
  );
};

export default TableSkeleton;
