import React from "react";
import { useSelector } from "react-redux";
import { FaChartArea } from "react-icons/fa"; // Importing chart icon
import { BiSolidCategoryAlt } from "react-icons/bi"; // Importing category icon
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton"; // Loading skeleton for charts
import TableSkeleton from "../../common/Skeletons/TableSkeleton"; // Loading skeleton for tables
import NoDataFound from "../../common/NoDataFound/NoDataFound"; // Component to display when there's no data
import DateWiseSalesLineChart from "./DateWiseSalesLineChart"; // Component for date-wise sales chart
import CategoryWiseSalesTable from "./CategoryWiseSalesTable"; // Component for category-wise sales table

const CategorySkuSplitSectionsContainer = ({ platformName }) => {
  // Get total sales data from Redux store
  const { totalSales } = useSelector((state) => state.sales);

  // Show loading skeletons if data is still being fetched
  if (totalSales.loading) {
    return (
      <>
        {/* Display loading placeholders for both chart and table */}
        <ChartSkeleton />
        <TableSkeleton />
      </>
    );
  }

  // Show 'No Data Found' component if there's no data available.
  if (!totalSales.data || totalSales.data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      {/* Date Wise Overview Section */}
      <div className="bg-white shadow-md rounded-xl mb-4 mt-4">
        <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
          <div>
            {/* Icon for Date Wise Overview */}
            <FaChartArea />
          </div>
          Date Wise Overview
        </h1>
        <div>
          {/* Date-wise sales line chart */}
          <DateWiseSalesLineChart />
        </div>
      </div>

      {/* Category Wise Sales Section */}
      <div className="bg-white shadow-md rounded-xl mb-4 ">
        <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
          <div>
            {/* Icon for Category Wise Sales */}
            <BiSolidCategoryAlt />
          </div>
          Category Wise Sales
        </h1>
        <div>
          {/* Category-wise sales table based on selected platformName */}
          <CategoryWiseSalesTable platformName={platformName} />
        </div>
      </div>
    </div>
  );
};

export default CategorySkuSplitSectionsContainer;

// Indexing of the code:

// --Date Wise Overview Section:
// A component displays a date-wise line chart (DateWiseSalesLineChart).
// Use case: Visualize sales trends over time.

// --Category Wise Sales Section:
// A second component shows a table of category-wise sales (CategoryWiseSalesTable).
// Use case: Display detailed sales breakdown by category based on the selected platform.
