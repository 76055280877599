import Chart from "react-apexcharts";

const AdsFunnel = ({ obj }) => {
  const objProduct = [
    { x: "Impressions", y: obj.impressions },
    { x: "Details Page Views", y: obj.details },
    { x: "Clicks", y: obj.clicks },
  ];
  const objCampaign = [
    { x: "Impressions", y: obj.impressions },
    { x: "Clicks", y: obj.clicks },
  ];
  let cpc = obj.spend / obj.clicks;

  let roi = obj.revenue / obj.spend;
  if (isNaN(cpc)) {
    cpc = 0;
  }
  if (isNaN(roi)) {
    roi = 0;
  }


  const options = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false, // Disable the hamburger menu (toolbar)
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,

        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      style: {
        fontSize: "14px",
        fontFamily: "IBM Plex Sans, sans-serif",

        colors: ["#000"], // Change the text color to black
      },
      dropShadow: {
        enabled: false,
      },
    },
    title: {
      text: `ROI : ${roi.toFixed(2)}`,
      align: "center", // 'center' instead of 'middle' for correct alignment
      style: {
        fontSize: "14px", // Adjust font size here
        fontFamily: "IBM Plex Sans, sans-serif",
        color: "#000", // Adjust color if needed
      },
    },
    legend: {
      show: false,
    },
  };

  const series = [
    {
      name: "Metrics",
      data: obj.ccDate ? objCampaign : objProduct,
    },
  ];

  if (!obj) {
    return <div>No data...</div>;
  }

  return (
    <div className="w-[30%] rounded-lg p-2 border bg-gray-50">
      <h1 className="font-bold text-sm text-center p-1 px-2 bg-gray-200  rounded-lg mt-1 capitalize">
        {obj.title}
      </h1>
      {obj.ccDate && (
        <h2 className="text-center font-bold text-sm">
          Creation Date : {obj.ccDate}
        </h2>
      )}
      <h2 className="font-bold text-sm text-center">
        Total Spent : {obj.spend.toFixed(0)}
      </h2>
      <div className="text-center font-bold text-sm">
        CPC : {cpc.toFixed(0)}
      </div>
      <Chart options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default AdsFunnel;
