import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // Hooks for Redux dispatch and state
import {
  stockingInsightsBlinkitRequest,
  stockingInsightsBlinkitSuccess,
  stockingInsightsBlinkitFailure,
  stockingInsightsInstamartRequest,
  stockingInsightsInstamartSuccess,
  stockingInsightsInstamartFailure,
  stockingInsightsZeptoRequest,
  stockingInsightsZeptoSuccess,
  stockingInsightsZeptoFailure,
} from "../../redux/actions/stockingInsightsActions"; // Redux actions for different platforms
import {
  fetchStockingInsightsBlinkitData,
  fetchStockingInsightsInstamartData,
  fetchStockingInsightsZeptoData,
} from "../../services/inventoryServices/stockingInsightsServices"; // Fetching services for stocking data
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider"; // Custom hook to get selected date
import OutOfStockTableBlinkit from "../../components/inventory/StockingInsightsComponents/OutOfStockTableBlinkit";
import OutOfStockTableZepto from "../../components/inventory/StockingInsightsComponents/OutOfStockTableZepto";
import OutOfStockTableInstamart from "../../components/inventory/StockingInsightsComponents/OutOfStockTableInstamart";
import UnderStockTableZepto from "../../components/inventory/StockingInsightsComponents/UnderStockTableZepto";
import UnderStockedTableInstamart from "../../components/inventory/StockingInsightsComponents/UnderStockedTableInstamart";
import OverStockedTableBlinkit from "../../components/inventory/StockingInsightsComponents/OverStockedTableBlinkit";
import OverStockedTableZepto from "../../components/inventory/StockingInsightsComponents/OverStockedTableZepto";
import UnderStockedTableBlinkit from "../../components/inventory/StockingInsightsComponents/UnderStockedTableBlinkit";
import OverStockedTableInstamart from "../../components/inventory/StockingInsightsComponents/OverStockedTableInstamart";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import TableSkeleton from "../../components/common/Skeletons/TableSkeleton";
import NoDataFound from "../../components/common/NoDataFound/NoDataFound";

// Options for ComponentSwitch
const options = ["Currently OOS", "Understocking", "Overstocking"];

const StockingInsightsScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit"); // State to store the selected platform
  const dispatch = useDispatch(); // Redux dispatch hook
  const [selectedOption, setSelectedOption] = useState(0); // State for selected view option (OOS, understock, overstock)

  // Selectors for stocking data of each platform from the Redux store
  const { stockingInsightBlinkit, stockingInsightZepto, stockingInsightInstamart } = useSelector(
    (state) => state.stocking
  );

  // Hook to get the selected single day date from the date picker
  const { selectedDate } = useDate();

  // Reset selected option to default (0) whenever the platform changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  // Function to handle Blinkit queries
  const handleBlinkitQueries = async () => {
    try {
      dispatch(stockingInsightsBlinkitRequest()); // Dispatch Blinkit request action
      const result = await fetchStockingInsightsBlinkitData(selectedDate); // Fetch Blinkit data for the selected date
      dispatch(stockingInsightsBlinkitSuccess(result)); // Dispatch success action with fetched data
    } catch (error) {
      dispatch(stockingInsightsBlinkitFailure(error)); // Dispatch failure action if an error occurs
    }
  };

  // Function to handle Instamart queries
  const handleInstamartQueries = async () => {
    try {
      dispatch(stockingInsightsInstamartRequest()); // Dispatch Instamart request action
      const result = await fetchStockingInsightsInstamartData(selectedDate); // Fetch Instamart data for the selected date
      dispatch(stockingInsightsInstamartSuccess(result)); // Dispatch success action with fetched data
    } catch (error) {
      dispatch(stockingInsightsInstamartFailure(error)); // Dispatch failure action if an error occurs
    }
  };

  // Function to handle Zepto queries
  const handleZeptoQueries = async () => {
    try {
      dispatch(stockingInsightsZeptoRequest()); // Dispatch Zepto request action
      const result = await fetchStockingInsightsZeptoData(selectedDate); // Fetch Zepto data for the selected date
      dispatch(stockingInsightsZeptoSuccess(result)); // Dispatch success action with fetched data
    } catch (error) {
      dispatch(stockingInsightsZeptoFailure(error)); // Dispatch failure action if an error occurs
    }
  };

  // useEffect to trigger queries whenever platform, selectedDate, or selectedOption changes
  useEffect(() => {
    if (platformName === "Blinkit") {
      handleBlinkitQueries(); // Fetch Blinkit data
    } else if (platformName === "Instamart") {
      handleInstamartQueries(); // Fetch Instamart data
    } else {
      handleZeptoQueries(); // Fetch Zepto data
    }
  }, [platformName, selectedDate, selectedOption]);

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />{" "}
      {/* Render heading bar with platform and date */}
      <Toolbar>
        {" "}
        {/* Toolbar for switching between OOS, understocking, and overstocking */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />{" "}
        {/* Render ComponentSwitch */}
        <div>
          {/* Conditional rendering based on selected option */}
          {selectedOption === 0 && (
            <div className="rounded-lg w-auto flex flex-col items-center mt-[1rem]">
              {platformName === "Blinkit" && (
                <>
                  {stockingInsightBlinkit?.loading ? (
                    <div className="w-full">
                      <TableSkeleton /> {/* Show skeleton while loading */}
                    </div>
                  ) : !stockingInsightBlinkit.data || stockingInsightBlinkit.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <OutOfStockTableBlinkit />
                  )}
                </>
              )}
              {platformName === "Zepto" && (
                <>
                  {stockingInsightZepto?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightZepto.data || stockingInsightZepto.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <OutOfStockTableZepto />
                  )}
                </>
              )}
              {platformName === "Instamart" && (
                <>
                  {stockingInsightInstamart?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightInstamart.data || stockingInsightInstamart.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <OutOfStockTableInstamart />
                  )}
                </>
              )}
            </div>
          )}

          {selectedOption === 1 && (
            <div className="rounded-lg w-auto flex flex-col items-center mt-[1rem]">
              {platformName === "Blinkit" && (
                <>
                  {stockingInsightBlinkit?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightBlinkit.data || stockingInsightBlinkit.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <UnderStockedTableBlinkit />
                  )}
                </>
              )}
              {platformName === "Zepto" && (
                <>
                  {stockingInsightZepto?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightZepto.data || stockingInsightZepto.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <UnderStockTableZepto />
                  )}
                </>
              )}
              {platformName === "Instamart" && (
                <>
                  {stockingInsightInstamart?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightInstamart.data || stockingInsightInstamart.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <UnderStockedTableInstamart />
                  )}
                </>
              )}
            </div>
          )}

          {selectedOption === 2 && (
            <div className="rounded-lg w-auto flex flex-col items-center mt-[1rem]">
              {platformName === "Blinkit" && (
                <>
                  {stockingInsightBlinkit?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightBlinkit.data || stockingInsightBlinkit.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <OverStockedTableBlinkit />
                  )}
                </>
              )}
              {platformName === "Zepto" && (
                <>
                  {stockingInsightZepto?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightZepto.data || stockingInsightZepto.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <OverStockedTableZepto />
                  )}
                </>
              )}
              {platformName === "Instamart" && (
                <>
                  {stockingInsightInstamart?.loading ? (
                    <div className="w-full">
                      <TableSkeleton />
                    </div>
                  ) : !stockingInsightInstamart.data || stockingInsightInstamart.data.length === 0 ? (
                    <NoDataFound message="No data found" />
                  ) : (
                    <OverStockedTableInstamart />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Toolbar>
    </>
  );
};

export default StockingInsightsScreen;
