export const POTENTIAL_SALES_LOSS_BLINKIT_REQUEST =
  "POTENTIAL_SALES_LOSS_BLINKIT_REQUEST";
export const POTENTIAL_SALES_LOSS_BLINKIT_SUCCESS =
  "POTENTIAL_SALES_LOSS_BLINKIT_SUCCESS";
export const POTENTIAL_SALES_LOSS_BLINKIT_FAILURE =
  "POTENTIAL_SALES_LOSS_BLINKIT_FAILURE";

export const POTENTIAL_SALES_LOSS_ZEPTO_REQUEST =
  "POTENTIAL_SALES_LOSS_ZEPTO_REQUEST";
export const POTENTIAL_SALES_LOSS_ZEPTO_SUCCESS =
  "POTENTIAL_SALES_LOSS_ZEPTO_SUCCESS";
export const POTENTIAL_SALES_LOSS_ZEPTO_FAILURE =
  "POTENTIAL_SALES_LOSS_ZEPTO_FAILURE";

export const POTENTIAL_SALES_LOSS_INSTAMART_REQUEST =
  "POTENTIAL_SALES_LOSS_INSTAMART_REQUEST";
export const POTENTIAL_SALES_LOSS_INSTAMART_SUCCESS =
  "POTENTIAL_SALES_LOSS_INSTAMART_SUCCESS";
export const POTENTIAL_SALES_LOSS_INSTAMART_FAILURE =
  "POTENTIAL_SALES_LOSS_INSTAMART_FAILURE";
