// import React, { useCallback, useEffect, useState } from "react";
// import { BsGraphUpArrow } from "react-icons/bs";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
//   CategoryScale,
// } from "chart.js";

// ChartJS.register(
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
//   CategoryScale
// );

// const AdsChart = ({ groupedData }) => {
//   const data = groupedData;
//   const [visibleLabels, setVisibleLabels] = useState({
//     "Total Spent": true,
//     "Total Impressions": true,
//     "Total Clicks": true,
//     ROI: true,
//     CPC: true,
//   });

//   // Get and sort dates
//   const dates = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));

//   // Prepare datasets
//   const totalSpent = dates.map((date) => data[date].spend);
//   const totalImpressions = dates.map((date) => data[date].impressions);
//   const totalClicks = dates.map((date) => data[date].clicks);
//   const roi = dates.map((date) => data[date].roi);
//   const cpc = dates.map((date) =>
//     data[date].clicks ? data[date].spend / data[date].clicks : 0
//   );

//   const chartData = {
//     labels: dates,
//     datasets: [
//       {
//         label: "Total Spent",
//         data: totalSpent,
//         borderColor: "rgba(255, 99, 132, 1)",
//         backgroundColor: "rgba(255, 99, 132, 0.2)",
//         fill: false,
//         tension: 0.1,
//         yAxisID: "y",
//         hidden: !visibleLabels["Total Spent"],
//       },
//       {
//         label: "Total Impressions",
//         data: totalImpressions,
//         borderColor: "rgba(54, 162, 235, 1)",
//         backgroundColor: "rgba(54, 162, 235, 0.2)",
//         fill: false,
//         tension: 0.1,
//         yAxisID: "y",
//         hidden: !visibleLabels["Total Impressions"],
//       },
//       {
//         label: "Total Clicks",
//         data: totalClicks,
//         borderColor: "rgba(75, 192, 192, 1)",
//         backgroundColor: "rgba(75, 192, 192, 0.2)",
//         fill: false,
//         tension: 0.1,
//         yAxisID: "yRight",
//         hidden: !visibleLabels["Total Clicks"],
//       },
//       {
//         label: "ROI",
//         data: roi,
//         borderColor: "rgba(153, 102, 255, 1)",
//         backgroundColor: "rgba(153, 102, 255, 0.2)",
//         fill: false,
//         tension: 0.1,
//         yAxisID: "yRight",
//         hidden: !visibleLabels["ROI"],
//       },
//       {
//         label: "CPC",
//         data: cpc,
//         borderColor: "rgba(255, 159, 64, 1)",
//         backgroundColor: "rgba(255, 159, 64, 0.2)",
//         fill: false,
//         tension: 0.1,
//         yAxisID: "yRight",
//         hidden: !visibleLabels["CPC"],
//       },
//     ],
//   };

//   const options = {
//     scales: {
//       y: {
//         type: "linear",
//         position: "left",
//         title: {
//           display: true,
//           text:
//             visibleLabels["Total Spent"] || visibleLabels["Total Impressions"]
//               ? "Amount"
//               : "",
//         },
//       },
//       yRight: {
//         type: "linear",
//         position: "right",
//         title: {
//           display: true,
//           text:
//             visibleLabels["Total Clicks"] ||
//             visibleLabels["ROI"] ||
//             visibleLabels["CPC"]
//               ? "Metrics"
//               : "",
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: "Dates",
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         onClick: (e, legendItem) => {
//           const index = legendItem.datasetIndex;
//           const label = chartData.datasets[index].label;
//           setVisibleLabels((prev) => ({
//             ...prev,
//             [label]: !prev[label],
//           }));
//         },
//       },
//     },
//   };

//   return <Line data={chartData} options={options} />;
// };

// export default AdsChart;

import React, { useState, useCallback, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import { BsGraphUpArrow } from "react-icons/bs";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const DisplayAdsChart = ({ groupedData }) => {
  const [hiddenDatasets, setHiddenDatasets] = useState([]);
  const [showLeftYAxis, setShowLeftYAxis] = useState(true);
  const [showRightYAxis, setShowRightYAxis] = useState(true);

  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    const [dayA, monthA, yearA] = a.split("-").map(Number);
    const [dayB, monthB, yearB] = b.split("-").map(Number);
    return (
      new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB)
    );
  });

  const chartData = {
    labels: sortedDates,
    datasets: [
      {
        label: "Total Spent",
        data: sortedDates.map((date) => groupedData[date].spend),
        borderColor: "#FF9F40",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        pointBorderColor: "#FF9F40",
        pointBackgroundColor: "#FF9F40",
        yAxisID: "left-y-axis",
        hidden: hiddenDatasets.includes("Total Spent"),
        tension: 0.3
      },
      {
        label: "Total Impressions",
        data: sortedDates.map((date) => groupedData[date].impressions),
        borderColor: "#9966FF",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        pointBorderColor: "#9966FF",
        pointBackgroundColor: "#9966FF",
        yAxisID: "left-y-axis",
        hidden: hiddenDatasets.includes("Total Impressions"),
        tension: 0.3
      },
      {
        label: "Total Clicks",
        data: sortedDates.map((date) => groupedData[date].clicks),
        borderColor: "#36A2EB",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        pointBorderColor: "#36A2EB",
        pointBackgroundColor: "#36A2EB",
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("Total Clicks"),
        tension: 0.3
      },
      {
        label: "ROI",
        data: sortedDates.map(
          (date) => groupedData[date].revenue / groupedData[date].spend
        ),
        borderColor: "#4BC0C0",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        pointBorderColor: "#4BC0C0",
        pointBackgroundColor: "#4BC0C0",
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("ROI"),
        tension: 0.3
      },
      {
        label: "CPC",
        data: sortedDates.map(
          (date) => groupedData[date].spend / groupedData[date].clicks
        ),
        borderColor: "#FF6384",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        pointBorderColor: "#FF6384",
        pointBackgroundColor: "#FF6384",
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("CPC"),
        tension: 0.3
      },
    ],
  };

  const getAxisTitles = useCallback(() => {
    const leftAxisLabels = chartData.datasets
      .filter((dataset) => dataset.yAxisID === "left-y-axis" && !dataset.hidden)
      .map((dataset) => dataset.label)
      .join(" & ");

    const rightAxisLabels = chartData.datasets
      .filter(
        (dataset) => dataset.yAxisID === "right-y-axis" && !dataset.hidden
      )
      .map((dataset) => dataset.label)
      .join(" & ");

    return {
      left: leftAxisLabels || "Total Spent & Total Impressions",
      right: rightAxisLabels || "Total Clicks, ROI & CPC",
    };
  }, [hiddenDatasets, chartData.datasets]);

  const updateAxisVisibility = useCallback(() => {
    const leftYAxisHidden = chartData.datasets.every(
      (dataset) =>
        dataset.yAxisID === "left-y-axis" &&
        hiddenDatasets.includes(dataset.label)
    );

    const rightYAxisHidden = chartData.datasets.every(
      (dataset) =>
        dataset.yAxisID === "right-y-axis" &&
        hiddenDatasets.includes(dataset.label)
    );

    setShowLeftYAxis(!leftYAxisHidden);
    setShowRightYAxis(!rightYAxisHidden);
  }, [hiddenDatasets, chartData.datasets]);

  useEffect(() => {
    updateAxisVisibility();
  }, [hiddenDatasets, updateAxisVisibility]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          padding: 20,
        },
        onClick: (event, legendItem) => {
          const datasetLabel = legendItem.text;
          setHiddenDatasets((prev) =>
            prev.includes(datasetLabel)
              ? prev.filter((label) => label !== datasetLabel)
              : [...prev, datasetLabel]
          );
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0,0,0,0.7)",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 12,
        },
        cornerRadius: 4,
        padding: 10,
      },
    },
    scales: {
      "left-y-axis": {
        display: showLeftYAxis,
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: getAxisTitles().left,
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#333",
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
        },
        grid: {
          drawBorder: false,
        },
        suggestedMin: 0, // Adjust to ensure visibility
        suggestedMax: Math.max(
          ...chartData.datasets
            .filter((dataset) => dataset.yAxisID === "left-y-axis")
            .flatMap((dataset) => dataset.data)
        ),
      },
      "right-y-axis": {
        display: showRightYAxis,
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: getAxisTitles().right,
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#333",
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
        },
        grid: {
          drawBorder: false,
        },
        suggestedMin: 0, // Adjust to ensure visibility
        suggestedMax: Math.max(
          ...chartData.datasets
            .filter((dataset) => dataset.yAxisID === "right-y-axis")
            .flatMap((dataset) => dataset.data)
        ),
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Dates",
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#333",
        },
        ticks: {
          color: "#333",
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
      line: {
        borderWidth: 3,
      },
    },
  };

  return (
    <div className="shadow-md rounded-xl mb-8 mt-4 bg-white">
      <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
        <div className="p-2 rounded-full bg-gray-200">
          <BsGraphUpArrow />
        </div>
        Date Wise Overview
      </h1>
      <div className="relative h-96 p-6">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default DisplayAdsChart;
