import {
  PSL_BLINKIT_FULFILLMENT_REQUEST,
  PSL_BLINKIT_FULFILLMENT_SUCCESS,
  PSL_BLINKIT_FULFILLMENT_FAILURE,
  PSL_ZEPTO_FULFILLMENT_REQUEST,
  PSL_ZEPTO_FULFILLMENT_SUCCESS,
  PSL_ZEPTO_FULFILLMENT_FAILURE,
  PSL_INSTAMRT_FULFILLMENT_REQUEST,
  PSL_INSTAMRT_FULFILLMENT_SUCCESS,
  PSL_INSTAMRT_FULFILLMENT_FAILURE,
} from "../constants/pslFulfillmentConstants";

export const getPslBlinkitFulfillmentRequest = () => {
  return {
    type: PSL_BLINKIT_FULFILLMENT_REQUEST,
  };
};

export const getPslBlinkitFulfillmentSuccess = (report) => {
  return {
    type: PSL_BLINKIT_FULFILLMENT_SUCCESS,
    payload: report,
  };
};

export const getPslBlinkitFulfillmentFailure = (error) => {
  return {
    type: PSL_BLINKIT_FULFILLMENT_FAILURE,
    payload: error,
  };
};

export const getPslZeptoFulfillmentRequest = () => {
  return {
    type: PSL_ZEPTO_FULFILLMENT_REQUEST,
  };
};

export const getPslZeptoFulfillmentSuccess = (report) => {
  return {
    type: PSL_ZEPTO_FULFILLMENT_SUCCESS,
    payload: report,
  };
};

export const getPslZeptoFulfillmentFailure = (error) => {
  return {
    type: PSL_ZEPTO_FULFILLMENT_FAILURE,
    payload: error,
  };
};

export const getPslInstamartFulfillmentRequest = () => {
  return {
    type: PSL_INSTAMRT_FULFILLMENT_REQUEST,
  };
};

export const getPslInstamartFulfillmentSuccess = (report) => {
  return {
    type: PSL_INSTAMRT_FULFILLMENT_SUCCESS,
    payload: report,
  };
};

export const getPslInstamartFulfillmentFailure = (error) => {
  return {
    type: PSL_INSTAMRT_FULFILLMENT_FAILURE,
    payload: error,
  };
};
