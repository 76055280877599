import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import KeywordBasedOwnBrandRankingModal from "./KeywordBasedOwnBrandRankingModal";
import KeywordBasedCompetitorsRankingModal from "./KeywordBasedCompetitorsRankingModal";

const transformData = (data) => {
  if (!Array.isArray(data)) {
    console.error("Expected data to be an array.");
    return [];
  }

  const groupedData = {};

  // Group the data by SKU name
  data.forEach((item) => {
    const {
      "postgres_third_party_visibility.sku_name": skuName,
      "postgres_third_party_visibility.area_name_lm": areaName,
      "postgres_third_party_visibility.account": account,
      "postgres_third_party_visibility.searchkeyword": searchKeyword,
      "postgres_third_party_visibility.slot": slot,
      "postgres_third_party_visibility.brand": brand,
      "postgres_third_party_visibility.productname": productName,
      "postgres_third_party_visibility.organic_rank": organicRank,
      "postgres_third_party_visibility.sponsored_rank": sponsoredRank,
    } = item;

    if (!groupedData[skuName]) {
      groupedData[skuName] = {
        skuName,
        details: [],
      };
    }

    // Add details for each SKU
    groupedData[skuName].details.push({
      areaName,
      account,
      searchKeyword,
      slot,
      organicRank,
      sponsoredRank,
      brand,
      productName,
    });
  });

  // Group details by search keyword
  const groupedDataValues = Object.values(groupedData);

  const transformedData = groupedDataValues.map((item) => {
    if (!item.details) {
      return { skuName: item.skuName, detailsGroupedByKeyword: {} };
    }

    const groupedByKeyword = item.details.reduce((acc, detail) => {
      const { searchKeyword } = detail;
      if (!searchKeyword) return acc;

      if (!acc[searchKeyword]) {
        acc[searchKeyword] = [];
      }
      acc[searchKeyword].push(detail);
      return acc;
    }, {});

    return {
      skuName: item.skuName,
      detailsGroupedByKeyword: groupedByKeyword,
    };
  });

  return transformedData;
};

const SkuVsKeywords = ({ selectedAreaName }) => {
  const { thirdPartyVisibility } = useSelector((state) => state.thirdParty);

  // Filter data based on selected areaName
  const data = thirdPartyVisibility.data?.filter(
    (item) => item["postgres_third_party_visibility.area_name_lm"] == selectedAreaName
  );
  const transformedData = transformData(data);
  const [isPopupOpenOwnBrand, setIsPopupOpenOwnBrand] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [openOwnBrandModal, setOpenOwnBrandModal] = useState(false);
  const [openCompetitorsRankingModal, setOpenCompetitorsRankingModal] = useState(false);
  const [selectedItemOwnBrand, setSelectedItemOwnBrand] = useState({});
  const [selectedItemCompetitor, setSelectedItemCompetitor] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("skuName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);

  // Handle sorting of the table columns
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Handle table pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change in the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle opening and closing of Own Brand Ranking modal
  const handleOpenOwnBrandModal = (item) => {
    setSelectedItemOwnBrand(item);
    setOpenOwnBrandModal(true);
    setSelectedKeyword(null);
  };

  const handleCloseOwnBrandModal = () => {
    setSelectedKeyword(null);
    setOpenOwnBrandModal(false);
  };

  // Handle opening and closing of Competitors Ranking modal
  const handleOpenCompetitorsModal = (item) => {
    setSelectedItemCompetitor(item);
    setOpenCompetitorsRankingModal(true);
    setSelectedKeyword(null);
  };

  const handleCloseCompetitorsModal = () => {
    setSelectedKeyword(null);
    setOpenCompetitorsRankingModal(false);
  };

  // Round slot values to the nearest quarter
  const roundToNearestQuarter = (value) => {
    return Math.round(value * 4) / 4;
  };

  // Update the transformed data with the average slot value for each SKU
  const updatedData = transformedData.map((item) => {
    const slots = Object.values(item.detailsGroupedByKeyword).flatMap((arr) =>
      arr
        .filter((entry) => entry.brand === parsedUserData?.organizationDetail?.name)
        .map((entry) => parseInt(entry.slot, 10))
    );

    const totalSlots = slots.reduce((sum, slot) => sum + slot, 0);
    const averageSlot = slots.length > 0 ? totalSlots / slots.length : 0;
    const roundedAverageSlot = roundToNearestQuarter(averageSlot);

    return {
      ...item,
      averageSlot: roundedAverageSlot,
    };
  });

  // Sort the data based on selected column and order
  const sortedData = updatedData.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Apply pagination to the sorted data
  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
        borderRadius: "0.5rem",
      }}
    >
      <>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                  }}
                  sortDirection={orderBy === "skuName" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "skuName"}
                    direction={orderBy === "skuName" ? order : "asc"}
                    onClick={() => handleRequestSort("skuName")}
                  >
                    Sku Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  sortDirection={orderBy === "averageSlot" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "averageSlot"}
                    direction={orderBy === "averageSlot" ? order : "asc"}
                    onClick={() => handleRequestSort("averageSlot")}
                  >
                    Average Across All Keywords
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Keyword Based Ranking
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Competitor Keyword Based Ranking
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    bgcolor: "white",
                    right: 0,
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      textAlign: "left",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    }}
                  >
                    {item?.skuName}
                  </TableCell>
                  <TableCell align="right" sx={{ color: "b#667085ack", fontSize: "1rem", textAlign: "left" }}>
                    {item?.averageSlot?.toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleOpenOwnBrandModal(item)}
                      variant="text"
                      color="primary"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "0.812rem",
                        fontWeight: 600,
                      }}
                    >
                      View Ranking
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleOpenCompetitorsModal(item)}
                      variant="text"
                      color="primary"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "0.812rem",
                        fontWeight: 600,
                      }}
                    >
                      View Competitors Ranking
                    </Button>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "black",
                      fontSize: "1rem",
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3",
                      position: "sticky",
                      bgcolor: "white",
                      right: 0,
                    }}
                  ></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={updatedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {isPopupOpenOwnBrand && popupData && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl max-h-[80vh] overflow-y-auto">
              <h2 className="text-2xl font-bold mb-4">Details for {popupData.skuName}</h2>
              {Object.keys(popupData.detailsGroupedByKeyword).length > 0 ? (
                Object.keys(popupData.detailsGroupedByKeyword).map((keyword, index) => {
                  const filteredDetails = popupData.detailsGroupedByKeyword[keyword].filter(
                    (detail) => detail.brand === parsedUserData?.organizationDetail?.name
                  );

                  return (
                    <div key={index} className="mb-6">
                      <h3 className="text-xl font-semibold mb-2 bg-blue-100 p-2 rounded-md text-blue-800">{keyword}</h3>
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {["Area name", "Account", "Slot", "Organic Rank", "Sponsored Rank"].map((item) => (
                                <th
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  key={index}
                                >
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {filteredDetails.map((detail, detailIndex) => (
                              <tr key={detailIndex}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{detail.areaName}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{detail.account}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{detail.slot}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {detail.organicRank}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {detail.sponsoredRank}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No details available.</p>
              )}
              <Button onClick={handleCloseOwnBrandModal} variant="outlined" color="secondary">
                Close
              </Button>
            </div>
          </div>
        )}

        {openOwnBrandModal && (
          <KeywordBasedOwnBrandRankingModal
            open={openOwnBrandModal}
            handleClose={handleCloseOwnBrandModal}
            data={selectedItemOwnBrand}
          />
        )}

        {openCompetitorsRankingModal && (
          <KeywordBasedCompetitorsRankingModal
            open={openCompetitorsRankingModal}
            handleClose={handleCloseCompetitorsModal}
            data={selectedItemCompetitor}
          />
        )}
      </>
    </Paper>
  );
};

export default SkuVsKeywords;
