import React, { useEffect, useState } from "react";
import LineChartDarkStore from "./WeeklyLevelOverviewLineChart";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import SkuCityLevelAvailabilityModal from "./SkuCityLevelAvailabilityModal";

// Utility function to calculate the percentage of stock availability for a given city's details
const calculateStockAvailabilityPercentage = (cityDetails) => {
  const totalPincodes = cityDetails.length;
  const availableStock = cityDetails.filter((detail) => detail.stockBinary === "1").length;
  return (availableStock / totalPincodes) * 100;
};

// Function to transform the raw API data into a more usable format,
// grouping by SKU, city, and date
const transformData = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const {
      "third_party_availability.product_name": sku,
      "third_party_availability.city_lm": city,
      "third_party_availability.stock_binary": stockBinary,
      "third_party_availability.sku_listing_status": stockListing,
      "third_party_availability.pincode_lm": pincode,
      "third_party_availability.area_name_lm": areaName,
      "third_party_availability.date": date,
    } = item;

    const parsedDate = new Date(date);

    // Initialize the SKU group if not present
    if (!groupedData[sku]) {
      groupedData[sku] = {
        sku,

        cities: {},
        dates: {},
      };
    }

    // Initialize the city group within the SKU if not present
    if (!groupedData[sku].cities[city]) {
      groupedData[sku].cities[city] = [];
    }

    // Initialize the date group within the SKU if not present
    if (!groupedData[sku].dates[parsedDate]) {
      groupedData[sku].dates[parsedDate] = [];
    }

    // Add city and date details to the grouped data
    groupedData[sku].cities[city].push({ stockBinary, stockListing, pincode, areaName });
    groupedData[sku].dates[parsedDate].push({
      stockBinary,
      stockListing,
      pincode,
    });
  });

  const result = [];

  // Loop through grouped SKUs and aggregate city and date data
  for (const sku in groupedData) {
    const cities = [];
    const dates = [];

    // Aggregate city data and calculate stock availability percentage
    for (const city in groupedData[sku].cities) {
      const details = groupedData[sku].cities[city];
      const availabilityPercentage = calculateStockAvailabilityPercentage(details);
      cities.push({
        city,
        details,
        availabilityPercentage,
      });
    }

    // Aggregate date data and calculate stock availability percentage
    for (const date in groupedData[sku].dates) {
      const details = groupedData[sku].dates[date];
      const availabilityPercentage = calculateStockAvailabilityPercentage(details);
      dates.push({
        date: new Date(date),
        availabilityPercentage,
      });
    }

    // Store the final result for each SKU
    result.push({
      sku: groupedData[sku].sku,
      cities,
      dates,
    });
  }

  return result; // Return transformed data
};

const SkuCityLevelAvailabilityTable = ({ apiData, brands, platformName }) => {
  const userDetail = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userDetail);
  const [selectedBrand, setSelectedBrand] = useState(parsedUserData?.organizationDetail?.name); // Default selected brand
  const data = apiData?.filter((item) => item["third_party_availability.brand"] == selectedBrand); // Filter data by brand
  const groupedData = transformData(data); // Transform the data

  // State for modal popup data
  const [popupData, setPopupData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState("");
  const [selectSku, setSelectSku] = useState("");

  // On initial load, set the selected SKU to the first one in the transformed data
  useEffect(() => {
    if (groupedData.length > 0) {
      setSelectedSKU(groupedData[0].sku);
    }
  }, [groupedData]);

  // Utility function to determine the background color based on availability percentage
  const getBackgroundColor = (percentage) => {
    if (percentage < 30) return "bg-red-500"; // Red for low availability
    if (percentage >= 30 && percentage <= 70) return "bg-yellow-500"; // Yellow for medium availability
    return "bg-green-500"; // Green for high availability
  };

  // Function to open the modal popup with city details
  const openPopup = (city, sku) => {
    setPopupData(city);
    setSelectSku(sku);
    setIsPopupOpen(true);
  };

  // Function to close the modal popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupData(null);
  };


  return (
    <div className="w-full  overflow-x-scroll">
      {/* Render the brand selection dropdown */}{" "}
      <div className="gap-3 mt-2 w-full flex font-semibold justify-end items-center">
        <span>Select Brand : </span>
        <Select
          className="bg-white"
          value={selectedBrand}
          sx={{
            width: "20rem",
          }}
          onChange={(e) => setSelectedBrand(e.target.value)}
          displayEmpty
        >
          {brands.map((brand) => (
            <MenuItem key={brand} value={brand} selected={selectedBrand == brand}>
              {brand}
            </MenuItem>
          ))}
        </Select>
      </div>
      {/* Render the data table */}
      <TableContainer component={Paper} sx={{ borderRadius: "0.5rem" }} className="mt-4 rounded-lg">
        <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
          <TableHead>
            <TableRow>
              {/* Render city column */}
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  bgcolor: "white",
                  left: 0,
                  maxWidth: "200px",
                }}
                style={{ minWidth: 150 }}
              >
                City
              </TableCell>
              {/* Render SKUs as columns */}
              {groupedData.map((item) => (
                <TableCell
                  key={item.sku}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  style={{ textAlign: "center" }}
                >
                  {item.sku}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  bgcolor: "white",
                  right: 0,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          {/* Render data rows for each city */}
          <TableBody>
            {groupedData[0]?.cities?.map((cityData) => (
              <TableRow key={cityData.city}>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    position: "sticky",
                    bgcolor: "white",
                    left: 0,
                    maxWidth: "300px",
                  }}
                  style={{ minWidth: 150 }}
                >
                  {cityData.city}
                </TableCell>

                {/* Render SKU availability for each city */}
                {groupedData.map((item) => {
                  const city = item.cities.find((c) => c.city === cityData.city);
                  const percentage = city ? city.availabilityPercentage : 0;
                  const backgroundColor = getBackgroundColor(percentage);
                  return (
                    <TableCell
                      key={item.sku}
                      onClick={() => openPopup(city, item.sku)}
                      sx={{
                        color: "#667085",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className={`inline-block ${backgroundColor} px-2 py-1 rounded-full text-white mui-table-css text-left`}
                      >
                        {city ? `${percentage.toFixed(2)}%` : "N/A"}
                      </div>
                    </TableCell>
                  );
                })}
                <TableCell
                  align="right"
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3", // Left shadow for this cell,
                    position: "sticky",
                    bgcolor: "white",
                    right: 0,
                  }}
                ></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Render the availability modal */}
      <SkuCityLevelAvailabilityModal
        open={isPopupOpen}
        handleClose={closePopup}
        data={popupData}
        selectedSku={selectSku}
      />
    </div>
  );
};

export default SkuCityLevelAvailabilityTable;
