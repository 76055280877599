// const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
import dayjs from "dayjs";

const getLast7DaysRange = (selectedDate) => {
  // Ensure selectedDate is valid
  if (!dayjs(selectedDate).isValid()) {
    console.error("Invalid selectedDate:", selectedDate);
    return ["", ""];
  }

  // Get endDate and startDate in ISO 8601 format
  const endDate = dayjs(selectedDate).toISOString();
  const startDate = dayjs(selectedDate).subtract(6, "day").toISOString();

  return [startDate, endDate];
};

const formatDateWithoutTimezone = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

const getSingleDayDateRange = (date) => {
  // Start of the day (00:00:00.000)
  const startOfDay = dayjs(date).startOf("day");
  // End of the day (23:59:59.999)
  const endOfDay = dayjs(date).endOf("day");

  // Format the start and end dates using the formatDateWithoutTimezone function
  const formattedStartOfDay = formatDateWithoutTimezone(startOfDay);
  const formattedEndOfDay = formatDateWithoutTimezone(endOfDay);

  // Return the range as an object
  return {
    start: formattedStartOfDay,
    end: formattedEndOfDay,
  };
};

export const blinkitQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);

  return {
    dimensions: [
      "third_party_availability.sku",
      "third_party_availability.date",

      "third_party_availability.platform",
      "third_party_availability.stock_binary",
      "third_party_availability.sku_listing_status",
      "third_party_availability.product_name",
      "third_party_availability.brand",

      "third_party_availability.area_name_lm",
      "third_party_availability.city_lm",
      "third_party_availability.pincode_lm",
      "third_party_availability.primary_key",
    ],
    order: [["third_party_availability.sku", "asc"]],
    filters: [
      {
        dimension: "third_party_availability.platform",
        operator: "equals",
        values: ["Blinkit"],
      },
    ],
    timeDimensions: [
      {
        dimension: "third_party_availability.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const instamartQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "third_party_availability.sku",
      "third_party_availability.date",

      "third_party_availability.platform",
      "third_party_availability.stock_binary",
      "third_party_availability.sku_listing_status",
      "third_party_availability.product_name",
      "third_party_availability.brand",
      "third_party_availability.area_name_lm",
      "third_party_availability.city_lm",
      "third_party_availability.pincode_lm",
    ],
    order: [["third_party_availability.sku", "asc"]],
    filters: [
      {
        dimension: "third_party_availability.platform",
        operator: "equals",
        values: ["Instamart"],
      },
    ],
    timeDimensions: [
      {
        dimension: "third_party_availability.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const zeptoQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "third_party_availability.sku",
      "third_party_availability.date",

      "third_party_availability.platform",
      "third_party_availability.stock_binary",
      "third_party_availability.sku_listing_status",
      "third_party_availability.product_name",
      "third_party_availability.brand",
      "third_party_availability.area_name_lm",
      "third_party_availability.city_lm",
      "third_party_availability.pincode_lm",
    ],
    order: [["third_party_availability.sku", "asc"]],
    filters: [
      {
        dimension: "third_party_availability.platform",
        operator: "equals",
        values: ["Zepto"],
      },
    ],
    timeDimensions: [
      {
        dimension: "third_party_availability.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const blinkitQueryforWeeklyChart = (selectedDate) => {
  const [startDate, endDate] = getLast7DaysRange(selectedDate);

  return {
    dimensions: [
      "third_party_availability.sku",
      "third_party_availability.date",

      "third_party_availability.platform",
      "third_party_availability.stock_binary",
      "third_party_availability.sku_listing_status",
      "third_party_availability.product_name",
      "third_party_availability.brand",
      "third_party_availability.area_name_lm",
      "third_party_availability.city_lm",
      "third_party_availability.pincode_lm",
    ],
    order: [["third_party_availability.sku", "asc"]],
    filters: [
      {
        dimension: "third_party_availability.platform",
        operator: "equals",
        values: ["Blinkit"],
      },
    ],
    timeDimensions: [
      {
        dimension: "third_party_availability.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
  };
};

export const instamartQueryforWeeklyChart = (selectedDate) => {
  const [startDate, endDate] = getLast7DaysRange(selectedDate);

  return {
    dimensions: [
      "third_party_availability.sku",
      "third_party_availability.date",

      "third_party_availability.platform",
      "third_party_availability.stock_binary",
      "third_party_availability.sku_listing_status",
      "third_party_availability.product_name",
      "third_party_availability.brand",
      "third_party_availability.area_name_lm",
      "third_party_availability.city_lm",
      "third_party_availability.pincode_lm",
    ],
    order: [["third_party_availability.sku", "asc"]],
    filters: [
      {
        dimension: "third_party_availability.platform",
        operator: "equals",
        values: ["Instamart"],
      },
    ],
    timeDimensions: [
      {
        dimension: "third_party_availability.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
  };
};

export const zeptoQueryforWeeklyChart = (selectedDate) => {
  const [startDate, endDate] = getLast7DaysRange(selectedDate);

  return {
    dimensions: [
      "third_party_availability.sku",
      "third_party_availability.date",
      "third_party_availability.platform",
      "third_party_availability.stock_binary",
      "third_party_availability.sku_listing_status",
      "third_party_availability.product_name",
      "third_party_availability.brand",
      "third_party_availability.area_name_lm",
      "third_party_availability.city_lm",
      "third_party_availability.pincode_lm",
    ],
    order: [["third_party_availability.sku", "asc"]],
    filters: [
      {
        dimension: "third_party_availability.platform",
        operator: "equals",
        values: ["Zepto"],
      },
    ],
    timeDimensions: [
      {
        dimension: "third_party_availability.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
  };
};
