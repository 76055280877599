import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { FaRegCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";

const getAppointmentsOn = (data) => {
  // const last10Days = getLastNDays(
  //   10,
  //   data[0]["fulfillment_report_blinkit.order_date"]
  // );
  let filteredData = {};
  data?.forEach((item) => {
    const poNumber = item["fulfillment_report_blinkit.po_number"];
    const facilityName = item["fulfillment_report_blinkit.facility_name"];
    const total_amount = parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0;
    const groupKey = `${poNumber}_${facilityName}`;
    if (
      item["fulfillment_report_blinkit.po_state"] === "Created" &&
      item["fulfillment_report_blinkit.appointment_date"] === "" &&
      !filteredData[groupKey]
      // last10Days.includes(
      //   item["fulfillment_report_blinkit.order_date"].slice(0, 10)
      // )
    ) {
      filteredData[groupKey] = {
        po_number: poNumber,
        facility_name: facilityName,
        item_name: item["fulfillment_report_blinkit.name"],
        total_amount: total_amount,
        po_state: item["fulfillment_report_blinkit.po_state"],
        order_date: item["fulfillment_report_blinkit.order_date"],
        appointment_date: item["fulfillment_report_blinkit.appointment_date"],
        expiry_date: item["fulfillment_report_blinkit.expiry_date"],
      };
    }

    // Ensure filteredData[groupKey] exists before updating its total_amount
    if (filteredData[groupKey]) {
      filteredData[groupKey].total_amount += parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0; // Add up the total_amount safely
    }
  });
  return Object.values(filteredData);
};

const BookAppointments = ({ data }) => {
  const filteredData = getAppointmentsOn(data);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!data.length) {
    return <div>No Data Found</div>;
  }

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
        mt: "1rem",
      }}
    >
      <div className="flex gap-4 items-center p-4 font-bold text-xl">
        <FaRegCalendarAlt />
        Book Appointments for these Dates
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
          <TableHead>
            <TableRow>
              {[
                {
                  id: "po_number_city",
                  label: "PO Number/City",
                },
                {
                  id: "sku_list",
                  label: "SKU List",
                },
                {
                  id: "total_value",
                  label: "Total Value",
                },
                {
                  id: "po_state",
                  label: "PO State",
                },
                {
                  id: "order_date",
                  label: "Order Date",
                },

            


                {
                  id: "expiry_date",
                  label: "Expiry Date",
                },
              ].map((headCell) => (
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: `${headCell.id === "po_number_city" && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"}`,
                    zIndex: `${headCell.id === "po_number_city" && "3"}`,
                    minWidth: "200px",
                    position: `${headCell.id === "po_number_city" && "sticky"}`,
                    left: `${headCell.id === "po_number_city" && 0}`,
                    bgcolor: `${headCell.id === "po_number_city" && "white"}`,
                  }}
                  key={headCell.id}
                >
                  {headCell.label}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    position: "sticky",
                    left: 0,
                    bgcolor: "white",
                  }}
                >
                  {item.po_number + "_" + item.facility_name}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {item.item_name}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {item.total_amount}
                </TableCell>
                <TableCell sx={{ color: "#667085", fontSize: "1rem", width: "200px" }}>{item.po_state}</TableCell>
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {dayjs(item.order_date).format("DD-MMM-YYYY")}
                </TableCell>
          
                <TableCell
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    minWidth: "200px",
                  }}
                >
                  {item.expiry_date}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3", // Left shadow for this cell,
                    position: "sticky",
                    bgcolor: "white",
                    right: 0,
                  }}
                ></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default BookAppointments;
