import React, { Suspense, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const CampaignTable = React.lazy(() => import("./CampaignTable"));

const CampaignAccordian = ({ campaignTitle = "Default Title", details = [] }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <div>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{campaignTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Suspense fallback={<div>Loading Table...</div>}>{expanded && <CampaignTable details={details} />}</Suspense>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CampaignAccordian;
