import { blinkitQuery, instamartQuery, zeptoQuery } from "../../utils/queries/inventoryReportQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";


// Fetch Blinkit data for daily view
export const fetchInventoryReportBlinkitData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQuery(selectedDate));
  return result.tablePivot();
};

// Fetch Instamart data for daily view
export const fetchInventoryReportInstamartData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQuery(selectedDate));
  return result.tablePivot();
};

// Fetch Zepto data for daily view
export const fetchInventoryReportZeptoData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQuery(selectedDate));
  return result.tablePivot();
};
