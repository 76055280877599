import React, { useMemo } from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import CampaignAccordian from "./CampaignAccordian";

const AdCampaigns = ({ groupedDataAd }) => {
  let count = 0;
  return (
    <div className="bg-white shadow-md rounded-xl ">
      <div>
        <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
          <div className="p-2 rounded-full bg-gray-200">
            <RiAdvertisementLine />
          </div>
          Campaigns Details
        </h1>
        <div className="flex p-4 flex-col justify-between gap-4">
          {Object.entries(groupedDataAd)?.map(([campaignTitle, details], index) => {
            count++;

            return <CampaignAccordian key={index} campaignTitle={campaignTitle} details={details} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AdCampaigns;
