import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"; // Import useLocation
import { setPlatformMenu } from "../../../redux/actions/platformActions";

const PlatformMenu = ({ setPlatformName }) => {
  const [selectedPlatform, setSelectedPlatform] = useState("Blinkit");
  const dispatch = useDispatch();
  const location = useLocation(); // Get current location

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    setPlatformName(platform);
    dispatch(setPlatformMenu(platform));
  };

  // Check if the current path is '/ads/display-ads'
  const shouldHideInstamart = location.pathname === "/ads/display-ads";

  return (
    <div className="flex items-center bg-backgroundWhite h-[2.75rem] w-[33.375rem] rounded-lg">
      <div className="flex py-2 text-[1rem] items-center font-medium leading-[1.2rem] font-poppins text-black">
        <span className="mr-[1.25rem]">Channel :</span>
        <div className="flex gap-[0.8rem]">
          <div
            className={`flex gap-1 items-center cursor-pointer p-1 ${
              selectedPlatform === "Blinkit" ? "border-2 border-[#F7DA3F] rounded-full" : ""
            }`}
            onClick={() => handlePlatformChange("Blinkit")}
          >
            <div className="w-10 h-10 rounded-lg overflow-hidden">
              <img src="/logos/blinkit_round_logo.png" className="w-full h-full object-cover" alt="Blinkit" />
            </div>
          </div>
          <div
            className={`flex gap-1 items-center cursor-pointer p-1 ${
              selectedPlatform === "Zepto" ? "border-2 border-[#3B006A] rounded-full" : ""
            }`}
            onClick={() => handlePlatformChange("Zepto")}
          >
            <div className="w-10 h-10 rounded-lg overflow-hidden">
              <img src="/logos/zepto_round_logo.png" className="w-full h-full object-cover" alt="Zepto" />
            </div>
          </div>
          {/* Conditionally render the Instamart section */}
          {!shouldHideInstamart && (
            <div
              className={`flex gap-1 items-center cursor-pointer p-1 ${
                selectedPlatform === "Instamart" ? "border-2 border-[#E95D32] rounded-full" : ""
              }`}
              onClick={() => handlePlatformChange("Instamart")}
            >
              <div className="w-10 h-10 rounded-lg overflow-hidden">
                <img src="/logos/instamart_round_logo.png" className="w-full h-full object-cover" alt="Instamart" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlatformMenu;
