import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllMarketingReportBlinkitSearchSuggestionSuccess,
  getAllMarketingReportBlinkitSearchSuggestionRequest,
  getAllMarketingReportBlinkitSearchSuggestionFailure,
  getAllMarketingReportBlinkitBannerListingSuccess,
  getAllMarketingReportBlinkitBannerListingRequest,
  getAllMarketingReportBlinkitBannerListingFailure,
  getAllCampaignPerformanceSkuSuccess,
  getAllCampaignPerformanceSkuRequest,
  getAllCampaignPerformanceSkuFailure,
  getAllAwarenessPerformanceDataSuccess,
  getAllAwarenessPerformanceDataRequest,
  getAllAwarenessPerformanceDataFailure,
} from "../../redux/actions/marketingActions";
import {
  fetchDisplayAdsSearchSuggestionDataBlinkit,
  fetchDisplayAdsBannerListingDataBlinkit,
  fetchDisplayAdsCampaignPerformanceDataZepto,
  fetchAwarenessPerformanceDataZepto,
} from "../../services/adsServices/displayAdsServices";
import SearchSuggesionSectionsContainer from "../../components/ads/DisplayAdsComponents/SearchSuggesionSectionsContainer";
import BannerListingSectionsContainer from "../../components/ads/DisplayAdsComponents/BannerListingSectionsContainer";
import AwarenessPerformanceZeptoSectionsContainer from "../../components/ads/DisplayAdsComponents/AwarenessPerformanceZeptoSectionsContainer";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import Toolbar from "../../components/Toolbar/Toolbar";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";

const optionsBlinkit = ["Search Suggestion", "Banner Listing"];
const optionsZepto = ["Awareness Performance"];

const DisplayAdScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const dispatch = useDispatch();
  const { selectedDate } = useDate();
  const options = platformName === "Blinkit" ? optionsBlinkit : optionsZepto;

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const handleBlinkitQueries = async () => {
    // Blinkit Search Suggestion
    try {
      dispatch(getAllMarketingReportBlinkitSearchSuggestionRequest());
      const searchSuggestionResult = await fetchDisplayAdsSearchSuggestionDataBlinkit(selectedDate);
      dispatch(getAllMarketingReportBlinkitSearchSuggestionSuccess(searchSuggestionResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitSearchSuggestionFailure(error));
    }

    // Blinkit Banner Listing
    try {
      dispatch(getAllMarketingReportBlinkitBannerListingRequest());
      const bannerListingResult = await fetchDisplayAdsBannerListingDataBlinkit(selectedDate);
      dispatch(getAllMarketingReportBlinkitBannerListingSuccess(bannerListingResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitBannerListingFailure(error));
    }
  };

  const handleZeptoQueries = async () => {
    // Zepto Campaign Performance
    try {
      dispatch(getAllCampaignPerformanceSkuRequest());
      const campaignPerformanceResult = await fetchDisplayAdsCampaignPerformanceDataZepto(selectedDate);
      dispatch(getAllCampaignPerformanceSkuSuccess(campaignPerformanceResult));
    } catch (error) {
      dispatch(getAllCampaignPerformanceSkuFailure(error));
    }

    // Zepto Awareness Performance
    try {
      dispatch(getAllAwarenessPerformanceDataRequest());
      const awarenessPerformanceResult = await fetchAwarenessPerformanceDataZepto(selectedDate);
      dispatch(getAllAwarenessPerformanceDataSuccess(awarenessPerformanceResult));
    } catch (error) {
      dispatch(getAllAwarenessPerformanceDataFailure(error));
    }
  };

  useEffect(() => {
    if (platformName === "Blinkit") {
      handleBlinkitQueries();
    } else {
      handleZeptoQueries();
    }
  }, [platformName, selectedDate, selectedOption]);

  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  // Calculate startDate as 30 days before selectedDate
  const calculateDateRange = (selectedDate) => {
    const endDate = formatDateWithoutTimezone(selectedDate);
    const startDate = formatDateWithoutTimezone(dayjs(selectedDate).subtract(30, "day"));
    return [startDate, endDate];
  };

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={calculateDateRange(selectedDate)} />

      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 && (
          <div className="flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
            {platformName === "Blinkit" && <SearchSuggesionSectionsContainer />}
            {platformName === "Zepto" && <AwarenessPerformanceZeptoSectionsContainer />}
          </div>
        )}
        {selectedOption === 1 && (
          <div className="rounded-lg w-full flex flex-col items-center mt-[1rem]">
            {platformName === "Blinkit" && <BannerListingSectionsContainer />}
          </div>
        )}
      </Toolbar>
    </>
  );
};

export default DisplayAdScreen;
