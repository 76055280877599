import React from "react";
import PotentialSalesLossBlinkitTable from "./PotentialSalesLossBlinkitTable";

import { useSelector } from "react-redux";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const PotentialSalesLossBlinkitSectionsContainer = () => {
  const { potentialSalesLossBlinkit } = useSelector((state) => state.potentailSalesLoss);

  const { pslBlinkitFulfillment } = useSelector((state) => state.pslFulfillment);


  // Check if either data is loading
  if (potentialSalesLossBlinkit.loading || pslBlinkitFulfillment.loading) {
    return (
      <div className="w-full">
        <TableSkeleton />
      </div>
    );
  }

  // Check if either data is null or empty
  if (
    potentialSalesLossBlinkit.data === null ||
    potentialSalesLossBlinkit.data.length === 0 ||
    pslBlinkitFulfillment.data === null ||
    pslBlinkitFulfillment.data.length === 0
  ) {
    return <NoDataFound />; // Show NoDataFound if no data is found
  }

  // Render the table if data is available
  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      <PotentialSalesLossBlinkitTable />
    </div>
  );
};

export default PotentialSalesLossBlinkitSectionsContainer;
