import React, { useEffect, useState } from "react";
import PricingCard from "./PricingCard";
import { useSelector } from "react-redux";

// Helper function to group data by SKU and keyword
const groupDataByKeywords = (data, parsedUserData) => {
  // Check if data is a valid array, log error if it's not
  if (!Array.isArray(data)) {
    console.error("Invalid data provided to groupDataByKeywords");
    return {}; // Return an empty object if data is not an array
  }

  let result = {};
  // Iterate over each data item to group it by SKU and keyword
  data.forEach((item) => {
    // Safeguard against missing properties
    const SKU = item["postgres_third_party_visibility.sku_name"] || "";
    const keyword = item["postgres_third_party_visibility.searchkeyword"] || "";
    const isParentBrand = item["postgres_third_party_visibility.brand"] === parsedUserData?.organizationDetail?.name;
    const price = parseFloat(item["postgres_third_party_visibility.price"] || "0");
    const discountPrice = parseFloat(item["postgres_third_party_visibility.discountprice"] || "0");
    const discountPercent = ((price - discountPrice) / price) * 100;

    // Initialize result object for the SKU if not already present
    if (!result[SKU]) {
      result[SKU] = {};
    }

    // Initialize result object for the keyword if not already present
    if (!result[SKU][keyword]) {
      result[SKU][keyword] = {
        parentBrand: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
        other: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
      };
    }

    // Populate data under `parentBrand` or `other` category based on `isParentBrand` flag
    if (isParentBrand) {
      result[SKU][keyword].parentBrand.count += 1;
      result[SKU][keyword].parentBrand.price += price;
      result[SKU][keyword].parentBrand.discountPrice += discountPrice;
      result[SKU][keyword].parentBrand.discountPercent += discountPercent;
    } else {
      result[SKU][keyword].other.count += 1;
      result[SKU][keyword].other.price += price;
      result[SKU][keyword].other.discountPrice += discountPrice;
      result[SKU][keyword].other.discountPercent += discountPercent;
    }
  });

  return result;
};

const PricingDetailsTable = ({ selectedAreaName }) => {
  const { thirdPartyVisibility } = useSelector((state) => state.thirdParty);
  const [selectedSKU, setSelectedSKU] = useState();
  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);
  // Filter the visibility data based on the selected pincode
  const pricingData = thirdPartyVisibility.data?.filter(
    (item) => item["postgres_third_party_visibility.area_name_lm"] == selectedAreaName
  );
  const SKU = Array.from(new Set(pricingData?.map((item) => item["postgres_third_party_visibility.sku_name"])));

  // Update the selected SKU whenever the SKU array changes
  useEffect(() => {
    if (SKU.length > 0) {
      setSelectedSKU(SKU[0]);
    }
  }, [pricingData.length]);

  // Group the filtered pricing data by SKU and keywords
  const groupData = groupDataByKeywords(pricingData, parsedUserData);

  // If there is no data, display a 'No Data Found' message
  if (pricingData.length === 0 && !Object.keys(groupData).length) {
    return <div className="w-full h-[35rem] flex justify-center items-center text-gray-500">No Data Found.</div>;
  }

  return (
    <div className="flex h-full bg-white rounded-lg">
      <div className="flex flex-col w-3/12 h-full overflow-y-auto rounded-lg shadow-inner relative text-xs">
        <div className="text-center font-bold text-lg w-full text-gray-800 sticky top-0 bg-gray-50 px-6 py-12 z-10">
          SKUs
        </div>
        <div className="px-4">
          {SKU?.map((item) => (
            <div
              key={item}
              className={`p-2 mb-2 cursor-pointer capitalize rounded-md ${
                selectedSKU === item ? "bg-navBarColour text-white hover:bg-[#2a4f8b] " : "hover:bg-gray-100"
              }`}
              onClick={() => setSelectedSKU(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="w-9/12 h-full bg-whgite overflow-y-auto">
        {groupData && <PricingCard selectedSKU={selectedSKU} data={groupData} pricingData={pricingData} />}
      </div>
    </div>
  );
};

export default PricingDetailsTable;
