import {
  TARGET_TRACKER_DATA_FAILURE,
  TARGET_TRACKER_DATA_REQUEST,
  TARGET_TRACKER_DATA_SUCCESS,
  TARGET_TRACKER_FAILURE,
  TARGET_TRACKER_REQUEST,
  TARGET_TRACKER_SUCCESS,
} from "../constants/tragetTrackerConstants";

export const getAllTargetRequest = () => {
  return {
    type: TARGET_TRACKER_REQUEST,
  };
};

export const getAllTargetSuccess = (targets) => {
  return {
    type: TARGET_TRACKER_SUCCESS,
    payload: targets,
  };
};

export const getAllTargetFailure = (error) => {
  return {
    type: TARGET_TRACKER_FAILURE,
    payload: error,
  };
};

export const getAllTargetDataRequest = () => {
  return {
    type: TARGET_TRACKER_DATA_REQUEST,
  };
};

export const getAllTargetDataSuccess = (data) => {
  return {
    type: TARGET_TRACKER_DATA_SUCCESS,
    payload: data,
  };
};

export const getAllTargetDataFailure = (error) => {
  return {
    type: TARGET_TRACKER_DATA_FAILURE,
    payload: error,
  };
};
