import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { getCubeJsApiInstance } from "../../utils/apiConfig";
import {
  getAllLeastPerformingBlinkitProductListingRequest,
  getAllLeastPerformingBlinkitProductListingSuccess,
  getAllLeastPerformingBlinkitProductListingFailure,
  getAllLeastPerformingBlinkitProductRecommendationRequest,
  getAllLeastPerformingBlinkitProductRecommendationSuccess,
  getAllLeastPerformingBlinkitProductRecommendationFailure,
  getAllLeastPerformingSkuPerformanceZeptoRequest,
  getAllLeastPerformingSkuPerformanceZeptoSuccess,
  getAllLeastPerformingSkuPerformanceZeptoFailure,
  getAllLeastPerformingCampaignPerformanceSkuRequest,
  getAllLeastPerformingCampaignPerformanceSkuSuccess,
  getAllLeastPerformingCampaignPerformanceSkuFailure,
  getAllLeastPerformingMarketingReportInstamartRequest,
  getAllLeastPerformingMarketingReportInstamartSuccess,
  getAllLeastPerformingMarketingReportInstamartFailure,
} from "../../redux/actions/leastPerformingActions";
import ProductListingCampaignLeastPerformanceSectionsContainer from "../../components/ads/LeastPerformingComponents/ProductListingCampaignLeastPerformanceSectionsContainer";
import ProductRecommendationCampaignLeastPerformanceSectionsContainer from "../../components/ads/LeastPerformingComponents/ProductRecommendationCampaignLeastPerformanceSectionsContainer";
import SkuPerformanceLeastPerformingSectionsContainer from "../../components/ads/LeastPerformingComponents/SkuPerformanceLeastPerformingSectionsContainer";
import CampaignPerformanceoLeastPerformanceSectionContainer from "../../components/ads/LeastPerformingComponents/CampaignPerformanceoLeastPerformanceSectionContainer";
import MarketingReportLeastPerformanceSectionsContainer from "../../components/ads/LeastPerformingComponents/MarketingReportLeastPerformanceSectionsContainer";
import {
  productListingQuery,
  productRecommendationQuery,
  skuPerformanceQuery,
  campaignPerformanceQuery,
  marketingReportQuery,
} from "../../utils/queries/leastPerformingQueries";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";

const optionsBlinkit = ["Product Listing", "Product Recommendation"];
const optionsInstamart = ["Marketitng Report"];
const optionsZepto = ["SKU Performance", "Campaign Performance"];

const LeastPerformingScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [cubejsApi] = useState(getCubeJsApiInstance());

  const [selectedOption, setSelectedOption] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const options =
    platformName === "Blinkit"
      ? optionsBlinkit
      : platformName === "Instamart"
      ? optionsInstamart
      : platformName === "Zepto"
      ? optionsZepto
      : [];
  const { selectedDate } = useDate();
  const query = skuPerformanceQuery(selectedDate);


  const handleBlinkitQueries = () => {
    // Product Listing
    dispatch(getAllLeastPerformingBlinkitProductListingRequest());
    cubejsApi
      .load(productListingQuery(selectedDate))
      .then((result) => dispatch(getAllLeastPerformingBlinkitProductListingSuccess(result.tablePivot())))
      .catch((error) => {
        dispatch(getAllLeastPerformingBlinkitProductListingFailure(error));
        console.error("Error loading product listing data", error);
      });

    // Product Recommendation
    dispatch(getAllLeastPerformingBlinkitProductRecommendationRequest());
    cubejsApi
      .load(productRecommendationQuery(selectedDate))
      .then((result) => dispatch(getAllLeastPerformingBlinkitProductRecommendationSuccess(result.tablePivot())))
      .catch((error) => {
        dispatch(getAllLeastPerformingBlinkitProductRecommendationFailure(error));
        console.error("Error fetching product recommendation data:", error);
      });
  };

  const handleInstamartQueries = () => {
    dispatch(getAllLeastPerformingMarketingReportInstamartRequest());
    cubejsApi
      .load(marketingReportQuery(selectedDate))
      .then((result) => dispatch(getAllLeastPerformingMarketingReportInstamartSuccess(result.tablePivot())))
      .catch((error) => {
        dispatch(getAllLeastPerformingMarketingReportInstamartFailure(error));
        console.error("Error fetching instamart data:", error);
      });
  };

  const handleZeptoQueries = () => {
    // SKU Performance
    dispatch(getAllLeastPerformingSkuPerformanceZeptoRequest());
    cubejsApi
      .load(skuPerformanceQuery(selectedDate))
      .then((result) => dispatch(getAllLeastPerformingSkuPerformanceZeptoSuccess(result.tablePivot())))
      .catch((error) => {
        dispatch(getAllLeastPerformingSkuPerformanceZeptoFailure(error));
        console.error("Error fetching SKU performance data:", error);
      });

    // Campaign Performance
    dispatch(getAllLeastPerformingCampaignPerformanceSkuRequest());
    cubejsApi
      .load(campaignPerformanceQuery(selectedDate))
      .then((result) => dispatch(getAllLeastPerformingCampaignPerformanceSkuSuccess(result.tablePivot())))
      .catch((error) => {
        dispatch(getAllLeastPerformingCampaignPerformanceSkuFailure(error));
        console.error("Error fetching campaign performance data:", error);
      });
  };

  useEffect(() => {
    if (platformName == "Blinkit") {
      handleBlinkitQueries();
    } else if (platformName == "Instamart") {
      handleInstamartQueries();
    } else {
      handleZeptoQueries();
    }
  }, [platformName, selectedDate, selectedOption, dispatch]);

  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  // Calculate startDate as 30 days before selectedDate
  const calculateDateRange = (selectedDate) => {
    const endDate = formatDateWithoutTimezone(selectedDate);
    const startDate = formatDateWithoutTimezone(dayjs(selectedDate).subtract(30, "day"));
    return [startDate, endDate];
  };

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={calculateDateRange(selectedDate)} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {selectedOption === 0 && (
          <>
            <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
              {platformName === "Blinkit" && <ProductListingCampaignLeastPerformanceSectionsContainer />}
              {platformName === "Instamart" && <MarketingReportLeastPerformanceSectionsContainer />}
              {platformName === "Zepto" && <SkuPerformanceLeastPerformingSectionsContainer />}
            </div>
          </>
        )}

        {selectedOption === 1 && (
          <>
            <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
              {platformName === "Blinkit" && <ProductRecommendationCampaignLeastPerformanceSectionsContainer />}
              {platformName === "Zepto" && <CampaignPerformanceoLeastPerformanceSectionContainer />}
            </div>
          </>
        )}
      </Toolbar>
    </>
  );
};

export default LeastPerformingScreen;
