import React from "react";
import ShowSelectedDate from "../ShowSelectedDate/ShowSelectedDate";
import PlatformMenu from "../PatformMenu/PlatformMenu";
import { useLocation } from "react-router-dom";

const HeadingBar = ({ setPlatformName, selectedDate }) => {
  const { pathname } = useLocation();
  return (
    <div>
      <nav className="bg-backgroundWhite shadow w-[100%]">
        <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-14 items-center justify-between">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <PlatformMenu setPlatformName={setPlatformName} />
            </div>

            {/* date picker sections */}
            <div className="flex flex-col">
              {/* <span className="text-[0.55rem] text-gray-500 ml-3 bg-white mb-[-2px] font-medium">
                    Select Date
                  </span> */}
              <div>{pathname !== "/set-targets" && <ShowSelectedDate date={selectedDate} />}</div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeadingBar;
