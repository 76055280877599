import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
} from "@mui/material";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";

const UnderStockedTableInstamart = () => {  
  const {
    stockingInsightInstamart,
  } = useSelector((state) => state.stocking);

  const { loading, data } = stockingInsightInstamart;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("inventoryDays");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dataWithTotalInventory = data.map((item) => ({
    ...item,
    totalInventory: parseInt(
      item["combined_inventory_data_instamart.wh_soh"],
      10
    ),
  }));

  const groupedData = {};
  dataWithTotalInventory.forEach((item) => {
    const key = `${item["combined_inventory_data_instamart.wh_name"]}_${item["combined_inventory_data_instamart.brand_internal_sku_code"]}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  const newModifiedData = [];
  Object.keys(groupedData).forEach((key) => {
    const group = groupedData[key];

    group.sort(
      (a, b) =>
        new Date(a["combined_inventory_data_instamart.dt"]) -
        new Date(b["combined_inventory_data_instamart.dt"])
    );

    const earliestEntry = group[0];
    const latestEntry = group[group.length - 1];

    const earliestEntryDate = new Date(
      earliestEntry["combined_inventory_data_instamart.dt"]
    );
    const latestEntryDate = new Date(
      latestEntry["combined_inventory_data_instamart.dt"]
    );

    let diffInTime = latestEntryDate.getTime() - earliestEntryDate.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

    let dailyRunRate =
      (earliestEntry.totalInventory - latestEntry.totalInventory) / diffInDays;
    if (isNaN(dailyRunRate)) {
      dailyRunRate = 0;
    }

    let inventoryDays = 0;
    if (dailyRunRate > 0) {
      inventoryDays = latestEntry.totalInventory / dailyRunRate;
    }

    newModifiedData.push({
      ...earliestEntry,
      dailyRunRate,
      inventoryDays,
    });
  });

  const filteredData = newModifiedData.filter(
    (item) => item.inventoryDays > 0 && item.inventoryDays <= 10
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === "dailyRunRate" || orderBy === "inventoryDays") {
      return order === "asc"
        ? a[orderBy] - b[orderBy]
        : b[orderBy] - a[orderBy];
    } else {
      const aValue = a[orderBy] || ""; // Default to empty string if undefined
      const bValue = b[orderBy] || ""; // Default to empty string if undefined
      return order === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
  });

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mb: "2rem" }}>
      <>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sortDirection={orderBy === "productName" ? order : false}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    minWidth: "200px",
                    position: "sticky",
                    left: 0,
                    bgcolor: "white",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "productName"}
                    direction={orderBy === "productName" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "productName")}
                  >
                    Instamart SKU Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === "city" ? order : false}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "city"}
                    direction={orderBy === "city" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "city")}
                  >
                    City
                  </TableSortLabel>
                </TableCell>


                <TableCell
                  sortDirection={orderBy === "city" ? order : false}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "city"}
                    direction={orderBy === "city" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "city")}
                  >
                    Facility Name
                  </TableSortLabel>
                </TableCell>


                <TableCell
                  sortDirection={orderBy === "itemCode" ? order : false}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "itemCode"}
                    direction={orderBy === "itemCode" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "itemCode")}
                  >
                    Item Code
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === "dailyRunRate" ? order : false}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "dailyRunRate"}
                    direction={orderBy === "dailyRunRate" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "dailyRunRate")}
                  >
                    Daily Run Rate
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === "totalInventory" ? order : false}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "totalInventory"}
                    direction={orderBy === "totalInventory" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "totalInventory")}
                  >
                    Total Inventory
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === "inventoryDays" ? order : false}
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "inventoryDays"}
                    direction={orderBy === "inventoryDays" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "inventoryDays")}
                  >
                    Inventory Days
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length > 0 ? (
                paginatedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        color: "black",
                        fontSize: "1rem",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        minWidth: "200px",
                        position: "sticky",
                        left: 0,
                        bgcolor: "white",
                      }}
                    >
                      {item["combined_inventory_data_instamart.product_name"]}
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item["combined_inventory_data_instamart.brands_choice"]}
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item[ "combined_inventory_data_instamart.wh_name"]}
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item["combined_inventory_data_instamart.item_code"]}
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item.dailyRunRate}
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item.totalInventory}
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item.inventoryDays}
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "white",
                      }}
                    ></TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    className="text-center text-[0.75rem] text-[#727278]"
                  >
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    </Paper>
  );
};

export default UnderStockedTableInstamart;
