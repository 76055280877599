import React, { useState, useEffect } from "react";
import { LuTrendingDown } from "react-icons/lu";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";

const calculateAverageAppointmentTurnAroundTime = (data) => {
  let diff = 0;
  let count = 0;
  for (let i = 0; i < data?.length; i++) {
    if (data[i]["fulfillment_report_blinkit.po_state"] === "Fulfilled") {
      const date1 = new Date(data[i]["fulfillment_report_blinkit.appointment_date"]);
      const date2 = new Date(data[i]["fulfillment_report_blinkit.order_date"]);

      const differenceInTime = date1.getTime() - date2.getTime(); // Difference in milliseconds
      const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert to days
      diff += differenceInDays;
      count++;
    }
  }
  return diff / count;
};

const PotentialSalesLossBlinkitTable = () => {
  const { pslBlinkitFulfillment } = useSelector((state) => state.pslFulfillment);
  const { potentialSalesLossBlinkit } = useSelector((state) => state.potentailSalesLoss);

  const [pslData, setPslData] = useState([]);
  const { selectedDate } = useDate();

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const averageTime = calculateAverageAppointmentTurnAroundTime(pslBlinkitFulfillment.data);


  const calculatePSL = (potentialSalesLoss) => {
    const pslResults = potentialSalesLoss
      .map((lossItem) => {
        const totalInventory =
          parseFloat(lossItem["combined_inventory_data_blinkit.frontend_inv_qty"]) +
          parseFloat(lossItem["combined_inventory_data_blinkit.backend_inv_qty"]);

        const dailyRunRate = parseFloat(lossItem["combined_sales_report_blinkit.daily_run_rate"]);

        const inventoryDays = totalInventory / dailyRunRate;
        const itemId = lossItem["combined_inventory_data_blinkit.item_id"];
        const itemFacilityName = lossItem["combined_inventory_data_blinkit.backend_facility_name"];

        const fulfillmentData = pslBlinkitFulfillment?.data?.filter(
          (fulfillmentItem) =>
            fulfillmentItem["fulfillment_report_blinkit.item_id"] === itemId &&
            fulfillmentItem["fulfillment_report_blinkit.facility_name"] === itemFacilityName
        );

        if (fulfillmentData.length === 0) {
          return null; // Skip processing this item if no fulfillment data
        }

        const lastPoRaisedDate = fulfillmentData
          .map((item) => item["fulfillment_report_blinkit.order_date"])
          .reduce((latest, current) => (dayjs(current).isAfter(latest) ? current : latest));

        const currentDate = dayjs(selectedDate);
        const lastPoDate = dayjs(lastPoRaisedDate);
        const poRaisedDays = currentDate.diff(lastPoDate, "day");
        const outOfStockDays = averageTime - (poRaisedDays + inventoryDays);

        // If outOfStockDays is negative, skip this item
        if (outOfStockDays <= 0) {
          return null;
        }

        // Use the MRP from the sales report
        const mrp = parseFloat(lossItem["combined_sales_report_blinkit.per_unit_price"]);

        const psl = outOfStockDays * dailyRunRate * mrp;

        return {
          internal_sku_code: lossItem["combined_inventory_data_blinkit.brand_internal_sku_code"],
          city: lossItem["combined_inventory_data_blinkit.brands_choice"],
          totalInventory,
          daily_run_rate: dailyRunRate,
          lastPoRaisedDate: lastPoDate,
          AVAT: averageTime.toFixed(2),
          outOfStockDays: outOfStockDays,
          PSL: psl,
        };
      })
      .filter((item) => item !== null); // Filter out null items

    setPslData(pslResults);
  };

  useEffect(() => {
    if (pslBlinkitFulfillment?.data?.length && potentialSalesLossBlinkit?.data?.length) {
      calculatePSL(potentialSalesLossBlinkit?.data);
    } else {
      console.log("pslBlinkitFulfillment or potentialSalesLossBlinkit is empty");
    }
  }, [pslBlinkitFulfillment?.data, potentialSalesLossBlinkit?.data]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="bg-white shadow-md rounded-xl ">
      <div>
        <Typography variant="h6" component="h1" className="flex gap-3 items-center pb-4 border-b p-2">
          <div className="p-2 rounded-full bg-gray-200">
            <LuTrendingDown />
          </div>
          Potential Sales Loss
        </Typography>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    minWidth: "200px",
                    position: "sticky",
                    left: 0,
                    bgcolor: "white",
                  }}
                >
                  SKU Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  City
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Total Inventory
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Daily Run Rate
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Last PO Raised Date
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Average Turn Around Time
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Out Of Stock Days
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  PSL
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pslData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      color: "#667085black",
                      fontSize: "1rem",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      minWidth: "200px",
                      position: "sticky",
                      left: 0,
                      bgcolor: "white",
                    }}
                  >
                    {row.internal_sku_code}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.city}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.totalInventory}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.daily_run_rate.toFixed(2)}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {dayjs(row.lastPoRaisedDate).format("DD-MM-YYYY")}
                  </TableCell>

                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.AVAT}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.outOfStockDays.toFixed(0)}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.PSL.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination Component */}
        <TablePagination
          component="div"
          count={pslData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </div>
    </div>
  );
};

export default PotentialSalesLossBlinkitTable;
