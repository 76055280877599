import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import {
  STOCKING_INSIGHTS_BLINKIT_REQUEST,
  STOCKING_INSIGHTS_BLINKIT_SUCCESS,
  STOCKING_INSIGHTS_BLINKIT_FAILURE,
  STOCKING_INSIGHTS_INSTAMART_REQUEST,
  STOCKING_INSIGHTS_INSTAMART_SUCCESS,
  STOCKING_INSIGHTS_INSTAMART_FAILURE,
  STOCKING_INSIGHTS_ZEPTO_REQUEST,
  STOCKING_INSIGHTS_ZEPTO_FAILURE,
  STOCKING_INSIGHTS_ZEPTO_SUCCESS,
} from "../constants/stockingInsightsConstants";

export const stockingInsightsBlinkitRequest = () => {
  return {
    type: STOCKING_INSIGHTS_BLINKIT_REQUEST,
  };
};

export const stockingInsightsBlinkitSuccess = (report) => {
  return {
    type: STOCKING_INSIGHTS_BLINKIT_SUCCESS,
    payload: report,
  };
};
export const stockingInsightsBlinkitFailure = (error) => {
  return {
    type: STOCKING_INSIGHTS_BLINKIT_FAILURE,
    payload: error,
  };
};

export const stockingInsightsInstamartRequest = () => {
  return {
    type: STOCKING_INSIGHTS_INSTAMART_REQUEST,
  };
};

export const stockingInsightsInstamartSuccess = (report) => {
  return {
    type: STOCKING_INSIGHTS_INSTAMART_SUCCESS,
    payload: report,
  };
};

export const stockingInsightsInstamartFailure = (error) => {
  return {
    type: STOCKING_INSIGHTS_INSTAMART_FAILURE,
    payload: error,
  };
};

export const stockingInsightsZeptoRequest = () => {
  return {
    type: STOCKING_INSIGHTS_ZEPTO_REQUEST,
  };
};

export const stockingInsightsZeptoSuccess = (report) => {
  return {
    type: STOCKING_INSIGHTS_ZEPTO_SUCCESS,
    payload: report,
  };
};

export const stockingInsightsZeptoFailure = (error) => {
  return {
    type: STOCKING_INSIGHTS_ZEPTO_FAILURE,
    payload: error,
  };
};
