import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaChevronRight } from "react-icons/fa";
import sidebarItems from "../../../utils/sidebarItems"; // Adjusted import path
import { useDispatch, useSelector } from "react-redux";
import { resetSidebar, setSidebarOpen } from "../../../redux/actions/SidebarActions";
import SubmenuPortal from "./SubmenuPortal"; // Import the portal component
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";

const Sidebar = ({ collapsed, setCollapsed }) => {
  const submenuOpen = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useState(null);
  const [collapsedSubmenuOpen, setCollapsedSubmenuOpen] = useState(null);
  const [submenuPosition, setSubmenuPosition] = useState({ top: 0, left: 0 });
  const [current, setCurrent] = useState("");
  const userDetail = localStorage.getItem("user");
  const parsedUser = JSON.parse(userDetail);
  const { selectedDate, setSelectedDate } = useDate();

  const navigateHandler = (event, pathname, isSubmenu) => {
    event.preventDefault();
    if (isSubmenu) {
      const currentDate = dayjs.utc().startOf("day");
      setSelectedDate(currentDate);
      navigate(pathname);
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
    for (let i = 0; i < sidebarItems.length; i++) {
      for (let j = 0; j < sidebarItems[i].children.length; j++) {
        if (sidebarItems[i].children[j].path == pathname) {
          dispatch(setSidebarOpen(i));
          return;
        }
      }
    }
  }, []);

  useEffect(() => {
    sidebarItems.forEach((item, index) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (child.path === pathname) {
            dispatch(setSidebarOpen(index)); // Open the submenu
            setCurrent(item.name); // Set current menu name
          }
        });
      }
    });
  }, [dispatch, pathname, collapsed]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
    dispatch(resetSidebar());
    setCollapsedSubmenuOpen(null); // Reset submenu state when sidebar is toggled
  };

  const toggleSubmenu = (index, item, event, isSubmenu) => {
    if (collapsed) {
      const rect = event.currentTarget.getBoundingClientRect();
      setSubmenuPosition({ top: rect.bottom, left: rect.left + rect.width });
      setCollapsedSubmenuOpen(index === collapsedSubmenuOpen ? null : index);
    } else {
      if (!isSubmenu) {
        dispatch(setSidebarOpen(index));
      }
    }
    setCurrent(item.name);
  };

  const renderMenuItems = (items, isSubmenu = false) => {
    return items.map((item, index) => (
      <div key={index} className={`${pathname === item.path && "bg-[#EBF2FE] text-black rounded-full"}`}>
        <Link to={item.path} onClick={(event) => navigateHandler(event, item.path, isSubmenu)}>
          <div
            className={`flex items-center p-2 cursor-pointer ${
              !collapsed && "rounded-full mb-2"
            } hover:bg-[rgb(229,231,235,0.1)] ${isSubmenu ? "pl-10" : ""} ${collapsed ? "justify-center py-4" : ""} ${
              collapsed && current == item.name ? "border-l-4" : ""
            }`}
            onClick={(event) => {
              if (item.children && item.children.length > 0) {
                toggleSubmenu(index, item, event, false);
              }
            }}
          >
            <div className={`${!collapsed && "mr-2"}`}>{isSubmenu && collapsed ? null : item.icon}</div>

            <span className={`flex-1 ${collapsed ? "hidden" : ""}`}>{item.name}</span>

            {item.children && item.children.length > 0 && (
              <div
                className={`ml-2 transition-transform duration-300 ${submenuOpen[index] ? "rotate-90" : "rotate-0"}`}
              >
                {!collapsed && <FaChevronRight />}
              </div>
            )}
          </div>
        </Link>
        {/* Regular submenu rendering */}

        {item.children && item.children.length > 0 && !collapsed && (
          <div
            className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
              submenuOpen[index] ? "max-h-screen" : "max-h-0"
            }`}
          >
            {renderMenuItems(item?.children, true)}
          </div>
        )}
      </div>
    ));
  };

  if (pathname === "/login") {
    return null;
  }

  return (
    <div
      className={`z-50 fixed top-0 left-0 h-screen overflow-y-scroll bg-navBarColour text-white ${
        collapsed ? "w-20" : "min-w-[250px] max-w-[25px]"
      } transition-width duration-300`}
    >
      <div className={`flex p-4 mb-8 ${collapsed ? "justify-center" : "justify-between"}`}>
        <div className="flex flex-col mt-[1.5rem] w-full">
          <div className={`flex ${collapsed ? "justify-center" : "justify-between"}`}>
            {!collapsed && (
              <img className="w-40 -mt-[1rem]" src={parsedUser?.organizationDetail?.brandLogo} alt="Brand Logo" />
            )}
            <button onClick={handleToggle} className="text-white cursor-pointer">
              <FaBars className="text-3xl" />
            </button>
          </div>
          <div className="flex flex-row gap-2 mt-3">
            {!collapsed && <span className="text-sm text-backgroundWhite font-medium">Powered By</span>}
            {!collapsed && <img src="/logos/brandLogoWhite.svg" alt="Brand Logo" />}
          </div>
        </div>
      </div>
      <div className={`${!collapsed && "px-2"}`}>{renderMenuItems(sidebarItems)}</div>

      {/* Render the submenu portal if collapsed and submenu is open */}
      {collapsedSubmenuOpen !== null && (
        <SubmenuPortal
          items={sidebarItems[collapsedSubmenuOpen].children}
          isVisible={true}
          position={submenuPosition}
          onClose={() => setCollapsedSubmenuOpen(null)}
        />
      )}
    </div>
  );
};

export default Sidebar;
