import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectGroup({ text, selectFields, setField, field }) {
  return (
    <div>
      <FormControl sx={{ minWidth: 120, maxHeight: "10px" }}>
        <Select
          value={field}
          onChange={setField}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{text}</em>;
            }
            return selected;
          }}
        >
          <MenuItem value="" disabled>
            <em>{text}</em>
          </MenuItem>
          {selectFields.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
