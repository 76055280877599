import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RxCross1 } from "react-icons/rx";
import { RiMenu2Line } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "90%", // Set max height for the modal
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  p: 0,
  overflow: "hidden", // Ensure the modal does not overflow
};

const MapModal = ({ city, pincode, cityData, onClose, open, handleClose }) => {
  const skuData = cityData?.skus || {};
  const getBackgroundColor = (percentage) => {
    if (percentage < 30) return "bg-red-500"; // red
    if (percentage >= 30 && percentage <= 70) return "bg-yellow-500"; // yellow
    return "bg-green-500"; // green
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* Heading container */}
          <div className="flex justify-between items-center mb-4 bg-black text-white p-4 rounded-t-2xl">
            <h2 className="text-xl font-bold flex items-center gap-2">
              <RiMenu2Line className="text-3xl" />
              City Based Availability - {city}
            </h2>
            <RxCross1
              className="text-xl cursor-pointer"
              onClick={handleClose}
            />
          </div>

          <div
            className="bg-white p-6 rounded-lg shadow-lg w-full overflow-y-auto"
            style={{ maxHeight: "calc(90vh - 100px)" }}
          >
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}
              >
                <TableHead>
                  <TableRow className="bg-gray-200">
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#000",
                        fontSize: "1rem",
                        textAlign: "left",
                        borderTop: "1px solid rgba(224, 224, 224, 1)",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        bgcolor: "white",
                        left: 0,
                        maxWidth: "200px",
                      }}
                    >
                      <div>SKU Name</div>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#000",
                        fontSize: "1rem",
                        textAlign: "center",
                        borderTop: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <div>Availability Percentage</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(skuData).map(([sku, data]) => {
                    const backgroundColor = getBackgroundColor(
                      data.availabilityPercentage
                    );
                    return (
                      <TableRow key={sku}>
                        <TableCell
                          sx={{
                            color: "#667085",
                            fontSize: "1rem",
                            boxShadow:
                              "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            position: "sticky",
                            bgcolor: "white",
                            left: 0,
                            maxWidth: "300px",
                          }}
                        >
                          {sku}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#667085",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                          style={{ textAlign: "center" }}
                        >
                          <div
                            className={`inline-block ${backgroundColor} px-2 py-1 rounded-full text-white mui-table-css text-left`}
                          >
                            {Math.ceil(data.availabilityPercentage)}%
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MapModal;
