import {
  GET_THIRD_PARTY_DISCOUNTING_DATA_REQUEST,
  GET_THIRD_PARTY_DISCOUNTING_DATA_SUCCESS,
  GET_THIRD_PARTY_DISCOUNTING_DATA_FAILURE,
  SELECTED_DISCOUNTING_CATEGORY,
  SELECTED_DISCOUNTING_CITY,
  SELECT_TYPE,
} from "../constants/discountConstants";

const initialState = {
  discountingData: {
    loading: false,
    data: null,
    error: null,
  },
  selectedDiscountCategory: null,
  selectedDiscountCity: null,
  selectedType: null,
};

export const discountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_THIRD_PARTY_DISCOUNTING_DATA_REQUEST:
      return {
        ...state,
        discountingData: {
          ...state.discountingData,
          loading: true,
          error: null,
        },
      };
    case GET_THIRD_PARTY_DISCOUNTING_DATA_SUCCESS:
      return {
        ...state,
        discountingData: {
          ...state.discountingData,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_THIRD_PARTY_DISCOUNTING_DATA_FAILURE:
      return {
        ...state,
        discountingData: {
          ...state.discountingData,
          loading: false,
          data: null,
          error: action.payload,
        },
      };

    case SELECTED_DISCOUNTING_CATEGORY:
      return {
        ...state,
        selectedDiscountCategory: action.payload,
      };
    case SELECTED_DISCOUNTING_CITY:
      return {
        ...state,
        selectedDiscountCity: action.payload,
      };
    case SELECT_TYPE:
      return {
        ...state,
        selectedType: action.payload,
      };
    default:
      return state;
  }
};
