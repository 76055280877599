import {
  GET_ALL_SALES_REQUEST,
  GET_ALL_SALES_SUCCESS,
  GET_ALL_SALES_FAILURE,
  INVENTORY_REPORT_BLINKIT_REQUEST,
  INVENTORY_REPORT_BLINKIT_SUCCESS,
  INVENTORY_REPORT_BLINKIT_FAILURE,
  INVENTORY_REPORT_INSTAMART_REQUEST,
  INVENTORY_REPORT_INSTAMART_SUCCESS,
  INVENTORY_REPORT_INSTAMART_FAILURE,
  INVENTORY_REPORT_ZEPTO_REQUEST,
  INVENTORY_REPORT_ZEPTO_SUCCESS,
  INVENTORY_REPORT_ZEPTO_FAILURE,
} from "../constants/SalesConstants";

const initialState = {
  totalSales: {
    loading: false,
    data: [],
    error: null,
  },
  inventoryReportBlinkit: {
    loading: false,
    data: [],
    error: null,
  },
  inventoryReportInstamart: {
    loading: false,
    data: [],
    error: null,
  },
  inventoryReportZepto: {
    loading: false,
    data: [],
    error: null,
  },
};

export const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SALES_REQUEST:
      return {
        ...state,
        totalSales: {
          ...state.totalSales,
          loading: true,
          data: [],
          erorr: null,
        },
      };
    case GET_ALL_SALES_SUCCESS:
      return {
        ...state,
        totalSales: { loading: false, data: action.payload, error: null },
      };
    case GET_ALL_SALES_FAILURE:
      return {
        ...state,
        totalSales: { loading: false, error: action.payload, data: [] },
      };


    case INVENTORY_REPORT_BLINKIT_REQUEST:
      return {
        ...state,
        inventoryReportBlinkit: {
          ...state.inventoryReportBlinkit,
          loading: true,
          data: [],
          erorr: null,
        },
      };

    case INVENTORY_REPORT_BLINKIT_SUCCESS:
      return {
        ...state,
        inventoryReportBlinkit: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case INVENTORY_REPORT_BLINKIT_FAILURE:
      return {
        ...state,
        inventoryReportBlinkit: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case INVENTORY_REPORT_INSTAMART_REQUEST:
      return {
        ...state,
        inventoryReportInstamart: {
          ...state.inventoryReportInstamart,
          loading: true,
          data: [],
          erorr: null,
        },
      };

    case INVENTORY_REPORT_INSTAMART_SUCCESS:
      return {
        ...state,
        inventoryReportInstamart: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case INVENTORY_REPORT_INSTAMART_FAILURE:
      return {
        ...state,
        inventoryReportInstamart: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case INVENTORY_REPORT_ZEPTO_REQUEST:
      return {
        ...state,
        inventoryReportZepto: {
          ...state.inventoryReportZepto,
          loading: true,
          data: [],
          erorr: null,
        },
      };

    case INVENTORY_REPORT_ZEPTO_SUCCESS:
      return {
        ...state,
        inventoryReportZepto: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case INVENTORY_REPORT_ZEPTO_FAILURE:
      return {
        ...state,
        inventoryReportZepto: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};
