import React from "react";
import CardComponent from "./CardComponent";

// Function to get top low ROI campaigns for ProductListing
const getToatalSpendForSubparCampagins = (groupedData) => {
  let totalSpendFinal = 0;
  let count = 0;
  for (const campaignName in groupedData) {
    for (const targetingValue in groupedData[campaignName]) {
      const { totalSpend, ROI } = groupedData[campaignName][targetingValue];

      if (ROI < 4) {
        count++;
        totalSpendFinal += totalSpend;
      }
    }
  }
  return { totalSpendFinal, count };
};

// Function to calculate total spend and count for ProductRecommendation where ROI < 4
const calculateTotalSpendForProductRecommendation = (groupedData) => {
  let totalSpendFinal = 0;
  let count = 0;

  // Iterate over each campaign in groupedData
  for (const campaignName in groupedData) {
    const { totalSpend, ROI } = groupedData[campaignName];

    // Accumulate total spend and count for campaigns with ROI < 4
    if (ROI < 4) {
      count++;
      totalSpendFinal += totalSpend;
    }
  }

  return { totalSpendFinal, count };
};

const LeastPerformDataCardsPanel = ({ groupedData, option }) => {
  let totalSpendOLeastPerformCampaigns = { totalSpendFinal: 0, count: 0 };
  // Use switch case to handle the option prop
  switch (option) {
    case "ProductListing":
      totalSpendOLeastPerformCampaigns = getToatalSpendForSubparCampagins(groupedData);
      break;
    case "ProductRecommandation":
      totalSpendOLeastPerformCampaigns = calculateTotalSpendForProductRecommendation(groupedData);
      break;
    case "CampaignPerformance":
      totalSpendOLeastPerformCampaigns = calculateTotalSpendForProductRecommendation(groupedData);
      break;
    case "MarketingReport":
      totalSpendOLeastPerformCampaigns = getToatalSpendForSubparCampagins(groupedData);
      break;
    case "SkuPerformance":
      totalSpendOLeastPerformCampaigns = calculateTotalSpendForProductRecommendation(groupedData);
      break;
    default:
      console.warn(`Unknown option: ${option}`);
  }

  // Define card titles based on the option
  const cardTitles = {
    ProductListing: {
      totalSpend: "Total Spend on Subpar Campaigns",
      numSubparCampaigns: "Total Number of Subpar Campaign Keyword Combinations",
    },
    ProductRecommandation: {
      totalSpend: "Total Spend on Subpar Campaigns",
      numSubparCampaigns: "Total Number of Subpar Campaigns",
    },
    CampaignPerformance: {
      totalSpend: "Total Spend on Subpar Campaigns",
      numSubparCampaigns: "Total Number of Subpar Campaigns",
    },
    MarketingReport: {
      totalSpend: "Total Spend on Subpar Campaigns",
      numSubparCampaigns: "Total Number of Subpar Campaign Keyword Combinations",
    },
    SkuPerformance: {
      totalSpend: "Total Spend on Subpar Product Campaign",
      numSubparCampaigns: "Total Number of Subpar Campaign Product Campaigns",
    },
  };

  const cardData = [
    {
      totalAmount: totalSpendOLeastPerformCampaigns.totalSpendFinal,
      title: cardTitles[option]?.totalSpend || "Total Spend",
      icon: "📉",
      unit: "INR",
    },
    {
      totalAmount: totalSpendOLeastPerformCampaigns.count,
      title: cardTitles[option]?.numSubparCampaigns || "Total Number of Subpar Campaigns",
      icon: "📉",
      unit: "",
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {cardData.map((card, index) => (
        <CardComponent
          key={index}
          totalAmount={card.totalAmount}
          title={card.title}
          icon={card.icon}
          unit={card.unit}
        />
      ))}
    </div>
  );
};

export default LeastPerformDataCardsPanel;
