import { Chart as ChartJS, registerables } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";

const colors = [
  "#ff1493", // Deep Pink
  "#000080", // Navy
  "#ffa500", // Orange
  "#dc143c", // Crimson
  "#00fa9a", // Medium Spring Green
  "#ffb6c1", // Light Pink
  "#000000", // Black
  "#696969", // Dim Gray
  "#006400", // Dark Green
  "#808000", // Olive
  "#483d8b", // Dark Slate Blue
  "#3cb371", // Medium Sea Green
  "#008b8b", // Dark Cyan
  "#32cd32", // Lime Green
  "#800080", // Purple
  "#b03060", // Maroon 3
  "#00ff00", // Lime
  "#8a2be2", // Blue Violet
  "#0000ff", // Blue
  "#adff2f", // Green Yellow
  "#ffff54", // Laser Lemon
  "#add8e6", // Light Blue
  "#8b4513", // Saddle Brown
  "#7b68ee", // Medium Slate Blue
  "#fafad2", // Light Goldenrod
];

ChartJS.register(...registerables);

const CitySkuSplitLineChart = ({ allCategories, cityChartData, city }) => {

  const categories = new Set();
  const dates = Object.keys(cityChartData);

  dates.forEach((date) => {
    Object.keys(cityChartData[date]).forEach((category) => {
      if (cityChartData[date][category].totalSales) {
        categories.add(category);
      }
    });
  });

  const datasets = Array.from(categories).map((category) => ({
    label: category,
    data: [],
    backgroundColor: colors[allCategories.indexOf(category)],
    barThickness: 24,
  }));

  // Populate dataset with quantities
  dates.forEach((date) => {
    const dayData = cityChartData[date];
    datasets.forEach((dataset) => {
      dataset.data.push(dayData[dataset.label]?.totalSales);
    });
  });

  const chartData = {
    labels: dates,
    datasets: datasets,
  };

  if (!Array.from(categories).length) {
    return null;
  }

  return (
    <div className="min-w-[48%] max-w-[48%] p-4 custom-shadow min-h-80 rounded-md mb-4">
      <div className="text-center font-semibold py-1">{city}</div>
      <Bar
        className="max-h-80 min-h-80 min-w-full max-w-full"
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
                font: {
                  size: 10, // Adjust the font size to make the circles smaller
                },
              },
            },
            title: {
              display: true,
              text: "Total Sales in " + city,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
            },
          },
        }}
      />
    </div>
  );
};

export default CitySkuSplitLineChart;
