import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RxCross1 } from "react-icons/rx";
import { RiMenu2Line } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  p: 0,
};

const KeywordBasedCompetitorsRankingModal = ({ open, handleClose, data }) => {
  const [selectedKeyword, setSelectedKeyword] = useState(null);

  useEffect(() => {
    const keys = Object.keys(data?.detailsGroupedByKeyword);

    setSelectedKeyword(keys[0]);
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjusted opacity here
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* heading container */}
          <div className="flex justify-between items-center mb-4 bg-black text-white p-4 rounded-t-2xl">
            <h2 className="text-xl font-bold flex items-center gap-2">
              <RiMenu2Line className="text-3xl" />
              Competitor Ranking for SKU- {data?.skuName}
            </h2>
            <RxCross1 className="text-xl cursor-pointer" onClick={handleClose} />
          </div>

          {/* <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"> */}
          <div className="bg-white p-6 rounded-lg shadow-lg w-full  max-h-[70vh] overflow-y-auto flex">
            {/* Left side: Keywords  */}
            <div className="w-3/12   pr-4 border-r border-gray-300 overflow-y-auto max-h-[70vh]">
              <h2 className="flex flex-col text-lg font-bold mb-4 ">
                <span>Keyword List For</span>
                <span className="text-xl text-navBarColour ">{data.skuName}</span>
              </h2>
              {Object.keys(data.detailsGroupedByKeyword).map((keyword, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedKeyword(keyword)}
                  className={`p-2 mb-2 cursor-pointer capitalize rounded-md  ${
                    selectedKeyword === keyword ? "bg-navBarColour text-white hover:bg-[#2a4f8b] " : "hover:bg-gray-100"
                  }`}
                >
                  {keyword}
                </div>
              ))}
            </div>

            {/* Right side: Keyword details table*/}
            <div className="flex flex-col pl-4 w-9/12 max-h-[70vh]">
              <div className="w-full pl-4 overflow-y-auto max-h-[70vh]">
                {selectedKeyword && (
                  <>
                    <h3 className="text-xl font-semibold mb-2 ">Details For Keyword - {selectedKeyword}</h3>
                    <TableContainer component={Paper} className="overflow-x-auto">
                      <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                        <TableHead>
                          <TableRow className="bg-gray-200">
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                color: "#000",
                                fontSize: "1rem",
                                textAlign: "left",
                                borderTop: "1px solid rgba(224, 224, 224, 1)",
                                boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                zIndex: "3",
                                position: "sticky",
                                bgcolor: "white",
                                left: 0,
                                maxWidth: "200px",
                              }}
                            >
                              <div>Brand Name</div>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                color: "#000",
                                fontSize: "1rem",
                                textAlign: "left",
                                borderTop: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <div> Product Name</div>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                color: "#000",
                                fontSize: "1rem",
                                textAlign: "left",
                                borderTop: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <div>Overall Ranking</div>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                color: "#000",
                                fontSize: "1rem",
                                textAlign: "left",
                                borderTop: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <div>Organic Ranking</div>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                color: "#000",
                                fontSize: "1rem",
                                textAlign: "left",
                                borderTop: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <div>Sponsored Ranking</div>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderTop: "1px solid rgba(224, 224, 224, 1)",
                                boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                zIndex: "3",
                                position: "sticky",
                                bgcolor: "white",
                                right: 0,
                              }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.detailsGroupedByKeyword[selectedKeyword]
                            .sort((a, b) => a.slot - b.slot)
                            .map((detail, detailIndex) => (
                              <TableRow key={detailIndex} hover>
                                <TableCell
                                  sx={{
                                    color: "#667085",
                                    fontSize: "1rem",
                                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    position: "sticky",
                                    bgcolor: "white",
                                    left: 0,
                                    maxWidth: "300px",
                                  }}
                                >
                                  {detail.brand}
                                </TableCell>
                                <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                                  <div></div>
                                  {detail.productName}
                                </TableCell>
                                <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>{detail.slot}</TableCell>
                                <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>{detail.organicRank}</TableCell>
                                <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                                  {detail.sponsoredRank}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color: "black",
                                    fontSize: "1rem",
                                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    zIndex: "3", // Left shadow for this cell,
                                    position: "sticky",
                                    bgcolor: "white",
                                    right: 0,
                                  }}
                                ></TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                {!selectedKeyword && <p className="text-gray-500">Select a keyword to see details.</p>}
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default KeywordBasedCompetitorsRankingModal;
