import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { BsCalendar2Month } from "react-icons/bs";
import { FaCity } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  cityProfitabilityGroupingBlinkit,
  cityProfitabilityGroupingInstamart,
  cityProfitabilityGroupingZepto,
} from "../../../utils/dataGrouping/OverallProfitability/CityProfitabilityGrouping";

const CityProfitability = ({ text, headings, platformName }) => {
  const { cityProfitability } = useSelector((state) => state.profitability);
  const tableData = cityProfitability.data;

  let groupedData;
  //set grouped data according to platform name
  if (platformName == "Blinkit") {
    groupedData = cityProfitabilityGroupingBlinkit(tableData);
  } else if (platformName == "Instamart") {
    groupedData = cityProfitabilityGroupingInstamart(tableData);
  } else {
    groupedData = cityProfitabilityGroupingZepto(tableData);
  }

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
        marginTop: "2rem",
      }}
    >
      <>
        <div className="flex text-xl items-center gap-4 p-4 font-bold">
          {text == "Month Wise Profitability" ? (
            <BsCalendar2Month />
          ) : text == "City Wise Profitability" ? (
            <FaCity />
          ) : (
            <MdCategory />
          )}{" "}
          {text}
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
            <TableHead>
              <TableRow>
                {headings.map((heading, index) => (
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "1rem",
                      textAlign: "left",
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                      minWidth: "200px",
                      boxShadow: index == 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: index == 0 && "3",
                      position: index == 0 && "sticky",
                      left: index === 0 && 0,
                      bgcolor: index == 0 && "white",
                    }}
                    key={heading}
                  >
                    {heading}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedData?.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      minWidth: "200px",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      position: "sticky",
                      left: 0,
                      bgcolor: "white",
                    }}
                  >
                    {row.category}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      minWidth: "200px",
                    }}
                  >
                    {row.mrp.toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      minWidth: "200px",
                    }}
                  >
                    {row.gst.toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      minWidth: "200px",
                    }}
                  >
                    {row.netSales.toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      minWidth: "200px",
                    }}
                  >
                    {row.cogs.toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      minWidth: "200px",
                    }}
                  >
                    {row.cm1.toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "black",
                      fontSize: "1rem",
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3", // Left shadow for this cell,
                      position: "sticky",
                      bgcolor: "white",
                      right: 0,
                    }}
                  ></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Paper>
  );
};

export default CityProfitability;
