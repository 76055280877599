import React from "react";

const NoDataFound = () => {
  return (
    <div className="w-full h-[520px] flex justify-center items-center rounded-xl">
      <img src="/icons/no-data-found.svg" alt="no-data-found" className="w-full h-full rounded-xl" />
    </div>
  );
};

export default NoDataFound;
