import React from "react";
import { useSelector } from "react-redux";
import {
  groupedDataByCampaignNameAndKeyword,
  groupedDataByDate,
} from "../../../utils/dataGrouping/LeastPerformance/MarketingReportLeastPerformance";
import { FaCalendarDays } from "react-icons/fa6";
import { LuListOrdered } from "react-icons/lu";
import LeastPerformDataCardsPanel from "./LeastPerformDataCardsPanel";
import TopTenWorstPerformKeyword from "./TopTenWorstPerformKeyword";
import LeastPerfromDatesWise from "./LeastPerfromDatesWise";
import { Skeleton } from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const MarketingReportLeastPerformanceSectionsContainer = () => {
  const { leastPerformingInstamartMarketingReport } = useSelector((state) => state.leastPerforming);
  const { loading, error, data } = leastPerformingInstamartMarketingReport;
  if (loading) {
    return (
      <div className="w-full">
        <div className="mb-2 bg-white rounded p-2 flex gap-10">
          <Skeleton variant="rounded" sx={{ width: "30%", height: "150px" }} />
          <Skeleton variant="rounded" sx={{ width: "30%", height: "150px" }} />
        </div>

        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }
  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  const groupedByCampaignNameAndKeyword = groupedDataByCampaignNameAndKeyword(
    leastPerformingInstamartMarketingReport.data
  );
  const groupedDataByCampaignDateAndKeyword = groupedDataByDate(leastPerformingInstamartMarketingReport.data);
  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      {/* <h2 className="text-lg font-semibold mb-4">Performance Overview</h2> */}
      <div>
        <div>
          <LeastPerformDataCardsPanel groupedData={groupedByCampaignNameAndKeyword} option={"MarketingReport"} />
          {/* <TopTenCompaignBySpend groupedData={groupedData} /> */}
        </div>

        <div className="mt-4">
          <div className="bg-white mt-4 shadow-md rounded-xl mb-4">
            <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
              <div>
                <LuListOrdered />
              </div>
              Top Ten Worst Performing Campaign Combination Details
            </h1>
            <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
              <TopTenWorstPerformKeyword groupedData={groupedByCampaignNameAndKeyword} option={"MarketingReport"} />
            </div>
          </div>

          <div className="bg-white mt-4 shadow-md rounded-xl mb-4">
            <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
              <div>
                <FaCalendarDays />
              </div>
              Worst Performing Keyword Datewise
            </h1>
            <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
              <LeastPerfromDatesWise groupedData={groupedDataByCampaignDateAndKeyword} option={"MarketingReport"} />
            </div>
          </div>

          {/* <TopTenKeywordsByBudget groupedData={groupedDataKeyword} /> */}
        </div>
        <div className="mt-4">{/* <ProductListingChart groupedData={groupedProductsListingChartData} /> */}</div>
        <div className="mt-4">{/* <AdCampaigns groupedDataAd={groupedByCampaign} /> */}</div>
      </div>
    </div>
  );
};

export default MarketingReportLeastPerformanceSectionsContainer;
