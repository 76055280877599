import { blinkitQuery, instamartQuery, zeptoQuery } from "../../utils/queries/stockingInsightQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

// Fetch Blinkit data for daily view
export const fetchStockingInsightsBlinkitData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQuery(selectedDate));
  return result.tablePivot();
};

// Fetch Instamart data for daily view
export const fetchStockingInsightsInstamartData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQuery(selectedDate));
  return result.tablePivot();
};

// Fetch Zepto data for daily view
export const fetchStockingInsightsZeptoData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQuery(selectedDate));
  return result.tablePivot();
};
