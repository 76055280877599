import React from "react";
import SelectGroup from "./Select"; // Custom select component
import { useDispatch, useSelector } from "react-redux"; // Hooks for interacting with Redux
import {
  addFinalCategory,
  resetFilters, // Import the reset action from Redux
} from "../../../redux/actions/chartActions"; // Redux actions for managing category filters
import { MdFilterAltOff } from "react-icons/md"; // Icon for reset button

const CategoryFilterSelector = ({ platformName }) => {
  // Initialize dispatch to dispatch actions
  const dispatch = useDispatch();

  // Access total sales data and selected category from Redux state
  const { totalSales } = useSelector((state) => state.sales);
  const { finalCategory } = useSelector((state) => state.chart);

  // Function to determine the category field based on platformName
  const getCategoryField = () => {
    switch (platformName) {
      case "Blinkit":
        return "combined_sales_report_blinkit.category";
      case "Zepto":
        return "combined_sales_report_zepto.category";
      case "Instamart":
        return "combined_sales_report_instamart.category";
      default:
        return "combined_sales_report_blinkit.category"; // Provide a fallback field if necessary
    }
  };

  // Retrieve the correct category field for the selected platform
  const categoryField = getCategoryField();

  // Extract category data from total sales based on the selected platform
  const categories = totalSales.data?.map((item) => item[categoryField]);

  // Ensure unique categories by removing duplicates
  const uniqueCategories = [...new Set(categories)];

  // Function to reset filters when the reset button is clicked
  const handleReset = () => {
    dispatch(resetFilters()); // Dispatch the resetFilters action
  };

  return (
    <div className="flex items-center h-16 gap-4 ">
      {/* Category selection dropdown */}
      <SelectGroup
        text={"Category"}
        selectFields={uniqueCategories} // Populate dropdown with unique categories
        setField={(e) => dispatch(addFinalCategory(e.target.value))} // Dispatch selected category
        field={finalCategory} // Selected category from Redux state
      />

      {/* Reset filters button */}
      <div
        onClick={handleReset} // Reset filters when clicked
        className="border mt-7 border-[#EAEDF3] px-4 py-2 small-shadow flex items-center rounded-md cursor-pointer"
      >
        {/* Reset icon */}
        <MdFilterAltOff />
        <span>Reset</span>
      </div>
    </div>
  );
};

export default CategoryFilterSelector;

// Indexing of the code:

// --Category Extraction:
// Uses the selected category field to extract category data from the totalSales data.
// Use case: Gather the categories from sales data, ensuring the correct platform field is used.

// --Unique Categories:
// Removes duplicate categories using Set.
// Use case: Display unique categories in the dropdown for better user experience.

// --Reset Filter Function:
// Resets the selected category when the reset button is clicked by dispatching the resetFilters action.
// Use case: Provide users with a way to clear all category filters and reset to the default state.

// --Category Selection Dropdown:
// The SelectGroup component is used for the category selection dropdown. When a user selects a category, it updates the Redux state with the selected value.
// Use case: Allow users to filter sales data by category.

// --Reset Button:
// A reset button with an icon (MdFilterAltOff) that triggers the resetFilters action to clear the selected filters.
// Use case: Allow users to quickly reset the category filter.
