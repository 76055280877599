import React, { useEffect, useState } from "react"; // Importing React and its hooks
import {
  blinkitFullfillmentReportQuery,
  instamartFulfillmentQuery,
  zeptoFulfillmentQuery,
} from "../../utils/queries/fulfillmentReportQueries"; // Import query functions for each platform

import {
  fetchFulfillmentReportBlinkitData,
  fetchFulfillmentReportInstamartData,
  fetchFulfillmentReportZeptoData,
} from "../../services/fulfillmentServices/fulfillmentReportServices";

import { useDispatch } from "react-redux"; // Redux hooks to dispatch actions and select state
import {
  getBlinkitFulfillmentRequest,
  getBlinkitFulfillmentSuccess,
  getBlinkitFulfillmentFailure,
  getZeptoFulfillmentRequest,
  getZeptoFulfillmentSuccess,
  getZeptoFulfillmentFailure,
  getInstamartFulfillmentRequest,
  getInstamartFulfillmentSuccess,
  getInstamartFulfillmentFailure,
} from "../../redux/actions/fulfillmentActions"; // Redux action creators for handling fulfillment report data

import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider"; // Custom hook to get selected date
import dayjs from "dayjs"; // Importing dayjs for date manipulation
import FulfillmentBlinkitSectionsContainer from "../../components/Fulfillment/FulfillmentReportComponents/FulfillmentBlinkitSectionsContainer";
import FulfillmentInstamartSectionsContainer from "../../components/Fulfillment/FulfillmentReportComponents/FulfillmentInstamartSectionsContainer";
import FulfillmentZeptoSectionsContainer from "../../components/Fulfillment/FulfillmentReportComponents/FulfillmentZeptoSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";



const FulfillmentReportScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit"); // State to track the selected platform, default is "Blinkit"
  const dispatch = useDispatch(); // Dispatch function from Redux
  const { selectedDate } = useDate(); // Get the selected date from custom hook

  const handleBlinkitQueries = async () => {
    try {
      dispatch(getBlinkitFulfillmentRequest());
      const result = await fetchFulfillmentReportBlinkitData(selectedDate);
      dispatch(getBlinkitFulfillmentSuccess(result));
    } catch (error) {
      dispatch(getBlinkitFulfillmentFailure(error));
    }
  };

  const handleZeptoQueries = async () => {
    try {
      dispatch(getZeptoFulfillmentRequest());
      const result = await fetchFulfillmentReportZeptoData(selectedDate);
      dispatch(getZeptoFulfillmentSuccess(result));
    } catch (error) {
      dispatch(getZeptoFulfillmentFailure(error));
    }
  };

  const handleInstamartQueries = async () => {
    try {
      dispatch(getInstamartFulfillmentRequest());
      const result = await fetchFulfillmentReportInstamartData(selectedDate);
      dispatch(getInstamartFulfillmentSuccess(result));
    } catch (error) {
      dispatch(getInstamartFulfillmentFailure(error));
    }
  };

  useEffect(() => {
    if (platformName === "Blinkit") {
      handleBlinkitQueries();
    } else if (platformName === "Instamart") {
      handleInstamartQueries();
    } else {
      handleZeptoQueries();
    }
  }, [platformName, selectedDate]);

  // Helper function to format date without timezone

  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  // Helper function to calculate the date range (last 30 days)
  const calculateDateRange = (selectedDate) => {
    const endDate = formatDateWithoutTimezone(selectedDate); // End date is the selected date
    const startDate = formatDateWithoutTimezone(
      dayjs(selectedDate).subtract(30, "day") // Start date is 30 days before the selected date
    );
    return [startDate, endDate]; // Return the date range as an array
  };

  return (
    <>
      {/* HeadingBar component to display page heading and allow platform selection */}
      <HeadingBar
        setPlatformName={setPlatformName}
        selectedDate={calculateDateRange(selectedDate)} // Pass calculated date range
      />
      <Toolbar>
        {/* Render specific sections based on the selected platform */}
        {platformName == "Blinkit" && <FulfillmentBlinkitSectionsContainer />}
        {platformName == "Instamart" && <FulfillmentInstamartSectionsContainer />}
        {platformName == "Zepto" && <FulfillmentZeptoSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default FulfillmentReportScreen;
