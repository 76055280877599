import {
  blinkitSearchSuggestionQuery,
  blinkitBannerListingQuery,
  zeptoCampaignPerformanceQuery,
  zeptoAwarenessPerformanceQuery,
} from "../../utils/queries/displayAdsQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchDisplayAdsSearchSuggestionDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitSearchSuggestionQuery(selectedDate));
  return result.tablePivot();
};

export const fetchDisplayAdsBannerListingDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitBannerListingQuery(selectedDate));
  return result.tablePivot();
};

export const fetchDisplayAdsCampaignPerformanceDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoCampaignPerformanceQuery(selectedDate));
  return result.tablePivot();
};

export const fetchAwarenessPerformanceDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoAwarenessPerformanceQuery(selectedDate));
  return result.tablePivot();
};
