import { SiSimpleanalytics } from "react-icons/si";
import { IoReturnDownBackOutline } from "react-icons/io5";
import { FaBalanceScale } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { LuWarehouse } from "react-icons/lu";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoPieChartSharp } from "react-icons/io5";
import { RiAdvertisementLine } from "react-icons/ri";
const sidebarItems = [
  {
    icon: <BsGraphUpArrow className="text-xl" />,
    name: "Revenue",
    path: "#",
    children: [
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Sales Report",
        path: "/revenue/sales-report",
        children: [],
      },

      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Target Tracker",
        path: "/revenue/target-tracker",
        children: [],
      },
    ],
  },

  {
    icon: <LuWarehouse className="text-xl" />,
    name: "Inventory",
    path: "#",
    children: [
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Locality Availability",
        path: "/inventory/locality-availability",
        children: [],
      },
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Inventory Report",
        path: "/inventory/inventory-report",
        children: [],
      },
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Stocking Insights",
        path: "/inventory/stocking-insights",
        children: [],
      },
    ],
  },

  {
    icon: <RiAdvertisementLine className="text-xl" />,
    name: "Ads",
    path: "#",
    children: [
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Keyword Ranking",
        path: "/ads/keyword-ranking",
        children: [],
      },
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Pricing Detail",
        path: "/ads/pricing-details",
        children: [],
      },
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Performance Marketing Ads",
        path: "/ads/performance-marketing-ads",
        children: [],
      },
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Display Ads",
        path: "/ads/display-ads",
        children: [],
      },
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Least Performing",
        path: "/ads/least-performing",
        children: [],
      },
    ],
  },
  {
    icon: <FaMoneyBillTrendUp className="text-xl" />,
    name: "Profitability",
    path: "#",
    children: [
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Overall Profitability",
        path: "/profitability/overall-profitability",
        children: [],
      },
    ],
  },

  {
    icon: <IoPieChartSharp className="text-xl" />,
    name: "Fulfillment",
    path: "#",
    children: [
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Fulfillment Report",
        path: "/fulfillment/fulfillment-report",
        children: [],
      },
      {
        icon: (
          <IoReturnDownBackOutline
            style={{ transform: "scaleX(-1)" }}
            className="text-xl"
          />
        ),
        name: "Potential Sales Loss",
        path: "/fulfillment/potential-sales-loss",
        children: [],
      },
    ],
  },
];

export default sidebarItems;
