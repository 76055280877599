import React from "react";
import { TbAlarmAverage } from "react-icons/tb";

const FulfillmentCard = ({ total, text }) => {
  return (
    <div className="p-4 border bg-white rounded-lg shadow-md flex items-center space-x-4 w-[25%] h-40">
      <div className="text-3xl">
        <TbAlarmAverage />
      </div>
      <div>
        <h2 className="text-xl font-semibold">{text}</h2>
        <p className="text-lg">{total} </p>
      </div>
    </div>
  );
};

export default FulfillmentCard;
