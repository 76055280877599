export const groupedDataByProductNameSKUPerformance = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const key = item["marketting_report_zepto_sku_performance.product_name"];
    if (!acc[key]) {
      acc[key] = {
        title: item["marketting_report_zepto_sku_performance.product_name"],
        revenue: 0,
        roi: 0,
        spend: 0,
        impressions: 0,
        clicks: 0,
        isCampaign: false,
        details: 0,
      
      };
    }

    // Aggregate the fields
    acc[key].revenue +=
      parseFloat(item["marketting_report_zepto_sku_performance.ad_revenue"]) ||
      0;

    acc[key].roi +=
      parseFloat(item["marketting_report_zepto_sku_performance.roi"]) || 0;
    acc[key].spend +=
      parseFloat(item["marketting_report_zepto_sku_performance.ad_spend"]) || 0;
    acc[key].impressions +=
      parseInt(
        item["marketting_report_zepto_sku_performance.ad_impressions"],
        10
      ) || 0;
    acc[key].clicks +=
      parseInt(item["marketting_report_zepto_sku_performance.ad_clicks"], 10) ||
      0;
    acc[key].details +=
      parseInt(
        item["marketting_report_zepto_sku_performance.detail_page_views"]
      ) || 0;

    return acc;
  }, {});
  return groupedData;
};

export const groupByDateSKUPerformance = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const reportDate =
      item["marketting_report_zepto_sku_performance.date_of_report"];

    if (!acc[reportDate]) {
      acc[reportDate] = {
        revenue: 0,
        tenant_id: item["marketting_report_zepto_sku_performance.tenant_id"],
        roi: 0,
        spend: 0,
        impressions: 0,
        clicks: 0,
        current_date: new Set(),
      };
    }

    // Aggregate the fields

    acc[reportDate].revenue +=
      parseFloat(item["marketting_report_zepto_sku_performance.ad_revenue"]) ||
      0;

    acc[reportDate].roi +=
      parseFloat(item["marketting_report_zepto_sku_performance.roi"]) || 0;
    acc[reportDate].spend +=
      parseFloat(item["marketting_report_zepto_sku_performance.ad_spend"]) || 0;
    acc[reportDate].impressions +=
      parseInt(
        item["marketting_report_zepto_sku_performance.ad_impressions"],
        10
      ) || 0;
    acc[reportDate].clicks +=
      parseInt(item["marketting_report_zepto_sku_performance.ad_clicks"], 10) ||
      0;
    acc[reportDate].current_date.add(
      item["marketting_report_zepto_sku_performance.current_date"]
    );

    return acc;
  }, {});
  return groupedData;
};

export function groupBySKUPerformanceTable(data) {
  const grouped = {};

  data.forEach((item) => {
    const productName =
      item["marketting_report_zepto_sku_performance.product_name"];

    // Initialize the campaign array if it doesn't exist
    if (!grouped[productName]) {
      grouped[productName] = [];
    }

    // Create a key-value pair from the item
    const keyValue = {
      revenue: item["marketting_report_zepto_sku_performance.ad_revenue"],
      roi: item["marketting_report_zepto_sku_performance.roi"],
      spend: item["marketting_report_zepto_sku_performance.ad_spend"],
      impressions:
        item["marketting_report_zepto_sku_performance.ad_impressions"],
      clicks: item["marketting_report_zepto_sku_performance.ad_clicks"],
    };

    // Push the key-value pair into the corresponding campaign array
    grouped[productName].push(keyValue);
  });

  return grouped;
}
