import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import dayjs from "dayjs";

const ShowSelectedDate = ({ date }) => {
  const formatDate = (date) => dayjs(date).format("MMM D, YYYY");

  return (
    <div
      className={`flex flex-row w-auto items-center p-2 gap-1 text-xs rounded-lg bg-[rgb(248,255,229)] border border-orange-300`}
    >
      <IoIosInformationCircleOutline />
      <span>{Array.isArray(date) ? "Data From :" : "Data For"} </span>
      <span className="text-medium">
        {Array.isArray(date) ? `${formatDate(date[0])} - ${formatDate(date[1])}` : formatDate(date)}
      </span>
    </div>
  );
};

export default ShowSelectedDate;
