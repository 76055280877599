//constants

//monthly
export const MONTHLY_PROFITABILITY_REQUEST = "MONTHLY_PROFITABILITY_REQUEST";
export const MONTHLY_PROFITABILITY_SUCCESS = "MONTHLY_PROFITABILITY_SUCCESS";
export const MONTHLY_PROFITABILITY_FAILURE = "MONTHLY_PROFITABILITY_FAILURE";

//category
export const CATEGORY_PROFITABILITY_REQUEST = "CATEGORY_PROFITABILITY_REQUEST";
export const CATEGORY_PROFITABILITY_SUCCESS = "CATEGORY_PROFITABILITY_SUCCESS";
export const CATEGORY_PROFITABILITY_FAILURE = "CATEGORY_PROFITABILITY_FAILURE";

//city
export const CITY_PROFITABILITY_REQUEST = "CITY_PROFITABILITY_REQUEST";
export const CITY_PROFITABILITY_SUCCESS = "CITY_PROFITABILITY_SUCCESS";
export const CITY_PROFITABILITY_FAILURE = "CITY_PROFITABILITY_FAILURE";
