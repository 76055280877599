import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getThirdPartyVisibilityRequest,
  getThirdPartyVisibilitySuccess,
  getThirdPartyVisibilityFailure,
} from "../../redux/actions/thirdPartyActions";
import {
  fetchKeywordRankingDataBlinkit,
  fetchKeywordRankingDataInstamart,
  fetchKeywordRankingDataZepto,
} from "../../services/adsServices/keywordRankingServices";
import SkuVsKeywordsSectionsContainer from "../../components/ads/KeywordRankingComponents/SkuVsKeywordsSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";

// Define options for the ComponentSwitch
const options = ["Sku vs keyword"];

// Object mapping platforms to their respective data fetch functions
const dataFetchFunctions = {
  Blinkit: fetchKeywordRankingDataBlinkit,
  Zepto: fetchKeywordRankingDataZepto,
  Instamart: fetchKeywordRankingDataInstamart,
};

const KeywordRankingScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);

  // Get the selected date from the SingleDatePickerProvider context
  const { selectedDate } = useDate();
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedOption(0); // Reset to "Sku vs keyword" view when platform changes
  }, [platformName]);

  // Effect to fetch keyword ranking data whenever platformName or selectedDate changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getThirdPartyVisibilityRequest());
        const result = await dataFetchFunctions[platformName](selectedDate);
        dispatch(getThirdPartyVisibilitySuccess(result));
      } catch (error) {
        dispatch(getThirdPartyVisibilityFailure(error));
      }
    };
    fetchData();
  }, [platformName, selectedDate]);

  return (
    <>
      {/* HeadingBar component handles platform selection and date display */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />

      <Toolbar>
        {/* ComponentSwitch allows switching between different views */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Conditionally render the SkuVsKeywordsSectionsContainer based on selectedOption */}
        {selectedOption === 0 && <SkuVsKeywordsSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default KeywordRankingScreen;
