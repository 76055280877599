import dayjs from "dayjs";

const getStartAndEndDate = (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(2, "month").toISOString();
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
  return [startDate, endDate];
};

export const blinkitDailyTargetsQuery = (selectedDate) => {
  const [startDate, endDate] = getStartAndEndDate(selectedDate);
  return {
    measures: ["combined_sales_report_blinkit.total_gmv"],
    timeDimensions: [
      {
        dimension: "combined_sales_report_blinkit.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
    dimensions: ["combined_sales_report_blinkit.normalized_date"],
    order: [["combined_sales_report_blinkit.normalized_date", "asc"]],
  };
};

export const zeptoDailyTargetsQuery = (selectedDate) => {
  const [startDate, endDate] = getStartAndEndDate(selectedDate);
  return {
    measures: ["combined_sales_report_zepto.total_gross_merchandise_value"],
    timeDimensions: [
      {
        dimension: "combined_sales_report_zepto.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
    dimensions: ["combined_sales_report_zepto.normalized_date"],
    order: [["combined_sales_report_zepto.normalized_date", "asc"]],
  };
};

export const instamartDailyTargetsQuery = (selectedDate) => {
  const [startDate, endDate] = getStartAndEndDate(selectedDate);
  return {
    measures: ["combined_sales_report_instamart.total_gmv"],
    timeDimensions: [
      {
        dimension: "combined_sales_report_instamart.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
    dimensions: ["combined_sales_report_instamart.normalized_date"],
    order: [["combined_sales_report_instamart.normalized_date", "asc"]],
  };
};
