export const MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_REQUEST =
  "MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_REQUEST";
export const MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_SUCCESS =
  "MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_SUCCESS";
export const MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_FAILURE =
  "MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_FAILURE";

export const MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST =
  "MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST";
export const MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS =
  "MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS";
export const MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE =
  "MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE";

export const MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_REQUEST =
  "MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_REQUEST";
export const MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_SUCCESS =
  "MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_SUCCESS";
export const MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_FAILURE =
  "MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_FAILURE";

export const MARKETING_REPORT_BLINKIT_BANNER_LISTING_REQUEST =
  "MARKETING_REPORT_BLINKIT_BANNER_LISTING_REQUEST";
export const MARKETING_REPORT_BLINKIT_BANNER_LISTING_SUCCESS =
  "MARKETING_REPORT_BLINKIT_BANNER_LISTING_SUCCESS";
export const MARKETING_REPORT_BLINKIT_BANNER_LISTING_FAILURE =
  "MARKETING_REPORT_BLINKIT_BANNER_LISTING_FAILURE";

export const MARKETING_REPORT_INSTAMART_REQUEST =
  "MARKETING_REPORT_INSTAMART_REQUEST";
export const MARKETING_REPORT_INSTAMART_SUCCESS =
  "MARKETING_REPORT_INSTAMART_SUCCESS";
export const MARKETING_REPORT_INSTAMART_FAILURE =
  "MARKETING_REPORT_INSTAMART_FAILURE";

export const SKU_PERFORMANCE_ZEPTO_REQUEST = "SKU_PERFORMANCE_ZEPTO_REQUEST";
export const SKU_PERFORMANCE_ZEPTO_SUCCESS = "SKU_PERFORMANCE_ZEPTO_SUCCESS";
export const SKU_PERFORMANCE_ZEPTO_FAILURE = "SKU_PERFORMANCE_ZEPTO_FAILURE";

export const SKU_ANALYTICS_ZEPTO_REQUEST = "SKU_ANALYTICS_ZEPTO_REQUEST";
export const SKU_ANALYTICS_ZEPTO_SUCCESS = "SKU_ANALYTICS_ZEPTO_SUCCESS";
export const SKU_ANALYTICS_ZEPTO_FAILURE = "SKU_ANALYTICS_ZEPTO_FAILURE";

export const CAMPAIGN_PERFORMANCE_SKU_REQUEST =
  "CAMPAIGN_PERFORMANCE_SKU_REQUEST";
export const CAMPAIGN_PERFORMANCE_SKU_SUCCESS =
  "CAMPAIGN_PERFORMANCE_SKU_SUCCESS";
export const CAMPAIGN_PERFORMANCE_SKU_FAILURE =
  "CAMPAIGN_PERFORMANCE_SKU_FAILURE";

export const AWARENESS_PERFORMANCE_DATA_REQUEST =
  "AWARENESS_PERFORMANCE_DATA_REQUEST";
export const AWARENESS_PERFORMANCE_DATA_SUCCESS =
  "GET_ALL_AWARENESS_PERFORMANCE_DATA_SUCCESS";
export const AWARENESS_PERFORMANCE_DATA_FAILURE =
  "AWARENESS_PERFORMANCE_DATA_FAILURE";
