import React, { useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LeastPerformDatesWise = ({ groupedData, option }) => {
  const { tableHeaders, tableRows } = useMemo(() => {
    let headers = [];
    let rows = (data) => [];

    switch (option) {
      case "ProductListing":
        headers = [
          "Campaign Name",
          "Keyword Name",
          "Total Spend",
          "Total Revenue",
          "ROI",
          "Impressions",
          "CPM",
          "MVP",
        ];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_blinkit_product_listing.campaign_name"],
            item["marketting_report_blinkit_product_listing.targeting_value"],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
            item.cpm,
            item.mvp,
          ]);
        break;

      case "ProductRecommandation":
        headers = [
          "Campaign Name",
          "Total Spend",
          "Total Revenue",
          "ROI",
          "Impressions",
          "CPM",
        ];
        rows = (data) =>
          data.map((item) => [
            item[
              "marketting_report_blinkit_product_recommendation.campaign_name"
            ],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
            item.cpm,
          ]);
        break;

      case "CampaignPerformance":
        headers = [
          "Campaign Name",
          "Total Spend",
          "Total Revenue",
          "ROI",
          "Impressions",
        ];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_zepto_campaign_performance.campaign_name"],
            item.totalSpend.toFixed(2),
            item.totalRevenue,
            item.ROI.toFixed(2),
            item.impression,
          ]);
        break;

      case "MarketingReport":
        headers = [
          "Campaign Name",
          "Keyword Name",
          "Total Spend",
          "Total Revenue",
          "ROI",
          "Impressions",
        ];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_instamart.campaign_name"],
            item["marketting_report_instamart.keyword"],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
          ]);
        break;

      case "SkuPerformance":
        headers = [
          "Campaign Name",
          "Total Spend",
          "Total Revenue",
          "ROI",
          "Impressions",
        ];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_zepto_sku_performance.product_name"],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
          ]);
        break;

      default:
        console.warn(`Unknown option: ${option}`);
    }

    return { tableHeaders: headers, tableRows: rows };
  }, [option]);

  return (
    <div>
      {Object.entries(groupedData).map(([date, { data }], index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{date}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}
              >
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          fontWeight: "500",
                          color: "#000",
                          fontSize: "1rem",
                          textAlign: "left",
                          borderTop: "1px solid rgba(224, 224, 224, 1)",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          bgcolor: "white",
                          left: index === 0 ? 0 : "auto",
                          maxWidth: index === 0 ? "200px" : "auto",
                        }}
                        style={{
                          minWidth: index === 0 ? 150 : "auto",
                          textAlign: index !== 0 ? "center" : "",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows(data).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell
                          key={cellIndex}
                          sx={{
                            color: "#667085",
                            fontSize: "1rem",
                            cursor: "pointer",
                            boxShadow:
                              cellIndex === 0
                                ? "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"
                                : "none",
                            position: cellIndex === 0 ? "sticky" : "static",
                            bgcolor: "white",
                            left: cellIndex === 0 ? 0 : "auto",
                            maxWidth: cellIndex === 0 ? "200px" : "auto",
                            textAlign: cellIndex === 0 ? "left" : "center",
                          }}
                          style={{ minWidth: cellIndex === 0 ? 150 : "auto" }}
                        >
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default LeastPerformDatesWise;
