export const targetsQuery = {
  dimensions: [
    "static_combined_data.sub_category",
    "static_combined_data.brand_internal_sku_code",
    "static_combined_data.instamart_sku_code",
    "static_combined_data.category",
    "static_combined_data.tenantid",
    "static_combined_data.zepto_sku_code",
    "static_combined_data.blinkit_sku_code",
  ],
  order: {
    "static_combined_data.sub_category": "asc",
  },
  filters: [
    {
      member: "static_combined_data.brand_internal_sku_code",
      operator: "set",
    },
  ],
};
