import React from "react";
import { useSelector } from "react-redux"; // Import the useSelector hook to access Redux state
import WeeklyLevelOverviewLineChart from "./WeeklyLevelOverviewLineChart";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

// Functional component to display a weekly overview section with a chart
const WeeklyLevelOverviewSectionsContainer = () => {
  // Access the weekly third-party availability data from Redux state
  const { thirdPartyAvailabilityWeekly } = useSelector((state) => state.thirdParty);

  // Extract unique brands from the weekly data, if available, and store in an array
  const weeklyBrands = Array.isArray(thirdPartyAvailabilityWeekly?.data)
    ? Array.from(new Set(thirdPartyAvailabilityWeekly.data.map((item) => item["third_party_availability.brand"])))
    : [];

  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      {/* Container for the chart section */}
      <div className="mt-4 mb-4 rounded-lg w-full flex flex-col items-center p-1">
        {/* Conditionally render content based on the loading state and data availability */}
        {thirdPartyAvailabilityWeekly?.loading ? (
          // Display a skeleton loader while data is being fetched
          <div className="w-full">
            <ChartSkeleton />
          </div>
        ) : !Array.isArray(thirdPartyAvailabilityWeekly.data) || thirdPartyAvailabilityWeekly.data.length === 0 ? (
          // Display a "No Data Found" message if there is no valid data
          <NoDataFound />
        ) : (
          // Render the line chart with the fetched data and unique brands
          <WeeklyLevelOverviewLineChart apiData={thirdPartyAvailabilityWeekly?.data || []} brands={weeklyBrands} />
        )}
      </div>
    </div>
  );
};

export default WeeklyLevelOverviewSectionsContainer;
