import React from "react";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";

const PagePath = () => {
  const location = useLocation();

  // Get the location path segments, normalize them
  const pathSegments = location.pathname
    .split("/")
    .filter((x) => x)
    .map((segment) => segment.replace(/-/g, " ")) // Replace hyphens with spaces
    .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1)); // Capitalize each segment

  return (
    <Box display="flex" alignItems="center" color="#333">
      {/* Home icon */}
      <HomeIcon sx={{ marginRight: 0.2, color: "#E5E7EB", cursor: "pointer" }} />
      <ChevronRightIcon sx={{ marginX: 0.2, color: "#E5E7EB" }} />
      {/* Render each path segment */}
      {pathSegments.map((segment, index) => (
        <Box key={index} display="flex" alignItems="center">
          {/* Pointer icon between segments */}
          {index > 0 && <ChevronRightIcon sx={{ marginX: 0.2, color: "#E5E7EB" }} />}
          {/* Highlight the last segment */}
          <Typography
            variant="body1"
            sx={{
              textTransform: "capitalize",
              fontWeight: index === pathSegments.length - 1 ? "semibold" : "normal",
              color: index === pathSegments.length - 1 ? "#007bff" : "inherit",
              backgroundColor: index === pathSegments.length - 1 ? "#EBF2FE" : "",
              padding: index === pathSegments.length - 1 ? "8px" : "",
              borderRadius: index === pathSegments.length - 1 ? "10px" : "",
            }}
          >
            {segment}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PagePath;
