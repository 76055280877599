import {
  PSL_BLINKIT_FULFILLMENT_REQUEST,
  PSL_BLINKIT_FULFILLMENT_SUCCESS,
  PSL_BLINKIT_FULFILLMENT_FAILURE,
  PSL_ZEPTO_FULFILLMENT_REQUEST,
  PSL_ZEPTO_FULFILLMENT_SUCCESS,
  PSL_ZEPTO_FULFILLMENT_FAILURE,
  PSL_INSTAMRT_FULFILLMENT_REQUEST,
  PSL_INSTAMRT_FULFILLMENT_SUCCESS,
  PSL_INSTAMRT_FULFILLMENT_FAILURE,
} from "../constants/pslFulfillmentConstants";

const initialState = {
  pslBlinkitFulfillment: {
    loading: false,
    data: null,
    error: null,
  },

  pslZeptoFulfillment: {
    loading: false,
    data: null,
    error: null,
  },
  pslInstamartFulfillment: {
    loading: false,
    data: null,
    error: null,
  },
};

export const pslFulfillmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PSL_BLINKIT_FULFILLMENT_REQUEST:
      return {
        ...state,
        pslBlinkitFulfillment: {
          ...state.pslBlinkitFulfillment,
          loading: true,
          error: null,
        },
      };
    case PSL_BLINKIT_FULFILLMENT_SUCCESS:
      return {
        ...state,
        pslBlinkitFulfillment: {
          ...state.pslBlinkitFulfillment,
          loading: false,
          data: action.payload,
        },
      };
    case PSL_BLINKIT_FULFILLMENT_FAILURE:
      return {
        ...state,
        pslBlinkitFulfillment: {
          ...state.pslBlinkitFulfillment,
          loading: false,
          error: action.payload,
        },
      };

    case PSL_ZEPTO_FULFILLMENT_REQUEST:
      return {
        ...state,
        pslZeptoFulfillment: {
          ...state.pslZeptoFulfillment,
          loading: true,
          error: null,
        },
      };
    case PSL_ZEPTO_FULFILLMENT_SUCCESS:
      return {
        ...state,
        pslZeptoFulfillment: {
          ...state.pslZeptoFulfillment,
          loading: false,
          data: action.payload,
        },
      };
    case PSL_ZEPTO_FULFILLMENT_FAILURE:
      return {
        ...state,
        pslZeptoFulfillment: {
          ...state.pslZeptoFulfillment,
          loading: false,
          error: action.payload,
        },
      };

    case PSL_INSTAMRT_FULFILLMENT_REQUEST:
      return {
        ...state,
        pslInstamartFulfillment: {
          ...state.pslInstamartFulfillment,
          loading: true,
          error: null,
        },
      };
    case PSL_INSTAMRT_FULFILLMENT_SUCCESS:
      return {
        ...state,
        pslInstamartFulfillment: {
          ...state.pslInstamartFulfillment,
          loading: false,
          data: action.payload, // This line should set the data correctly
        },
      };

    case PSL_INSTAMRT_FULFILLMENT_FAILURE:
      return {
        ...state,
        pslInstamartFulfillment: {
          ...state.pslInstamartFulfillment,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
