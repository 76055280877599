export const PSL_BLINKIT_FULFILLMENT_REQUEST =
  "PSL_BLINKIT_FULFILLMENT_REQUEST";
export const PSL_BLINKIT_FULFILLMENT_SUCCESS =
  "PSL_BLINKIT_FULFILLMENT_SUCCESS";
export const PSL_BLINKIT_FULFILLMENT_FAILURE =
  "PSL_BLINKIT_FULFILLMENT_FAILURE";
export const PSL_ZEPTO_FULFILLMENT_REQUEST = "PSL_ZEPTO_FULFILLMENT_REQUEST";
export const PSL_ZEPTO_FULFILLMENT_SUCCESS = "PSL_ZEPTO_FULFILLMENT_SUCCESS";
export const PSL_ZEPTO_FULFILLMENT_FAILURE = "PSL_ZEPTO_FULFILLMENT_FAILURE";
export const PSL_INSTAMRT_FULFILLMENT_REQUEST =
  "PSL_INSTAMRT_FULFILLMENT_REQUEST";
export const PSL_INSTAMRT_FULFILLMENT_SUCCESS =
  "PSL_INSTAMRT_FULFILLMENT_SUCCESS";
export const PSL_INSTAMRT_FULFILLMENT_FAILURE =
  "PSL_INSTAMRT_FULFILLMENT_FAILURE";
