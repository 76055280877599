import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
} from "@mui/material";
import "./css/CityWiseSales.css";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";

// Function to normalize data based on the platform name
const normalizeData = (data, platformName) => {
  if (platformName === "Zepto") {
    return {
      platform: "zepto",
      category: data["combined_sales_report_zepto.category"],
      subCategory: data["combined_sales_report_zepto.sub_category"],
      itemName: data["combined_sales_report_zepto.sku_name"],
      mrp: parseFloat(data["combined_sales_report_zepto.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_zepto.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_zepto.brands_choice"],
    };
  } else if (platformName === "Blinkit") {
    return {
      platform: "blinkit",
      category: data["combined_sales_report_blinkit.category"],
      subCategory: data["combined_sales_report_blinkit.sub_category"],
      itemName: data["combined_sales_report_blinkit.item_name"],
      mrp: parseFloat(data["combined_sales_report_blinkit.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_blinkit.qty_sold"]),
      gstRate: 0.18,
      city: data["combined_sales_report_blinkit.brands_choice"],
    };
  } else if (platformName === "Instamart") {
    return {
      platform: "instamart",
      category: data["combined_sales_report_instamart.brand_internal_sku_code"],
      subCategory: data["combined_sales_report_instamart.sub_category"],
      itemName: data["combined_sales_report_instamart.product_name"],
      mrp: parseFloat(data["combined_sales_report_instamart.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_instamart.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_instamart.brands_choice"],
    };
  }
  return null;
};

// Function to group data by city
const groupDataByCity = (data, platformName) => {
  const grouped = {};

  data.forEach((item) => {
    const normalizedItem = normalizeData(item, platformName);
    if (!normalizedItem) return;

    const { city, qtySold, mrp } = normalizedItem;

    if (!grouped[city]) {
      grouped[city] = {
        totalQtySold: 0,
        totalMrp: 0,
      };
    }

    // Accumulate totals for each city
    grouped[city].totalQtySold += qtySold;
    grouped[city].totalMrp += mrp;
  });

  return grouped;
};

// Function to calculate overall totals from grouped data
const calculateTotals = (groupedData) => {
  return Object.values(groupedData).reduce(
    (totals, cityData) => {
      totals.totalQtySold += cityData.totalQtySold;
      totals.totalMrp += cityData.totalMrp;

      return totals;
    },
    {
      totalQtySold: 0,
      totalMrp: 0,
    }
  );
};

// Function to format numbers with locale-specific formatting
const formatNumber = (number) => {
  return number.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// Main component for displaying city-wise sales data
const CityWiseSalesTable = ({ platformName }) => {
  const [selectedCategory, setSelectedCategory] = useState("All Categories"); // State for selected category
  const [categories, setCategories] = useState([]); // State for categories list
  const { totalSales } = useSelector((state) => state.sales); // Fetch sales data from Redux store
  const [groupedData123, setGroupedData123] = useState([]); // State for grouped data

  useEffect(() => {
    // Function to group data by brands_choice and calculate total GMV
    const groupData = () => {
      const result = totalSales.data.reduce((acc, item) => {
        const brand = item["combined_sales_report_blinkit.brands_choice"];
        const gmv = parseFloat(item["combined_sales_report_blinkit.gmv"]);

        if (!acc[brand]) {
          acc[brand] = { brand, totalGmv: 0 };
        }

        acc[brand].totalGmv += gmv;
        return acc;
      }, {});

      setGroupedData123(Object.values(result));
    };

    // Delay the execution by 10 seconds
    const timer = setTimeout(groupData, 10000);

    // Cleanup the timer if the component unmounts before the delay
    return () => clearTimeout(timer);
  }, [totalSales]);

  useEffect(() => {
    // Extract unique categories from totalSales data
    if (totalSales?.data?.length) {
      const categorySet = new Set();
      totalSales.data.forEach((item) => {
        const normalizedItem = normalizeData(item, platformName);
        if (normalizedItem?.category) {
          categorySet.add(normalizedItem.category);
        }
      });
      setCategories(["All Categories", ...Array.from(categorySet)]);
    }
  }, [totalSales]);

  if (!totalSales?.data?.length) {
    return <TableSkeleton />; // Display loading skeleton if no data
  }

  // Extract unique cities for filtering
  const cities = [...new Set(totalSales.data.map((item) => normalizeData(item, platformName)?.city))].filter(Boolean);

  // Filter data based on selected category
  const filteredData = totalSales.data.filter((item) => {
    const normalizedItem = normalizeData(item, platformName);
    return selectedCategory === "All Categories" || normalizedItem?.category === selectedCategory;
  });

  // Group data by city
  const groupedData = groupDataByCity(filteredData, platformName);

  // Calculate totals for all grouped data
  const totals = calculateTotals(groupedData);

  return (
    <div className="w-full h-[70vh] overflow-scroll">
      <div className="flex sticky-container min-w-[100%] justify-between items-center mb-0 bg-white p-3">
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Select Category" }}
          style={{ marginRight: "10px" }}
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "200px", // Set your desired width here
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                }}
              >
                City
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "right",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Total Quantity Sold
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "right",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Total MRP
              </TableCell>
              <TableCell
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedData).map((city) => {
              const cityData = groupedData[city];
              return (
                <TableRow key={city} className=" table-row">
                  <TableCell
                    sx={{
                      width: "200px",
                      color: "black",
                      fontSize: "1rem",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    }}
                    className="flex items-center justify-between"
                  >
                    {city}
                  </TableCell>
                  <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                    <div className="mui-table-css text-right text-[#667085]">{formatNumber(cityData.totalQtySold)}</div>
                  </TableCell>
                  <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                    <div className="mui-table-css text-right text-[#667085]">{formatNumber(cityData.totalMrp)}</div>
                  </TableCell>
                  <TableCell
                    sx={{
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3",
                      position: "sticky",
                      right: 0,
                      bgcolor: "white",
                    }}
                  ></TableCell>
                </TableRow>
              );
            })}
            <TableRow className="total-row">
              <TableCell
                sx={{
                  width: "250px",
                  color: "black",
                  fontSize: "1rem",
                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                }}
                className="flex items-center justify-between"
              >
                Total
              </TableCell>
              <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                <div className="mui-table-css text-right text-[#667085]">{formatNumber(totals.totalQtySold)}</div>
              </TableCell>
              <TableCell align="right" sx={{ color: "black", fontSize: "1rem" }}>
                <div className="mui-table-css text-right text-[#667085]">{formatNumber(totals.totalMrp)}</div>
              </TableCell>
              <TableCell
                sx={{
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CityWiseSalesTable;

// Use Cases
// --CityWiseSalesTable Component

// View Sales Data by City: Displays sales metrics (total quantity sold and MRP) grouped by city.
// Filter Sales Data by Category: Allows filtering the sales data based on selected categories.
// Update Data with Delay: Processes and updates sales data with a delay for recent changes.
// Adapt to Different Platforms: Handles sales data for various platforms like Zepto, Blinkit, and Instamart.
// Show Loading State: Displays a loading skeleton when data is being fetched or processed.
