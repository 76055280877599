export const monthlyProfitabilityGroupingBlinkit = (tableData) => {
  return tableData?.map((row) => ({
    month: row.month,
    mrp: parseFloat(row.data[0]["combined_sales_report_blinkit.total_gmv"]),
    gst: parseFloat(row.data[0]["combined_sales_report_blinkit.total_taxes_paid"]),
    cogs: parseFloat(row.data[0]["combined_sales_report_blinkit.total_cogs"]),
    netSales:
      parseFloat(row.data[0]["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row.data[0]["combined_sales_report_blinkit.total_taxes_paid"]),
    cm1:
      parseFloat(row.data[0]["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row.data[0]["combined_sales_report_blinkit.total_taxes_paid"]) -
      parseFloat(row.data[0]["combined_sales_report_blinkit.total_cogs"]),
  }));
};

export const monthlyProfitabilityGroupingZepto = (tableData) => {
  return tableData?.map((row) => ({
    month: row.month,
    mrp: parseFloat(row.data[0]["combined_sales_report_zepto.total_gross_merchandise_value"]),
    gst: parseFloat(row.data[0]["combined_sales_report_zepto.total_taxes_paid"]),
    cogs: parseFloat(row.data[0]["combined_sales_report_zepto.total_cogs"]),
    netSales:
      parseFloat(row.data[0]["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row.data[0]["combined_sales_report_zepto.total_taxes_paid"]),
    cm1:
      parseFloat(row.data[0]["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row.data[0]["combined_sales_report_zepto.total_taxes_paid"]) -
      parseFloat(row.data[0]["combined_sales_report_zepto.total_cogs"]),
  }));
};

export const monthlyProfitabilityGroupingInstamart = (tableData) => {
  return tableData?.map((row) => ({
    month: row.month,
    mrp: parseFloat(row.data[0]["combined_sales_report_instamart.total_gmv"]),
    gst: parseFloat(row.data[0]["combined_sales_report_instamart.total_taxes_paid"]),
    cogs: parseFloat(row.data[0]["combined_sales_report_instamart.total_cogs"]),
    netSales:
      parseFloat(row.data[0]["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row.data[0]["combined_sales_report_instamart.total_taxes_paid"]),
    cm1:
      parseFloat(row.data[0]["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row.data[0]["combined_sales_report_instamart.total_taxes_paid"]) -
      parseFloat(row.data[0]["combined_sales_report_instamart.total_cogs"]),
  }));
};
