import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TableSortLabel,
} from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";


const OverStockedTableBlinkit = () => {
  const { stockingInsightBlinkit } = useSelector((state) => state.stocking);

  // Assuming stockingInsightBlinkit has a structure with loading, error, and data properties
  const { loading, data } = stockingInsightBlinkit;

  const dataWithTotalInventory = data.map((item) => ({
    ...item,
    totalInventory:
      parseInt(item["combined_inventory_data_blinkit.frontend_inv_qty"], 10) +
      parseInt(item["combined_inventory_data_blinkit.backend_inv_qty"], 10),
  }));
  const groupedData = {};
  dataWithTotalInventory.forEach((item) => {
    const key = `${item["combined_inventory_data_blinkit.backend_facility_name"]}_${item["combined_inventory_data_blinkit.brand_internal_sku_code"]}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  const newModifiedData = [];
  Object.keys(groupedData).forEach((key) => {
    const group = groupedData[key];

    group.sort(
      (a, b) =>
        new Date(a["combined_inventory_data_blinkit.created_at"]) -
        new Date(b["combined_inventory_data_blinkit.created_at"])
    );

    const latestEntry = group[group.length - 1];

    let dailyRunRate =
      parseFloat(latestEntry["combined_sales_report_blinkit.daily_run_rate"]) ||
      0;

    let inventoryDays = 0;
    if (dailyRunRate > 0) {
      inventoryDays = latestEntry.totalInventory / dailyRunRate;
    }

    newModifiedData.push({
      ...latestEntry,
      dailyRunRate,
      inventoryDays: isNaN(inventoryDays) ? 0 : inventoryDays,
    });
  });

  const filteredData = newModifiedData.filter(
    (item) => item.inventoryDays > 10
  );
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("item_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({
    item_id: "",
    city: "",
    item_name: "",
  });

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const filteredAndSortedData = filteredData
    .filter((item) =>
      (item["combined_inventory_data_blinkit.item_id"] || "")
        .toLowerCase()
        .includes(filter.item_id.toLowerCase())
    )
    .filter((item) =>
      (item["combined_inventory_data_blinkit.brands_choice"] || "")
        .toLowerCase()
        .includes(filter.city.toLowerCase())
    )
    .filter((item) =>
      (item["combined_inventory_data_blinkit.item_name"] || "")
        .toLowerCase()
        .includes(filter.item_name.toLowerCase())
    )
    .sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });

  const paginatedData = filteredAndSortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
      }}
    >
      <>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    minWidth: "200px",
                    position: "sticky",
                    left: 0,
                    bgcolor: "white",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "item_name"}
                    direction={orderBy === "item_name" ? order : "asc"}
                    onClick={() => handleRequestSort("item_name")}
                  >
                    <div className="mui-table-css text-[#000]">
                      Blinkit SKU Name
                    </div>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "city"}
                    direction={orderBy === "city" ? order : "asc"}
                    onClick={() => handleRequestSort("city")}
                  >
                    <div className="mui-table-css">City</div>
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "city"}
                    direction={orderBy === "city" ? order : "asc"}
                    onClick={() => handleRequestSort("city")}
                  >
                    <div className="mui-table-css">Facility Name</div>
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "item_id"}
                    direction={orderBy === "item_id" ? order : "asc"}
                    onClick={() => handleRequestSort("item_id")}
                  >
                    <div className="mui-table-css ">Item ID</div>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "totalInventory"}
                    direction={orderBy === "totalInventory" ? order : "asc"}
                    onClick={() => handleRequestSort("totalInventory")}
                  >
                    <div className="mui-table-css">Total Inventory</div>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "dailyRunRate"}
                    direction={orderBy === "dailyRunRate" ? order : "asc"}
                    onClick={() => handleRequestSort("dailyRunRate")}
                  >
                    <div className="mui-table-css">Daily Run Rate</div>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "inventoryDays"}
                    direction={orderBy === "inventoryDays" ? order : "asc"}
                    onClick={() => handleRequestSort("inventoryDays")}
                  >
                    <div className="mui-table-css">Inventory Days</div>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length > 0 ? (
                paginatedData.map((row, index) => (
                  <TableRow key={index} hover>
                    <TableCell
                      sx={{
                        color: "black",
                        fontSize: "1rem",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        minWidth: "200px",
                        position: "sticky",
                        left: 0,
                        bgcolor: "white",
                      }}
                    >
                      <div className="text-left text-[#667085]">
                        {row["combined_inventory_data_blinkit.item_name"]}
                      </div>
                    </TableCell>
                    <TableCell sx={{ color: "black", fontSize: "1rem" }}>
                      <div className="text-left text-[#667085]">
                        {row["combined_inventory_data_blinkit.brands_choice"]}
                      </div>
                    </TableCell>
                    <TableCell sx={{ color: "black", fontSize: "1rem" }}>
                      <div className="text-left text-[#667085]">
                        {
                          row[
                            "combined_inventory_data_blinkit.backend_facility_name"
                          ]
                        }
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "black",
                        fontSize: "1rem",
                      }}
                    >
                      <div className="text-left text-[#667085]">
                        {row["combined_inventory_data_blinkit.item_id"]}
                      </div>
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      <div className="text-left text-[#667085]">
                        {row.totalInventory.toFixed(2)}
                      </div>
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      <div className="text-left text-[#667085]">
                        {row.dailyRunRate.toFixed(2)}
                      </div>
                    </TableCell>
                    <TableCell sx={{ color: "#667085", fontSize: "1rem" }}>
                      <div className="text-left text-[#667085]">
                        {row.inventoryDays.toFixed(2)}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "white",
                      }}
                    ></TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    className="text-center text-[0.75rem] text-[#727278]"
                  >
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25]}
          component="div"
          count={filteredAndSortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    </Paper>
  );
};

export default OverStockedTableBlinkit;
