import {
    LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_REQUEST,
    LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_SUCCESS,
    LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_FAILURE,
    LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST,
    LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS,
    LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE,
    LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_REQUEST,
    LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_SUCCESS,
    LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_FAILURE,
    LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_REQUEST,
    LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_SUCCESS,
    LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_FAILURE,
    LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_REQUEST,
    LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_SUCCESS,
    LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_FAILURE,
  } from "../constants/leastPerformingConstants";
  
  // Blinkit Product Listing Actions
  export const getAllLeastPerformingBlinkitProductListingRequest = () => ({
    type: LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_REQUEST,
  });
  
  export const getAllLeastPerformingBlinkitProductListingSuccess = (report) => ({
    type: LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_SUCCESS,
    payload: report,
  });
  
  export const getAllLeastPerformingBlinkitProductListingFailure = (error) => ({
    type: LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_FAILURE,
    payload: error,
  });
  
  // Blinkit Product Recommendation Actions
  export const getAllLeastPerformingBlinkitProductRecommendationRequest = () => ({
    type: LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST,
  });
  
  export const getAllLeastPerformingBlinkitProductRecommendationSuccess = (
    report
  ) => ({
    type: LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS,
    payload: report,
  });
  
  export const getAllLeastPerformingBlinkitProductRecommendationFailure = (
    error
  ) => ({
    type: LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE,
    payload: error,
  });
  
  // Zepto SKU Performance Actions
  export const getAllLeastPerformingSkuPerformanceZeptoRequest = () => ({
    type: LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_REQUEST,
  });
  
  export const getAllLeastPerformingSkuPerformanceZeptoSuccess = (report) => ({
    type: LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_SUCCESS,
    payload: report,
  });
  
  export const getAllLeastPerformingSkuPerformanceZeptoFailure = (error) => ({
    type: LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_FAILURE,
    payload: error,
  });
  
  // Campaign Performance SKU Actions
  export const getAllLeastPerformingCampaignPerformanceSkuRequest = () => ({
    type: LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_REQUEST,
  });
  
  export const getAllLeastPerformingCampaignPerformanceSkuSuccess = (report) => ({
    type: LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_SUCCESS,
    payload: report,
  });
  
  export const getAllLeastPerformingCampaignPerformanceSkuFailure = (error) => ({
    type: LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_FAILURE,
    payload: error,
  });
  
  // Instamart Marketing Report Actions
  export const getAllLeastPerformingMarketingReportInstamartRequest = () => ({
    type: LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_REQUEST,
  });
  
  export const getAllLeastPerformingMarketingReportInstamartSuccess = (report) => ({
    type: LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_SUCCESS,
    payload: report,
  });
  
  export const getAllLeastPerformingMarketingReportInstamartFailure = (error) => ({
    type: LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_FAILURE,
    payload: error,
  });
  