import React from "react";
import PotentialSalesLossInstamartTable from "./PotentialSalesLossInstamartTable";

import { useSelector } from "react-redux";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const PotentialSalesLossInstamartSectionsContainer = () => {
  const { potentialSalesLossInstamart } = useSelector((state) => state.potentailSalesLoss);
  const { pslInstamartFulfillment } = useSelector((state) => state.pslFulfillment);

  // Check if either data is loading
  if (potentialSalesLossInstamart.loading || pslInstamartFulfillment.loading) {
    return (
      <div className="w-full">
        <TableSkeleton />
      </div>
    );
  }

  // Check if either data is null or empty
  if (
    potentialSalesLossInstamart.data === null ||
    potentialSalesLossInstamart.data.length === 0 ||
    pslInstamartFulfillment.data === null ||
    pslInstamartFulfillment.data.length === 0
  ) {
    return <NoDataFound />; // Show NoDataFound if no data is found
  }

  // Render the table if data is available
  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      <PotentialSalesLossInstamartTable />
    </div>
  );
};

export default PotentialSalesLossInstamartSectionsContainer;
