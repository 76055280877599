import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDateRange } from "../../../hooks/CustomDateRange/CustomDateRangePickerProvider";

export default function CustomDateRangePicker() {
  const { selectedDateRange, setSelectedDateRange } = useDateRange();

  const handleStartDateChange = (date) => {
    setSelectedDateRange([date, selectedDateRange[1]]);
  };

  const handleEndDateChange = (date) => {
    setSelectedDateRange([selectedDateRange[0], date]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        display="flex"
        gap={2}
        sx={{
          width: 330,
          "& .MuiOutlinedInput-root": {
            borderRadius: 8,
            padding: "0 4px",
            height: 32,
          },
          "& .MuiOutlinedInput-input": {
            height: "100%",
            fontSize: "0.875rem",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "& .MuiInputLabel-root": {
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "0.75rem",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            top: "-6px",
            left: "0",
            right: "0",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#1976d2",
            top: "-6px",
            fontSize: "0.95rem",
          },
          "& .MuiInputAdornment-root": {
            marginRight: "12px",
          },
        }}
      >
        <DatePicker
          label="Start Date"
          value={selectedDateRange[0]}
          onChange={handleStartDateChange}
          maxDate={dayjs()} // Restrict to current date or earlier
          renderInput={(params) => <TextField {...params} />}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
              padding: "0 4px",
              height: "36px",
            },
            "& .MuiInputLabel-root": {
              color: "#1976d2",
              top: "-6px",
              fontSize: "0.95rem",
            },
          }}
        />
        <DatePicker
          label="End Date"
          value={selectedDateRange[1]}
          onChange={handleEndDateChange}
          maxDate={dayjs()} // Restrict to current date or earlier
          renderInput={(params) => <TextField {...params} />}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
              padding: "0 4px",
              height: "36px",
            },
            "& .MuiInputLabel-root": {
              color: "#1976d2",
              top: "-6px",
              fontSize: "0.95rem",
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}
