import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// ProductListing
const getTopLowROICampaigns = (groupedData) => {
  return Object.entries(groupedData)
    .flatMap(([campaignName, targets]) =>
      Object.entries(targets).map(([targetingValue, { totalSpend, totalRevenue, ROI }]) => ({
        campaignName,
        targetingValue,
        totalSpend,
        totalRevenue,
        ROI,
      }))
    )
    .filter(({ ROI }) => ROI < 4)
    .sort((a, b) => {
      if (b.totalSpend !== a.totalSpend) {
        return b.totalSpend - a.totalSpend; // Sort by total spend descending
      }
      return a.ROI - b.ROI; // Then sort by ROI ascending
    })
    .slice(0, 10);
};

// ProductRecommendation
const getTopLowROICampaignsRecommendation = (groupedData) => {
  return Object.entries(groupedData)
    .map(([campaignName, { totalSpend, totalRevenue, ROI }]) => ({
      campaignName,
      totalSpend,
      totalRevenue,
      ROI,
    }))
    .filter(({ ROI }) => ROI < 4)
    .sort((a, b) => b.totalSpend - a.totalSpend)
    .slice(0, 10);
};

const TopTenWorstPerformKeyword = ({ groupedData, option }) => {
  let topLowROICampaigns = [];
  let tableHeaders = [];
  let tableRows = [];

  switch (option) {
    case "ProductListing":
      topLowROICampaigns = getTopLowROICampaigns(groupedData);
      tableHeaders = [
        "Campaign Name",
        "Keyword",
        "Total Spend",
        "Total Revenue",
        "ROI",
      ];
      tableRows = topLowROICampaigns.map((campaign) => [
        campaign.campaignName,
        campaign.targetingValue,
        campaign.totalSpend.toFixed(2),
        campaign.totalRevenue.toFixed(2),
        campaign.ROI.toFixed(2),
      ]);
      break;
    case "ProductRecommandation":
    case "CampaignPerformance":
    case "SkuPerformance":
      topLowROICampaigns = getTopLowROICampaignsRecommendation(groupedData);
      tableHeaders = ["Campaign Name", "Total Spend", "Total Revenue", "ROI"];
      tableRows = topLowROICampaigns.map((campaign) => [
        campaign.campaignName,
        campaign.totalSpend.toFixed(2),
        campaign.totalRevenue.toFixed(2),
        campaign.ROI.toFixed(2),
      ]);
      break;
    case "MarketingReport":
      topLowROICampaigns = getTopLowROICampaigns(groupedData);
      tableHeaders = [
        "Campaign Name",
        "Keyword",
        "Total Spend",
        "Total Revenue",
        "ROI",
      ];
      tableRows = topLowROICampaigns.map((campaign) => [
        campaign.campaignName,
        campaign.targetingValue,
        campaign.totalSpend.toFixed(2),
        campaign.totalRevenue.toFixed(2),
        campaign.ROI.toFixed(2),
      ]);
      break;
    default:
      console.warn(`Unknown option: ${option}`);
  }

  return (
    <TableContainer component={Paper} className="">
      <Table
        stickyHeader
        aria-label="sticky table"
        sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}
      >
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  bgcolor: "white",
                  left: index === 0 ? 0 : "auto",
                  maxWidth: index === 0 ? "200px" : "auto",
                }}
                style={{
                  minWidth: index === 0 ? 150 : "auto",
                  textAlign: index !== 0 ? "center" : "",
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  sx={{
                    color: "#667085",
                    fontSize: "1rem",
                    cursor: "pointer",
                    boxShadow:
                      cellIndex === 0
                        ? "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"
                        : "none",
                    position: cellIndex === 0 ? "sticky" : "static",
                    bgcolor: "white",
                    left: cellIndex === 0 ? 0 : "auto",
                    maxWidth: cellIndex === 0 ? "200px" : "auto",
                    textAlign: cellIndex === 0 ? "left" : "center",
                  }}
                  style={{ minWidth: cellIndex === 0 ? 150 : "auto" }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopTenWorstPerformKeyword;
