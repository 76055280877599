// {
//     "marketting_report_zepto_sku_performance.product_name": "Anveshan Wood Pressed Black Sesame Oil",
//     "marketting_report_zepto_sku_performance.ad_spend": "33.63",
//     "marketting_report_zepto_sku_performance.orders_sku": "0",
//     "marketting_report_zepto_sku_performance.detail_page_views": "2",
//     "marketting_report_zepto_sku_performance.cpc": "7.15",
//     "marketting_report_zepto_sku_performance.brand_name": "Anveshan",
//     "marketting_report_zepto_sku_performance.same_sku_orders": "0",
//     "marketting_report_zepto_sku_performance.sku_id": "60e72982-f3ff-470e-b98c-aff8b876bc74",
//     "marketting_report_zepto_sku_performance.tenant_id": "2",
//     "marketting_report_zepto_sku_performance.roi": "0",
//     "marketting_report_zepto_sku_performance.ad_impressions": "63",
//     "marketting_report_zepto_sku_performance.date_of_report": "09-08-2024",
//     "marketting_report_zepto_sku_performance.ad_revenue": "0",
//     "marketting_report_zepto_sku_performance.other_sku_orders": "0",
//     "marketting_report_zepto_sku_performance.category": "Oil",
//     "marketting_report_zepto_sku_performance.ad_clicks": "1",
//     "marketting_report_zepto_sku_performance.brand_id": "Oil>>Top Picks>>Newly Added>>Feast Essentials",
//     "marketting_report_zepto_sku_performance.current_date": "2024-08-12T00:00:00.000"
// }

export const groupedDataByCampaignName = (array) => {
  return array.reduce((acc, item) => {
    const {
      "marketting_report_zepto_sku_performance.product_name": campaignName,

      "marketting_report_zepto_sku_performance.ad_spend": spend,
      "marketting_report_zepto_sku_performance.ad_revenue": revenue,
    } = item;

    // Initialize campaign grouping if it does not exist
    if (!acc[campaignName]) {
      acc[campaignName] = {
        totalSpend: 0,
        totalRevenue: 0,
        ROI: 0,
      };
    }

    // Update totalSpend and totalRevenue
    acc[campaignName].totalSpend += parseFloat(spend) || 0;
    acc[campaignName].totalRevenue += parseFloat(revenue);

    // Update ROI
    const { totalSpend, totalRevenue } = acc[campaignName];
    acc[campaignName].ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    return acc;
  }, {});
};

export const groupedDataByDate = (array) => {
  // Step 1: First, calculate totalSpend, totalRevenue, and ROI for each item
  const calculatedArray = array.map((item) => {
    const {
      "marketting_report_zepto_sku_performance.ad_spend": spend,
      "marketting_report_zepto_sku_performance.ad_revenue": revenue,
      "marketting_report_zepto_sku_performance.ad_impressions": impression,
    } = item;

    // Calculate totalSpend and totalRevenue
    const totalSpend = parseFloat(spend) || 0;
    const totalRevenue = parseFloat(revenue) || 0;

    // Calculate ROI
    const ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    // Return the item with the calculated values
    return {
      ...item,
      totalSpend,
      totalRevenue,
      ROI,
      impression,
    };
  });

  // Step 2: Group the data by date without recalculating
  const groupedData = calculatedArray.reduce((acc, item) => {
    const { "marketting_report_zepto_sku_performance.date_of_report": date } = item;

    // Initialize date grouping if it does not exist
    if (!acc[date]) {
      acc[date] = {
        data: [],
      };
    }

    // Push the item into the respective date array
    acc[date].data.push(item);

    return acc;
  }, {});

  // Step 3: Sort the grouped data by date
  const sortedGroupedData = Object.keys(groupedData)
    .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
    .reduce((acc, date) => {
      acc[date] = groupedData[date]; // Rebuild the sorted object
      return acc;
    }, {});

  return sortedGroupedData;
};
