import React, { useEffect, useState } from "react";
import { getCubeJsApiInstance } from "../../utils/apiConfig";
import { targetsQuery } from "../../utils/queries/targetsQuery";
import CategoriesTarget from "../../components/setTargetComponents/CategoriesTarget";
import { useDateRange } from "../../hooks/CustomDateRange/CustomDateRangePickerProvider";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";

// Helper function to create unique categories from chart data
const createTargetByCategories = (chartData) => {
  // Extract category names from the data
  let categories = chartData.map((element) => {
    const category = element["static_combined_data.category"];
    return category;
  });

  // Filter out null categories and return unique categories
  return Array.from(new Set(categories.filter((category) => category !== null)));
};

const SetTargetScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [blinkitTarget, setBlinkitTarget] = useState("");
  const [zeptoTarget, setZeptoTarget] = useState("");
  const [instamartTarget, setInstamartTarget] = useState("");
  const [blinkitCatTargets, setBlinkitCatTargets] = useState({});
  const [zeptoCatTargets, setZeptoCatTargets] = useState({});
  const [instamartCatTargets, setInstamartCatTargets] = useState({});
  const { selectedDateRange } = useDateRange();
  const [categories, setCategories] = useState([]);

  // useEffect to load category data when the component mounts
  useEffect(() => {
    const cubejsApi = getCubeJsApiInstance();

    // Load data using Cube.js API and process it to get unique categories
    cubejsApi.load(targetsQuery).then((result) => {
      const categories = createTargetByCategories(result.tablePivot());
      setCategories(categories);
    });
  }, []);

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} />

      <Toolbar>
        <div className="bg-white w-full flex flex-col  mt-[1rem] rounded-xl small-shadow mb-10">
          <div className="border rounded-lg p-4 m-4">
            {platformName == "Blinkit" ? (
              <CategoriesTarget
                categories={categories}
                selectedDate={selectedDateRange}
                target={blinkitTarget}
                setTarget={setBlinkitTarget}
                catTargets={blinkitCatTargets}
                setCatTargets={setBlinkitCatTargets}
                platformName={platformName}
              />
            ) : platformName == "Zepto" ? (
              <CategoriesTarget
                categories={categories}
                selectedDate={selectedDateRange}
                target={zeptoTarget}
                setTarget={setZeptoTarget}
                catTargets={zeptoCatTargets}
                setCatTargets={setZeptoCatTargets}
                platformName={platformName}
              />
            ) : (
              <CategoriesTarget
                categories={categories}
                selectedDate={selectedDateRange}
                target={instamartTarget}
                setTarget={setInstamartTarget}
                catTargets={instamartCatTargets}
                setCatTargets={setInstamartCatTargets}
                platformName={platformName}
              />
            )}
          </div>
        </div>
      </Toolbar>
    </>
  );
};

export default SetTargetScreen;
