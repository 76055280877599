import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const heading = ["Category", "Total Inventory", "Daily Run Rate", "Inventory Days"];

const CityWiseDOHZeptoTable = () => {
  const { inventoryReportZepto } = useSelector((state) => state.sales);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const [selectedBrandChoice, setSelectedBrandChoice] = useState("Mumbai");

  const { loading, data } = inventoryReportZepto;

  function consolidateData(data) {
    const consolidated = {};

    data.forEach((entry) => {
      const city = entry["combined_inventory_data_zepto.brands_choice"];
      const skuCode = entry["combined_inventory_data_zepto.brand_internal_sku_code"];
      const dailyRunRate = parseFloat(entry["combined_sales_report_zepto.daily_run_rate"]);
      const totalFrontBackInv = parseInt(entry["combined_inventory_data_zepto.total_quantity"], 10);
      // Create a unique key based on city and skuCode
      const key = `${city}-${skuCode}`;

      if (!consolidated[key]) {
        consolidated[key] = {
          ...entry, // Spread all other fields from the first entry for the city and sku
          "combined_sales_report_zepto.daily_run_rate": 0, // Initialize for calculation

          totalFrontBackInv: 0,
          count: 0,
        };
      }

      consolidated[key]["combined_sales_report_zepto.daily_run_rate"] += dailyRunRate;

      consolidated[key].totalFrontBackInv += totalFrontBackInv;
      consolidated[key].count += 1;
    });

    return Object.values(consolidated)
      .map((entry) => ({
        ...entry, // Spread all other fields
        "combined_sales_report_zepto.daily_run_rate": entry["combined_sales_report_zepto.daily_run_rate"] / entry.count,
        totalInv: entry.totalFrontBackInv,
      }))
      .sort((a, b) =>
        a["combined_inventory_data_zepto.brand_internal_sku_code"].localeCompare(
          b["combined_inventory_data_zepto.brand_internal_sku_code"]
        )
      );
  }

  function aggregateBySkuCode(consolidatedData) {
    const aggregatedData = {};

    consolidatedData.forEach((entry) => {
      const skuCode = entry["combined_inventory_data_zepto.brand_internal_sku_code"];

      if (!aggregatedData[skuCode]) {
        aggregatedData[skuCode] = {
          ...entry, // Initialize with the first entry's data
          totalInv: 0,
          totalDailyRunRate: 0,
          count: 0,
        };
      }

      // Sum up the frontend and backend inventory, total inventory, and daily run rate
      aggregatedData[skuCode].totalInv += entry.totalInv;
      aggregatedData[skuCode].totalDailyRunRate += entry["combined_sales_report_zepto.daily_run_rate"];
      aggregatedData[skuCode].count += 1;
    });

    // After grouping, calculate the average daily run rate, inventory days, and finalize other fields
    return Object.values(aggregatedData).map((entry) => {
      const averageDailyRunRate = entry.totalDailyRunRate / entry.count;
      const totalInv = entry.totalInv;
      const inventoryDays = totalInv / averageDailyRunRate;

      return {
        ...entry,
        "combined_sales_report_zepto.daily_run_rate": averageDailyRunRate,
        totalInv, // Recalculate totalInv to ensure it's correct
        inventoryDays: inventoryDays || 0, // Prevent division by zero, set inventoryDays to 0 if averageDailyRunRate is 0
      };
    });
  }
  // Example usage:
  const consolidatedData = consolidateData(data); // Assuming this is the result from your consolidateData function
  const aggregatedData = aggregateBySkuCode(consolidatedData);

  const handleCategoryClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleSubCategoryClick = (subCategory) => {
    setExpandedSubCategories((prev) => ({
      ...prev,
      [subCategory]: !prev[subCategory],
    }));
  };

  const filteredData = !selectedBrandChoice
    ? aggregatedData
    : aggregatedData.filter((item) => item["combined_inventory_data_zepto.brands_choice"] === selectedBrandChoice);

  const groupedByCategory = filteredData.reduce((acc, item) => {
    const category = item["combined_inventory_data_zepto.category"];
    const subCategory = item["combined_inventory_data_zepto.sub_category"];
    const productName = item["combined_inventory_data_zepto.sku_name"];

    // Initialize category if not present
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if not present
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize productName if not present
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        totalInventory: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update product totals
    acc[category].subCategories[subCategory].productNames[productName].totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate +=
      item["combined_sales_report_zepto.daily_run_rate"];
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum +=
      item["combined_sales_report_zepto.daily_run_rate"]; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.totalInventory += item.totalInv;
    acc[category].totals.dailyRunRateSum += item["combined_sales_report_zepto.daily_run_rate"]; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategory).forEach((category) => {
    const categoryData = groupedByCategory[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate = categoryData.totals.dailyRunRateSum / categoryData.totals.productCount;
    categoryData.totals.inventoryDays = categoryData.totals.inventoryDaysSum / categoryData.totals.productCount;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount;
      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount;
    });
  });

  const handleBrandChoiceChange = (event) => {
    setSelectedBrandChoice(event.target.value);
  };

  const uniqueCities = [...new Set(data.map((item) => item["combined_inventory_data_zepto.brands_choice"]))];
  const filteredUniqueCities = uniqueCities.filter((choice) => choice !== null && choice !== undefined);

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div>
      <div className="py-4 flex justify-end items-center gap-4">
        <label className="font-semibold text-gray-700 mr-2">Choose city</label>

        <select className="px-4 py-2 border" value={selectedBrandChoice} onChange={handleBrandChoiceChange}>
          <option value="">All Cities</option>
          {filteredUniqueCities.map((city) => (
            <option value={city}>{city}</option>
          ))}
        </select>
      </div>
      <Paper
        className=" w-full rounded-lg shadow overflow-scroll"
        sx={{
          width: "100%",
          overflowX: "auto",
          mb: "2rem",
        }}
      >
        <Table
          className="table-auto  border-collapse min-w-full"
          stickyHeader
          aria-label="sticky table"
          sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}
        >
          <TableHead className="bg-white sticky z-10">
            <TableRow>
              {heading.map((head, index) => (
                <TableCell
                  key={`heading-${index}`}
                  className="text-left text-[#39393D] px-6 py-4  text-[0.7rem] font-bold font-nunito gap-[2.75rem] tracking-wider"
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: index == 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: index == 0 && "3",
                    minWidth: "300px",
                    maxWidth: "300px",
                    position: index == 0 && "sticky",
                    left: index == 0 && 0,
                    bgcolor: index == 0 && "white",
                  }}
                >
                  {head}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="bg-white">
            {Object.keys(groupedByCategory).length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-6">
                  <div className="flex justify-center items-center text-gray-500 text-lg">
                    No data available for this city .
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              Object.keys(groupedByCategory).map((category) => (
                <React.Fragment key={category}>
                  <TableRow
                    className={`cursor-pointer ${expandedCategories[category] ? "bg-[#E5E5E5]" : ""}`}
                    onClick={() => handleCategoryClick(category)}
                  >
                    <TableCell
                      className="flex justify-between items-center text-left px-3 gap-4 py-4 text-[0.75rem] font-medium font-body text-[#727278]"
                      sx={{
                        color: "#667085",
                        fontSize: "1rem",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        minWidth: "300px",
                        maxWidth: "300px",
                        position: "sticky",
                        left: 0,
                        bgcolor: "white",
                        display: "flex",
                      }}
                    >
                      {category}
                      {expandedCategories[category] ? <FaCaretUp /> : <FaCaretDown />}
                    </TableCell>
                    <TableCell
                      className="px-6 py-4 whitespace-nowrap text-left  text-[0.7rem] font-normal font-body text-[#727278]"
                      sx={{
                        color: "#667085",
                        fontSize: "1rem",
                      }}
                    >
                      {groupedByCategory[category].totals.totalInventory.toFixed(2)}
                    </TableCell>
                    <TableCell
                      className="px-6 py-4 whitespace-nowrap text-left  text-[0.7rem] font-normal font-body text-[#727278]"
                      sx={{
                        color: "#667085",
                        fontSize: "1rem",
                      }}
                    >
                      {groupedByCategory[category].totals.dailyRunRate.toFixed(2)}
                    </TableCell>
                    <TableCell
                      className="px-6 py-4 whitespace-nowrap text-left  text-[0.7rem] font-normal font-body text-[#727278]"
                      sx={{
                        color: "#667085",
                        fontSize: "1rem",
                      }}
                    >
                      {groupedByCategory[category].totals.inventoryDays.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "white",
                      }}
                    ></TableCell>
                  </TableRow>
                  {expandedCategories[category] &&
                    Object.keys(groupedByCategory[category].subCategories).map((subCategory) => (
                      <React.Fragment key={subCategory}>
                        <TableRow
                          className="cursor-pointer bg-[#F0F0F0] text-[#727278]"
                          onClick={() => handleSubCategoryClick(subCategory)}
                        >
                          <TableCell
                            className="flex justify-between pl-6 items-center  px-3 gap-4 py-4 text-[0.75rem] font-medium font-body text-[#727278]"
                            sx={{
                              color: "#667085",
                              fontSize: "1rem",
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              minWidth: "300px",
                              maxWidth: "300px",
                              position: "sticky",
                              left: 0,
                              bgcolor: "white",
                              display: "flex",
                              paddingLeft: "2rem",
                            }}
                          >
                            {subCategory}
                            {expandedSubCategories[category]?.[subCategory] ? <FaCaretUp /> : <FaCaretDown />}
                          </TableCell>

                          <TableCell
                            className="px-6 py-4 whitespace-nowrap text-left text-[0.75rem] font-normal font-body"
                            sx={{
                              color: "#667085",
                              fontSize: "1rem",
                            }}
                          >
                            {groupedByCategory[category].subCategories[subCategory].totals.totalInventory.toFixed(2)}
                          </TableCell>
                          <TableCell
                            className="px-6 py-4 whitespace-nowrap text-left text-[0.75rem] font-normal font-body"
                            sx={{
                              color: "#667085",
                              fontSize: "1rem",
                            }}
                          >
                            {groupedByCategory[category].subCategories[subCategory].totals.dailyRunRate.toFixed(2)}
                          </TableCell>
                          <TableCell
                            className="px-6 py-4 whitespace-nowrap text-left text-[0.75rem] font-normal font-body"
                            sx={{
                              color: "#667085",
                              fontSize: "1rem",
                            }}
                          >
                            {groupedByCategory[category].subCategories[subCategory].totals.inventoryDays.toFixed(2)}
                          </TableCell>
                          <TableCell
                            sx={{
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              zIndex: "3",
                              position: "sticky",
                              right: 0,
                              bgcolor: "white",
                            }}
                          ></TableCell>
                        </TableRow>
                        {expandedSubCategories[subCategory] &&
                          Object.keys(groupedByCategory[category].subCategories[subCategory].productNames).map(
                            (productName, index) => (
                              <TableRow key={index} className="bg-white cursor-pointer">
                                <TableCell
                                  className="px-6 py-4 pl-8 text-[0.75rem] font-medium font-body"
                                  sx={{
                                    color: "#667085",
                                    fontSize: "1rem",
                                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    minWidth: "300px",
                                    maxWidth: "300px",
                                    position: "sticky",
                                    left: 0,
                                    bgcolor: "white",
                                    display: "flex",
                                    paddingLeft: "3rem",
                                  }}
                                >
                                  {productName}
                                </TableCell>
                                <TableCell className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body">
                                  {
                                    groupedByCategory[category].subCategories[subCategory].productNames[productName]
                                      .wh_soh
                                  }
                                </TableCell>
                                <TableCell className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body">
                                  {groupedByCategory[category].subCategories[subCategory].productNames[
                                    productName
                                  ].dailyRunRate.toFixed(2)}
                                </TableCell>
                                <TableCell className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body">
                                  {groupedByCategory[category].subCategories[subCategory].productNames[
                                    productName
                                  ].inventoryDays.toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color: "black",
                                    fontSize: "1rem",
                                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    zIndex: "3",
                                    position: "sticky",
                                    bgcolor: "white",
                                    right: 0,
                                  }}
                                ></TableCell>
                              </TableRow>
                            )
                          )}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default CityWiseDOHZeptoTable;
