import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { FaAngleDown } from "react-icons/fa6";
import { useSelector } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Function to group data by SKU and keyword while calculating parentBrand and competitor price details
const groupDataByKeywords = (data, parsedUserData) => {
  let result = {};
  data.forEach((item) => {
    const SKU = item["postgres_third_party_visibility.sku_name"];
    const keyword = item["postgres_third_party_visibility.searchkeyword"];
    const isParentBrand = item["postgres_third_party_visibility.brand"] === parsedUserData?.organizationDetail?.name;
    const price = parseFloat(item["postgres_third_party_visibility.price"]);
    const discountPrice = parseFloat(item["postgres_third_party_visibility.discountprice"]);
    const discountPercent = ((price - discountPrice) / price) * 100;

    // Initializing SKU and keyword in the result object if not already present
    if (!result[SKU]) {
      result[SKU] = {};
    }

    if (!result[SKU][keyword]) {
      result[SKU][keyword] = {
        parentBrand: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
        other: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
      };
    }

    // Accumulating parentBrand brand details or competitor details based on the item data
    if (isParentBrand) {
      result[SKU][keyword].parentBrand.count += 1;
      result[SKU][keyword].parentBrand.price += price;
      result[SKU][keyword].parentBrand.discountPrice += discountPrice;
      result[SKU][keyword].parentBrand.discountPercent += discountPercent;
    } else {
      result[SKU][keyword].other.count += 1;
      result[SKU][keyword].other.price += price;
      result[SKU][keyword].other.discountPrice += discountPrice;
      result[SKU][keyword].other.discountPercent += discountPercent;
    }
  });
  return result;
};

const PricingDetailBarChart = ({ selectedAreaName }) => {
  const { thirdPartyVisibility } = useSelector((state) => state.thirdParty);

  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);
  // Filtering data based on the selected areaname
  const filteredDataByPincode = thirdPartyVisibility.data?.filter(
    (item) => item["postgres_third_party_visibility.area_name_lm"] == selectedAreaName
  );


  // Grouping data by SKU and keywords
  const data = groupDataByKeywords(filteredDataByPincode, parsedUserData);
  const allSKUs = Object.keys(data);
  const [selectedSKUs, setSelectedSKUs] = useState([]);
  const [all, setAll] = useState(true);
  const [open, setOpen] = useState(false);

  // Effect to update selected SKUs when data changes
  useEffect(() => {
    const func = async () => {
      const newData = groupDataByKeywords(filteredDataByPincode);
      const allSKUs = Object.keys(newData);
      setSelectedSKUs(allSKUs);
    };
    func();
  }, [thirdPartyVisibility.data, allSKUs.length]);

  // Effect to select or deselect all SKUs based on the 'all' checkbox state
  useEffect(() => {
    if (all) {
      setSelectedSKUs(allSKUs);
    } else {
      setSelectedSKUs([]);
    }
  }, [all]);

  // Handler for checkbox selection/deselection for individual SKUs or all SKUs
  const handleCheckboxChange = (event, sku) => {
    if (sku === "all") {
      if (event.target.checked) {
        setSelectedSKUs(allSKUs);
      } else {
        setSelectedSKUs([]);
      }
    } else {
      // Toggle selection of individual SKUs
      if (selectedSKUs.includes(sku)) {
        const updated = selectedSKUs.filter((innerItem) => innerItem !== sku);
        setSelectedSKUs(updated);
      } else {
        setSelectedSKUs([...selectedSKUs, sku]);
      }
    }
  };

  // Filter data based on the selected SKUs
  const filteredData = selectedSKUs.length
    ? Object.keys(data).reduce((acc, sku) => {
        if (selectedSKUs.includes(sku)) {
          acc[sku] = data[sku];
        }
        return acc;
      }, {})
    : data;

  // Preparing chart data for rendering the Bar chart
  const chartData = {
    labels: Object.keys(filteredData),
    datasets: [
      {
        label: "Own Price",
        data: Object.keys(filteredData).map((sku) => {
          const keywords = Object.keys(filteredData[sku]);
          return (
            filteredData[sku][keywords[0]]?.parentBrand?.price / filteredData[sku][keywords[0]]?.parentBrand?.count || 0
          );
        }),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        barThickness: 24,
      },
      {
        label: "Competitor's Average Price",
        data: Object.keys(filteredData).map((sku) => {
          const keywords = Object.keys(filteredData[sku]);
          return filteredData[sku][keywords[0]]?.other?.price / filteredData[sku][keywords[0]]?.other?.count || 0;
        }),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        barThickness: 24,
      },
    ],
  };

  return (
    <div className="p-4">
      <div className="mb-20">
        <div className="relative float-right">
          <div
            className="border w-[325px] flex justify-between px-2 py-3 items-center cursor-pointer font-bold"
            onClick={() => setOpen(!open)}
          >
            {selectedSKUs.length === allSKUs.length ? "All Selected" : "Select SKU's"}
            <FaAngleDown />
          </div>
          {open && (
            <div className="border max-h-[500px] h-fit px-4 mb-4 w-max shadow-md p-2 absolute bg-white overflow-y-scroll">
              <div className="p-2 flex gap-x-2 items-center">
                <label htmlFor="all">
                  <input
                    type="checkbox"
                    id="all"
                    value={"all"}
                    checked={selectedSKUs.length == allSKUs.length}
                    onChange={() => setAll(!all)}
                  />
                  All SKU's
                </label>
              </div>
              {allSKUs.map((sku) => (
                <div key={sku} className="w-fit p-2 flex gap-x-2 items-center">
                  <label htmlFor={sku}>
                    <input
                      type="checkbox"
                      id={sku}
                      value={sku}
                      checked={selectedSKUs.includes(sku)}
                      onChange={(event) => handleCheckboxChange(event, sku)}
                    />
                    {sku}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div style={{ maxWidth: "100%", height: "600px", overflowX: "scroll" }}>
        <div
          style={{
            minWidth: "100%",
            width: `${Object.keys(filteredData).length * 100}px`,
            height: "100%",
          }}
        >
          {selectedSKUs.length ? (
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: "top",

                    labels: {
                      usePointStyle: true,
                      pointStyle: "circle",
                      font: {
                        size: 10,
                      },
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => `${context.dataset.label}: ${context.raw.toFixed(2)}`,
                    },
                  },
                },
                scales: {
                  x: {
                    grid: { display: false },
                    title: {
                      display: true,
                      text: "SKU",
                    },
                    categoryPercentage: 2,
                    barPercentage: 0.9,
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Price",
                    },
                  },
                },
              }}
            />
          ) : (
            <div className="w-[calc(100vw-280px)] h-full flex justify-center items-center">No SKU Selected</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingDetailBarChart;
