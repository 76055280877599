import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllMarketingReportBlinkitProductListingSuccess,
  getAllMarketingReportBlinkitProductListingRequest,
  getAllMarketingReportBlinkitProductListingFailure,
  getAllMarketingReportBlinkitProductRecommandationSuccess,
  getAllMarketingReportBlinkitProductRecommandationRequest,
  getAllMarketingReportBlinkitProductRecommandationFailure,
  getAllMarketingReportInstamartSuccess,
  getAllMarketingReportInstamartRequest,
  getAllMarketingReportInstamartFailure,
  getAllMarketingReportZeptoSkuPerformanceSuccess,
  getAllMarketingReportZeptoSkuPerformanceRequest,
  getAllMarketingReportZeptoSkuPerformanceFailure,
  getAllMarketingReportZeptoSkuAnalyticsSuccess,
  getAllMarketingReportZeptoSkuAnalyticsRequest,
  getAllMarketingReportZeptoSkuAnalyticsFailure,
} from "../../redux/actions/marketingActions";
import {
  fetchPerformanceMarketingAdsCampaignPerformanceDataZepto,
  fetchPerformanceMarketingAdsMarketingReportDataInstamart,
  fetchPerformanceMarketingAdsProductListingDataBlinkit,
  fetchPerformanceMarketingAdsProductRecommendationDataBlinkit,
  fetchPerformanceMarketingAdsSkuPerformanceDataZepto,
} from "../../services/adsServices/performanceMarketingAdsServices";
import ProductListingCampaignSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/ProductListingCampaignSectionsContainer";
import ProcuctRecommandationCampaignSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/ProcuctRecommandationCampaignSectionsContainer";
import MarketingReportSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/MarketingReportSectionsContainer";
import SkuPerformanceZeptoSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/SkuPerformanceZeptoSectionsContainer";
import CampaignPerformanceSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/CampaignPerformanceSectionsContainer";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";

const optionsBlinkit = ["Product Listing", "Product Recommendation"];
const optionsInstamart = ["Marketing Report"];
const optionsZepto = ["SKU Performance", "Campaign Performance"];

const PerformanceMarketingAdsScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(0);
  const { selectedDate } = useDate();

  const options =
    platformName === "Blinkit"
      ? optionsBlinkit
      : platformName === "Instamart"
      ? optionsInstamart
      : platformName === "Zepto"
      ? optionsZepto
      : [];

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const handleBlinkitQueries = async () => {
    // Product Listing
    try {
      dispatch(getAllMarketingReportBlinkitProductListingRequest());
      const productListingResult = await fetchPerformanceMarketingAdsProductListingDataBlinkit(selectedDate);
      dispatch(getAllMarketingReportBlinkitProductListingSuccess(productListingResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitProductListingFailure(error));
    }

    // Product Recommendation
    try {
      dispatch(getAllMarketingReportBlinkitProductRecommandationRequest());
      const productRecommendationResult = await fetchPerformanceMarketingAdsProductRecommendationDataBlinkit(
        selectedDate
      );
      dispatch(getAllMarketingReportBlinkitProductRecommandationSuccess(productRecommendationResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitProductRecommandationFailure(error));
    }
  };

  const handleInstamartQueries = async () => {
    try {
      dispatch(getAllMarketingReportInstamartRequest());
      const result = await fetchPerformanceMarketingAdsMarketingReportDataInstamart(selectedDate);
      dispatch(getAllMarketingReportInstamartSuccess(result));
    } catch (error) {
      dispatch(getAllMarketingReportInstamartFailure(error));
    }
  };

  const handleZeptoQueries = async () => {
    // SKU Performance
    try {
      dispatch(getAllMarketingReportZeptoSkuPerformanceRequest());
      const skuPerformanceResult = await fetchPerformanceMarketingAdsSkuPerformanceDataZepto(selectedDate);
      dispatch(getAllMarketingReportZeptoSkuPerformanceSuccess(skuPerformanceResult));
    } catch (error) {
      dispatch(getAllMarketingReportZeptoSkuPerformanceFailure(error));
    }

    // Campaign Performance
    try {
      dispatch(getAllMarketingReportZeptoSkuAnalyticsRequest());
      const campaignPerformanceResult = await fetchPerformanceMarketingAdsCampaignPerformanceDataZepto(selectedDate);
      dispatch(getAllMarketingReportZeptoSkuAnalyticsSuccess(campaignPerformanceResult));
    } catch (error) {
      dispatch(getAllMarketingReportZeptoSkuAnalyticsFailure(error));
    }
  };

  useEffect(() => {
    if (platformName === "Blinkit") {
      handleBlinkitQueries();
    } else if (platformName === "Instamart") {
      handleInstamartQueries();
    } else {
      handleZeptoQueries();
    }
  }, [platformName, selectedDate, selectedOption]);

  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  const calculateDateRange = (selectedDate) => {
    const endDate = formatDateWithoutTimezone(selectedDate);
    const startDate = formatDateWithoutTimezone(dayjs(selectedDate).subtract(30, "day"));
    return [startDate, endDate];
  };

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={calculateDateRange(selectedDate)} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 && (
          <div className="flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max pb-10">
            {platformName === "Blinkit" && <ProductListingCampaignSectionsContainer />}
            {platformName === "Instamart" && <MarketingReportSectionsContainer />}
            {platformName === "Zepto" && <SkuPerformanceZeptoSectionsContainer />}
          </div>
        )}
        {selectedOption === 1 && (
          <div className="rounded-lg w-full flex flex-col items-center mt-[1rem] pb-10">
            {platformName === "Blinkit" && <ProcuctRecommandationCampaignSectionsContainer />}
            {platformName === "Zepto" && <CampaignPerformanceSectionsContainer />}
          </div>
        )}
      </Toolbar>
    </>
  );
};

export default PerformanceMarketingAdsScreen;
