import { RESET_SIDEBAR, SIDEBAR_OPEN } from "../constants/SidebarConstants";

export const setSidebarOpen = (value) => {
  return {
    type: SIDEBAR_OPEN,
    payload: value,
  };
};

export const resetSidebar = () => {
  return {
    type: RESET_SIDEBAR,
  };
};
