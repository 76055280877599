import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MenuItem, Select, Checkbox, ListItemText, FormControl } from "@mui/material";
import CitySkuSplitLineChart from "./CitySkuSplitLineChart";
import { MdFilterAltOff } from "react-icons/md";

// Function to transform sales data based on city, category, and platform
const transformData = (data, selectedCities, selectedCategory, platformName) => {
  let filteredData = {};
  data?.forEach((element) => {
    // Extract city, date, category, MRP, and quantity sold based on the platform
    const city =
      platformName === "Blinkit"
        ? element["combined_sales_report_blinkit.brands_choice"]
        : platformName === "Instamart"
        ? element["combined_sales_report_instamart.brands_choice"]
        : element["combined_sales_report_zepto.brands_choice"];
    const date =
      platformName === "Blinkit"
        ? element["combined_sales_report_blinkit.date"]
        : platformName === "Instamart"
        ? element["combined_sales_report_instamart.date"]
        : element["combined_sales_report_zepto.date"];
    const category =
      platformName === "Blinkit"
        ? element["combined_sales_report_blinkit.category"]
        : platformName === "Instamart"
        ? element["combined_sales_report_instamart.category"]
        : element["combined_sales_report_zepto.category"];
    const mrp =
      platformName === "Blinkit"
        ? Number(element["combined_sales_report_blinkit.gmv"])
        : platformName === "Instamart"
        ? Number(element["combined_sales_report_instamart.gmv"])
        : Number(element["combined_sales_report_zepto.gmv"]);
    const qtySold =
      platformName === "Blinkit"
        ? Number(element["combined_sales_report_blinkit.qty_sold"])
        : platformName === "Instamart"
        ? Number(element["combined_sales_report_instamart.qty"])
        : Number(element["combined_sales_report_zepto.qty"]);

    // Filter data by selected cities and categories
    if (mrp !== 0 && (!selectedCities.length || selectedCities.includes(city))) {
      if (!filteredData[city]) {
        filteredData[city] = {};
      }
      if (!filteredData[city][date]) {
        filteredData[city][date] = {};
      }
      if (!filteredData[city][date][category]) {
        filteredData[city][date][category] = { totalSales: 0 };
      }
      if (!selectedCategory.length || selectedCategory.includes(category)) {
        filteredData[city][date][category].totalSales += mrp;
      }
    }
  });

  return filteredData;
};

const CityWiseSkuSplitCharts = ({ platformName }) => {
  // Fetch sales data from Redux state
  const { totalSales } = useSelector((state) => state.sales);
  const [allCities, setAllCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [newChartData, setNewChartData] = useState({});

  // Handle city filter changes
  const handleCityChange = (event) => {
    setSelectedCities(event.target.value);
  };

  // Handle category filter changes
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Reset filters
  const handleReset = () => {
    setSelectedCities([]);
    setSelectedCategory([]);
  };

  // Fetch and set cities, categories, and chart data when filters or sales data change
  useEffect(() => {
    const getCitiesAndCategories = (data, platform) => {
      let cities = [];
      let categories = [];

      // Extract cities and categories based on the platform
      if (platform === "Blinkit") {
        cities = data.map((item) => item["combined_sales_report_blinkit.brands_choice"]);
        categories = data.map((item) => item["combined_sales_report_blinkit.category"]);
      } else if (platform === "Instamart") {
        cities = data.map((item) => item["combined_sales_report_instamart.brands_choice"]);
        categories = data.map((item) => item["combined_sales_report_instamart.category"]);
      } else if (platform === "Zepto") {
        cities = data.map((item) => item["combined_sales_report_zepto.brands_choice"]);
        categories = data.map((item) => item["combined_sales_report_zepto.category"]);
      }

      return {
        cities: Array.from(new Set(cities)).filter(Boolean).sort(),
        categories: Array.from(new Set(categories)).filter(Boolean).sort(),
      };
    };

    // Update cities, categories, and chart data
    const { cities, categories } = getCitiesAndCategories(totalSales.data, platformName);
    setAllCities(cities);
    setAllCategories(categories);
    setNewChartData(transformData(totalSales.data, selectedCities, selectedCategory, platformName));
  }, [totalSales.data, platformName, selectedCities, selectedCategory]);

  return (
    <div className="flex flex-col">
      {/* Filter selection UI */}
      <div className="flex flex-col">
        <div className="flex items-center">
          {/* Filter dropdowns */}
          <div className="p-4 flex justify-end gap-2 flex-wrap">
            <div className="flex flex-row gap-1">
              {/* City filter dropdown */}
              <FormControl>
                <Select
                  multiple
                  displayEmpty
                  value={selectedCities}
                  onChange={handleCityChange}
                  renderValue={(selected) => (selected.length === 0 ? "Select City" : selected.join(", "))}
                  MenuProps={{ PaperProps: { style: { maxHeight: 224, width: 250 } } }}
                  sx={{ width: 200 }}
                >
                  <MenuItem disabled value="">
                    <em>Select City</em>
                  </MenuItem>
                  {allCities.map((city) => (
                    <MenuItem key={city} value={city}>
                      <Checkbox checked={selectedCities.indexOf(city) > -1} />
                      <ListItemText primary={city} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Category filter dropdown */}
              <FormControl>
                <Select
                  multiple
                  displayEmpty
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  renderValue={(selected) => (selected.length === 0 ? "Select Category" : selected.join(", "))}
                  MenuProps={{ PaperProps: { style: { maxHeight: 224, width: 250 } } }}
                  sx={{ width: 200 }}
                >
                  <MenuItem disabled value="">
                    <em>Select Category</em>
                  </MenuItem>
                  {allCategories.map((category) => (
                    <MenuItem key={category} value={category}>
                      <Checkbox checked={selectedCategory.indexOf(category) > -1} />
                      <ListItemText primary={category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Reset filters button */}
          <div
            onClick={handleReset}
            className="border w-28 mt-4 border-[#EAEDF3] px-4 py-2 small-shadow flex items-center rounded-md cursor-pointer"
          >
            <MdFilterAltOff />
            <span>Reset</span>
          </div>
        </div>

        {/* Display selected filters */}
        <div className="p-4 flex flex-col gap-1">
          {selectedCities.length !== 0 && (
            <div className="flex flex-wrap items-center gap-2">
              <h6 className="text-lg font-semibold">Selected Cities:</h6>
              <div className="flex flex-wrap gap-2">
                {selectedCities.map((city) => (
                  <span key={city} className="bg-gray-200 text-sm px-2 py-1 rounded">
                    {city}
                  </span>
                ))}
              </div>
            </div>
          )}

          {selectedCategory.length !== 0 && (
            <div className="flex flex-wrap items-center gap-2 mt-2">
              <h6 className="text-lg font-semibold">Selected Categories:</h6>
              <div className="flex flex-wrap gap-2">
                {selectedCategory.map((category) => (
                  <span key={category} className="bg-gray-200 text-sm px-2 py-1 rounded">
                    {category}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Render charts for each selected or all cities */}
      <div className="flex w-full flex-wrap p-4 justify-between max-h-screen overflow-scroll">
        {(selectedCities.length ? selectedCities : allCities).map((item) => (
          <CitySkuSplitLineChart
            key={item}
            allCategories={allCategories}
            cityChartData={newChartData[item] || {}}
            city={item}
          />
        ))}
      </div>
    </div>
  );
};

export default CityWiseSkuSplitCharts;

// Use Case - CityWiseSkuSplitCharts

// Displays platform-specific sales data (Blinkit, Zepto, Instamart) filtered by city and category.
// Users can select multiple cities and categories via dropdown menus for targeted filtering.
// A reset button allows clearing selected filters with a single click.
// Sales data is dynamically transformed based on the selected cities, categories, and platform.
// Line charts visualize aggregated sales data for each selected city using the CitySkuSplitLineChart component.
