import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"; // Redux hook to dispatch actions
import {
  inventoryReportBlinkitSuccess,
  inventoryReportBlinkitRequest,
  inventoryReportBlinkitFailure,
  inventoryReportInstamartSuccess,
  inventoryReportInstamartRequest,
  inventoryReportInstamartFailure,
  inventoryReportZeptoSuccess,
  inventoryReportZeptoRequest,
  inventoryReportZeptoFailure,
} from "../../redux/actions/salesActions"; // Redux actions for managing inventory reports
import {
  fetchInventoryReportBlinkitData,
  fetchInventoryReportInstamartData,
  fetchInventoryReportZeptoData,
} from "../../services/inventoryServices/inventoryReportServices"; // Fetching functions for different platforms
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider"; // Custom hook to get the selected date
import InventoryDaysBlinkitTable from "../../components/inventory/InventoryReportComponents/InventoryDaysBlinkitTable";
import InventoryDaysZeptoTable from "../../components/inventory/InventoryReportComponents/InventoryDaysZeptoTable";
import InventoryDaysInstamartTable from "../../components/inventory/InventoryReportComponents/InventoryDaysInstamartTable";
import CityWiseDOHBlinkitTable from "../../components/inventory/InventoryReportComponents/CityWiseDOHBlinkitTable";
import CityWiseDOHZeptoTable from "../../components/inventory/InventoryReportComponents/CityWiseDOHZeptoTable";
import CityWiseDOHInstamartTable from "../../components/inventory/InventoryReportComponents/CityWiseDOHInstamartTable";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";

// List of report options available
const options = ["Inventory Days", "Days on Hand (City Wise)"];

const InventoryReportScreen = () => {
  const dispatch = useDispatch(); // Hook to dispatch Redux actions
  const [selectedOption, setSelectedOption] = useState(0); // State to manage the selected report option
  const [platformName, setPlatformName] = useState("Blinkit"); // State to manage the selected platform (Blinkit, Zepto, Instamart)
  // Hook to get the selected date from the Date Picker component
  const { selectedDate } = useDate();

  // Reset selected option when platform changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const handleBlinkitQueries = async () => {
    try {
      dispatch(inventoryReportBlinkitRequest());
      const result = await fetchInventoryReportBlinkitData(selectedDate);
      dispatch(inventoryReportBlinkitSuccess(result));
    } catch (error) {
      dispatch(inventoryReportBlinkitFailure(error));
    }
  };

  const handleZeptoQueries = async () => {
    try {
      dispatch(inventoryReportZeptoRequest());
      const result = await fetchInventoryReportZeptoData(selectedDate);
      dispatch(inventoryReportZeptoSuccess(result));
    } catch (error) {
      dispatch(inventoryReportZeptoFailure(error));
    }
  };

  const handleInstamartQueries = async () => {
    try {
      dispatch(inventoryReportInstamartRequest());
      const result = await fetchInventoryReportInstamartData(selectedDate);
      dispatch(inventoryReportInstamartSuccess(result));
    } catch (error) {
      dispatch(inventoryReportInstamartFailure(error));
    }
  };

  useEffect(() => {
    if (platformName === "Blinkit") {
      handleBlinkitQueries();
    } else if (platformName === "Instamart") {
      handleInstamartQueries();
    } else {
      handleZeptoQueries();
    }
  }, [platformName, selectedDate, selectedOption]);

  return (
    <>
      {/* Heading bar with platform selection and date picker */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />
      {/* Toolbar with option switcher */}
      <Toolbar>
        {/* Component to switch between report options */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {/* Render report based on selected option and platform */}
        {selectedOption === 0 && (
          <div className="w-full mt-6">
            {platformName === "Blinkit" && <InventoryDaysBlinkitTable />}
            {platformName === "Zepto" && <InventoryDaysZeptoTable />}
            {platformName === "Instamart" && <InventoryDaysInstamartTable />}
          </div>
        )}
        {selectedOption === 1 && (
          <div className="w-full mt-6">
            {platformName === "Blinkit" && <CityWiseDOHBlinkitTable />}
            {platformName === "Zepto" && <CityWiseDOHZeptoTable />}
            {platformName === "Instamart" && <CityWiseDOHInstamartTable />}
          </div>
        )}
      </Toolbar>
    </>
  );
};

export default InventoryReportScreen;
