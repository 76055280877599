import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllTargetRequest,
  getAllTargetSuccess,
  getAllTargetFailure,
  getAllTargetDataRequest,
  getAllTargetDataSuccess,
  getAllTargetDataFailure,
} from "../../redux/actions/targetTrackerActions";
import DailyTarget from "../../components/revenue/TargetTrackerComponents/DailyTarget";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import {
  fetchAllTargetSetByUser,
  fetchTargetTrackerDataBlinkit,
  fetchTargetTrackerDataInstamart,
  fetchTargetTrackerDataZepto,
} from "../../services/revenueServices/targetTrackerServices";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";

// Options array to show inside ComponentSwitch component
const options = ["Daily Target"];

// Object mapping platform names to their respective data fetch functions
const dataFetchFunctions = {
  Blinkit: fetchTargetTrackerDataBlinkit,
  Zepto: fetchTargetTrackerDataZepto,
  Instamart: fetchTargetTrackerDataInstamart,
};

const TargetTrackerScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const { selectedDate } = useDate();
  const dispatch = useDispatch();

  // Reset selected option to the first option inside ComponentSwitch whenever the platformName changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  // Fetch targets defined by the user whenever platformName, selectedDateRange, or selectedOption changes
  useEffect(() => {
    const fetchTargets = async () => {
      dispatch(getAllTargetRequest());
      try {
        const filteredTargetsByPlatform = await fetchAllTargetSetByUser(platformName); // Fetch targets based on platform
        dispatch(getAllTargetSuccess(filteredTargetsByPlatform)); // Dispatch success action with fetched targets
      } catch (error) {
        dispatch(getAllTargetFailure(error.message));
      }
    };

    fetchTargets();
  }, [platformName, selectedOption, selectedDate]);

  // Fetch sales data whenever platformName, selectedDateRange, or selectedOption changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getAllTargetDataRequest());
        const result = await dataFetchFunctions[platformName](selectedDate); // Fetch data for the selected date and platform
        dispatch(getAllTargetDataSuccess(result));
      } catch (error) {
        getAllTargetDataFailure(error.message);
      }
    };
    fetchData();
  }, [platformName, selectedOption, selectedDate]);

  return (
    <>
      {/* HeadingBar for platform selection and date picker */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />
      <Toolbar>
        <div className="w-full mb-10">
          {/* ComponentSwitch to toggle between different tabs */}
          <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
          <div className="max-w-full h-fit mt-[1rem] rounded-2xl small-shadow">
            {/* Render the DailyTarget component if selectedOption is 0  */}
            {selectedOption === 0 && <DailyTarget platformName={platformName} />}
          </div>
        </div>
      </Toolbar>
    </>
  );
};

export default TargetTrackerScreen;
