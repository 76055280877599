import React from "react";
import { TbAlarmAverage } from "react-icons/tb";
import { useSelector } from "react-redux";

const calculateAverageAppointmentTurnAroundTime = (data) => {
  let diff = 0;
  let count = 0;
  for (let i = 0; i < data?.length; i++) {
    if (data[i]["fulfillment_report_blinkit.po_state"] === "Fulfilled") {
      const date1 = new Date(data[i]["fulfillment_report_blinkit.appointment_date"]);
      const date2 = new Date(data[i]["fulfillment_report_blinkit.order_date"]);

      const differenceInTime = date1.getTime() - date2.getTime(); // Difference in milliseconds
      const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert to days
      diff += differenceInDays;
      count++;
    }
  }
  return diff / count;
};

const AverageAppointmentTurnAroundTime = () => {
  const { blinkitFulfillment } = useSelector((state) => state.fulfillment);
  const title = "Average Appointment Turn Around Time";
  const averageTime = calculateAverageAppointmentTurnAroundTime(blinkitFulfillment.data);
  return (
    <div className="p-4 border bg-white rounded-lg shadow-md flex items-center space-x-4 w-[25%]">
      <div className="text-3xl">
        <TbAlarmAverage />
      </div>
      <div>
        <h2 className="text-xl font-semibold">{title}</h2>
        <p className="text-lg">{averageTime.toFixed(2)} Days</p>
      </div>
    </div>
  );
};

export default AverageAppointmentTurnAroundTime;
