import { CircularProgress } from "@mui/material";
import React from "react";
import { useAlert } from "../common/Alert/AlertProvider ";
import { BASE_URL } from "../../utils/assets";
import dayjs from "dayjs";
import { useDateRange } from "../../hooks/CustomDateRange/CustomDateRangePickerProvider";
import ShowSelectedDate from "../common/ShowSelectedDate/ShowSelectedDate";
import CustomDateRangePicker from "../DatePickers/CustomDateRangePicker/CustomDateRangePicker";

const CategoriesTarget = ({ categories, selectedDate, target, setTarget, catTargets, setCatTargets, platformName }) => {
  const { showAlert } = useAlert();
  const { selectedDateRange } = useDateRange();

  // Helper function to format date without timezone information
  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  // Format the start and end dates using your formatDateWithoutTimezone function
  const formattedStartDate = formatDateWithoutTimezone(selectedDate[0]);
  const formattedEndDate = formatDateWithoutTimezone(selectedDate[1]);

  // Show a loading spinner if categories are not defined or empty
  if (categories[0] == "undefined" || categories.length == 0) {
    return (
      <div className="w-full h-80 flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  // Handle changes to the overall target input field
  const handleTargetChange = (e) => {
    const newTarget = e.target.value;
    const obj = {};
    setTarget(newTarget);

    // Distribute the total target equally among categories
    categories.forEach((element) => {
      if (!obj[element]) {
        obj[element] = newTarget / categories.length;
      }
    });

    // Set the distributed target for each category
    setCatTargets({ ...obj });
  };

  // Handle changes to individual category target input fields
  const handleCatTargetChange = (category, value) => {
    const newCatTargets = { ...catTargets, [category]: Number(value) };
    setCatTargets(newCatTargets);

    // Recalculate the total target based on individual category values
    const newTotalTarget = Object.keys(newCatTargets).reduce((acc, val) => acc + Number(newCatTargets[val]), 0);
    setTarget(newTotalTarget);
  };

  // Handle form submission
  const handleSubmit = () => {
    const token = localStorage.getItem("token");

    // Make a POST request to add targets
    fetch(BASE_URL + "/addTargets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: formattedStartDate, // Pass formatted start date
        endDate: formattedEndDate, // Pass formatted end date
        subCategories: catTargets,
        platform: platformName,
        totalTarget: target,
        token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showAlert("success", "Targets Saved successfully!!!!");
        } else {
          showAlert("warning", data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="flex justify-center gap-10 items-center">
        <CustomDateRangePicker />
        <ShowSelectedDate date={selectedDateRange} />
      </div>

      {target ? (
        <div className="font-bold text-xl py-2 flex justify-between items-center">
          <div>Your Target for Today: {target.toLocaleString()}</div>
          <div className="bg-black text-white py-2 px-6 rounded cursor-pointer" onClick={handleSubmit}>
            Submit
          </div>
        </div>
      ) : (
        <div className="font-bold text-xl p-2 ">
          <div>You have not set any Target yet</div>
        </div>
      )}
      <table>
        <tbody>
          <tr>
            <td className="font-bold text-xl">Enter Your Daily Target</td>
            <td className="pl-2 py-2">
              <input
                value={target}
                type="number"
                id="full-target"
                placeholder="50,000"
                className="border p-2 w-80 outline-0"
                onChange={(e) => handleTargetChange(e)}
              />
            </td>
          </tr>
          {categories.length &&
            categories.map((item) => (
              <tr key={item}>
                <td>{item}</td>
                <td className="pl-2 py-2">
                  <input
                    type="number"
                    value={catTargets[item] || ""}
                    name=""
                    id=""
                    className="border p-2 w-80 outline-0"
                    onChange={(e) => {
                      handleCatTargetChange(item, e.target.value);
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default CategoriesTarget;
