export const categoryProfitabilityGroupingBlinkit = (tableData) => {
  return tableData?.map((row) => ({
    category: row["combined_sales_report_blinkit.category"],
    mrp: parseFloat(row["combined_sales_report_blinkit.total_gmv"]),
    gst: parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_blinkit.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_blinkit.total_cogs"]),
  }));
};

export const categoryProfitabilityGroupingZepto = (tableData) => {
  return tableData?.map((row) => ({
    category: row["combined_sales_report_zepto.category"],
    mrp: parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]),
    gst: parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_zepto.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_zepto.total_cogs"]),
  }));
};

export const categoryProfitabilityGroupingInstamart = (tableData) => {
  return tableData?.map((row) => ({
    category: row["combined_sales_report_instamart.category"],
    mrp: parseFloat(row["combined_sales_report_instamart.total_gmv"]),
    gst: parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_instamart.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_instamart.total_cogs"]),
  }));
};
