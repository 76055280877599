import React from "react";
import { useSelector } from "react-redux";
import {
  groupedDataByCampaignNameProductRcomm,
  groupedDataByCampaignRecommendation,
  groupedDataByDateRecommendation,
} from "../../../utils/dataGrouping/productRecommendationGrouping";
import AdCampaigns from "./AdCampaigns";
import TopTenCompaignBySpend from "./TopTenCompaignBySpend";
import ProductListingChart from "./ProductListingChart";
import FunnelSkeleton from "../../common/Skeletons/FunnelSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Error from "../../common/Error/Error";

const ProcuctRecommandationCampaignSectionsContainer = () => {
  const { productRecommendation } = useSelector((state) => state.marketing);
  const { loading, data, error } = productRecommendation;

  if (loading) {
    return (
      <div className="w-full">
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>

        <div className="bg-white rounded-xl">
          <ChartSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl"></div>
        <TableSkeleton />
      </div>
    );
  }

  if (error) {
    return <Error />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  const groupedData = groupedDataByCampaignNameProductRcomm(productRecommendation.data);
  const groupedByCampaign = groupedDataByCampaignRecommendation(productRecommendation.data);
  const groupedDataChart = groupedDataByDateRecommendation(productRecommendation.data);

  return (
    <div className="w-full flex flex-col rounded-lg">
      <div>
        <div>
          <TopTenCompaignBySpend groupedData={groupedData} />
        </div>
        <div>
          <ProductListingChart groupedData={groupedDataChart} />
        </div>

        <div className="mt-4">
          <AdCampaigns groupedDataAd={groupedByCampaign} />
        </div>
      </div>
    </div>
  );
};

export default ProcuctRecommandationCampaignSectionsContainer;
