import { IS_DATE_SET_TO_CURRENT } from "../constants/datePickersConstants";

const initialState = {
  isDateSet: false, // Initial state indicating whether the date is set to the current date
};

export const datePickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_DATE_SET_TO_CURRENT:
      return {
        ...state,
        isDateSet: action.payload, // Directly update isDateSet with the payload (true/false)
      };

    default:
      return state;
  }
};
