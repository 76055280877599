import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation } from "react-router-dom";

const SubmenuPortal = ({ items, isVisible, position, onClose }) => {
  const { pathname } = useLocation();
  const submenuRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the submenu
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        onClose(); // Close the submenu
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div
      ref={submenuRef}
      className="submenu-portal absolute bg-navBarColour text-white shadow-lg rounded py-2"
      style={{
        top: `${position.top - 50}px`,
        left: `${position.left + 20}px`,
        zIndex: 9999, // Ensure it's above other elements
      }}
    >
      <div className="triangle-indicator"></div>
      {items.map((item, index) => (
        <Link to={item.path} onClick={onClose} key={index}>
          <div
            className={`py-2 px-8 hover:bg-[rgb(229,231,235,0.1)] cursor-pointer ${
              pathname == item.path ? "bg-[rgb(229,231,235,0.1)]" : ""
            }`}
          >
            {item.name}
          </div>
        </Link>
      ))}
    </div>,
    document.body
  );
};

export default SubmenuPortal;
