import cubejs from "@cubejs-client/core";


// // For Development
// export const cubeApiURL = process.env.REACT_APP_DEVELOPMENT_CUBEJS_API_URL;

// // For Staging 
// export const cubeApiURL = process.env.REACT_APP_STAGING_CUBEJS_API_URL;

// For Production
export const cubeApiURL = process.env.REACT_APP_CUBEJS_API_URL;



export const getCubeJsApiInstance = () => {
  const cubeToken = localStorage.getItem("cubeToken");
  return cubejs(cubeToken, { apiUrl: cubeApiURL });
};
