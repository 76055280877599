import {
  BLINKIT_FULFILLMENT_ERROR,
  BLINKIT_FULFILLMENT_REQUEST,
  BLINKIT_FULFILLMENT_SUCCESS,
  INSTAMART_FULFILLMENT_ERROR,
  INSTAMART_FULFILLMENT_REQUEST,
  INSTAMART_FULFILLMENT_SUCCESS,
  ZEPTO_FULFILLMENT_ERROR,
  ZEPTO_FULFILLMENT_REQUEST,
  ZEPTO_FULFILLMENT_SUCCESS,
} from "../constants/fulfillmentConstants";

const initialState = {
  blinkitFulfillment: {
    loading: false,
    data: null,
    error: null,
  },

  zeptoFulfillment: {
    loading: false,
    data: null,
    error: null,
  },
  instamartFulfillment: {
    loading: false,
    data: null,
    error: null,
  },
};

export const fulfillmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case BLINKIT_FULFILLMENT_REQUEST:
      return {
        ...state,
        blinkitFulfillment: {
          ...state.blinkitFulfillment,
          loading: true,
          error: null,
        },
      };
    case BLINKIT_FULFILLMENT_SUCCESS:
      return {
        ...state,
        blinkitFulfillment: {
          ...state.blinkitFulfillment,
          loading: false,
          data: action.payload,
        },
      };
    case BLINKIT_FULFILLMENT_ERROR:
      return {
        ...state,
        blinkitFulfillment: {
          ...state.blinkitFulfillment,
          loading: false,
          error: action.payload,
        },
      };

    case ZEPTO_FULFILLMENT_REQUEST:
      return {
        ...state,
        zeptoFulfillment: {
          ...state.zeptoFulfillment,
          loading: true,
          error: null,
        },
      };
    case ZEPTO_FULFILLMENT_SUCCESS:
      return {
        ...state,
        zeptoFulfillment: {
          ...state.zeptoFulfillment,
          loading: false,
          data: action.payload,
        },
      };
    case ZEPTO_FULFILLMENT_ERROR:
      return {
        ...state,
        zeptoFulfillment: {
          ...state.zeptoFulfillment,
          loading: false,
          error: action.payload,
        },
      };

    case INSTAMART_FULFILLMENT_REQUEST:
      return {
        ...state,
        instamartFulfillment: {
          ...state.instamartFulfillment,
          loading: true,
          error: null,
        },
      };
    case INSTAMART_FULFILLMENT_SUCCESS:
      return {
        ...state,
        instamartFulfillment: {
          ...state.instamartFulfillment,
          loading: false,
          data: action.payload, // This line should set the data correctly
        },
      };

    case INSTAMART_FULFILLMENT_ERROR:
      return {
        ...state,
        instamartFulfillment: {
          ...state.instamartFulfillment,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
