export const ADD_AVAIL = "ADD_AVAIL";

export const GET_THIRD_PARTY_DISCOUNTING_DATA_REQUEST =
  "GET_THIRD_PARTY_DISCOUNTING_DATA_REQUEST";
export const GET_THIRD_PARTY_DISCOUNTING_DATA_SUCCESS =
  "GET_THIRD_PARTY_DISCOUNTING_DATA_SUCCESS";
export const GET_THIRD_PARTY_DISCOUNTING_DATA_FAILURE =
  "GET_THIRD_PARTY_DISCOUNTING_DATA_FAILURE";

export const SELECTED_DISCOUNTING_CATEGORY = "SELECTED_DISCOUNTING_CATEGORY";
export const SELECTED_DISCOUNTING_CITY = "SELECTED_DISCOUNTING_CITY";
export const SELECT_TYPE = "SELECT_TYPE";
