import dayjs from "dayjs";

// Format selectedDate without the timezone
const formatDateWithoutTimezone = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

const getSingleDayDateRange = (date) => {
  // Start of the day (00:00:00.000)
  const startOfDay = dayjs(date).startOf("day");
  // End of the day (23:59:59.999)
  const endOfDay = dayjs(date).endOf("day");

  // Format the start and end dates using the formatDateWithoutTimezone function
  const formattedStartOfDay = formatDateWithoutTimezone(startOfDay);
  const formattedEndOfDay = formatDateWithoutTimezone(endOfDay);

  // Return the range as an object
  return {
    start: formattedStartOfDay,
    end: formattedEndOfDay,
  };
};


export const productListingQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_blinkit_product_listing.date",
      "marketting_report_blinkit_product_listing.campaign_name",
      "marketting_report_blinkit_product_listing.cpm",
      "marketting_report_blinkit_product_listing.total_roas",
      "marketting_report_blinkit_product_listing.direct_atc",
      "marketting_report_blinkit_product_listing.indirect_atc",
      "marketting_report_blinkit_product_listing.impressions",
      "marketting_report_blinkit_product_listing.indirect_sales",
      "marketting_report_blinkit_product_listing.d_quantities_sold",
      "marketting_report_blinkit_product_listing.in_quantities_sold",
      "marketting_report_blinkit_product_listing.direct_sales",
      "marketting_report_blinkit_product_listing.estimate_budget",
      "marketting_report_blinkit_product_listing.targeting_type",
      "marketting_report_blinkit_product_listing.targeting_value",
      "marketting_report_blinkit_product_listing.new_users_acquired",
      "marketting_report_blinkit_product_listing.most_viewed_position",
    ],
    order: [["marketting_report_blinkit_product_listing.campaign_name", "asc"]],
    timeDimensions: [
      {
        dimension: "marketting_report_blinkit_product_listing.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const productRecommendationQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_blinkit_product_recommendation.campaign_name",
      "marketting_report_blinkit_product_recommendation.date",
      "marketting_report_blinkit_product_recommendation.direct_roas",
      "marketting_report_blinkit_product_recommendation.cpm",
      "marketting_report_blinkit_product_recommendation.direct_atc",
      "marketting_report_blinkit_product_recommendation.indirect_atc",
      "marketting_report_blinkit_product_recommendation.direct_sales",
      "marketting_report_blinkit_product_recommendation.impressions",
      "marketting_report_blinkit_product_recommendation.d_q_sold",
      "marketting_report_blinkit_product_recommendation.in_q_sold",
      "marketting_report_blinkit_product_recommendation.i_sales",
      "marketting_report_blinkit_product_recommendation.n_u_a",
      "marketting_report_blinkit_product_recommendation.e_budg",
    ],
    order: [
      ["marketting_report_blinkit_product_recommendation.campaign_name", "asc"],
    ],
    timeDimensions: [
      {
        dimension:
          "marketting_report_blinkit_product_recommendation.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const skuPerformanceQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_zepto_sku_performance.product_name",
      "marketting_report_zepto_sku_performance.ad_spend",
      "marketting_report_zepto_sku_performance.orders_sku",
      "marketting_report_zepto_sku_performance.detail_page_views",
      "marketting_report_zepto_sku_performance.cpc",
      "marketting_report_zepto_sku_performance.brand_name",
      "marketting_report_zepto_sku_performance.same_sku_orders",
      "marketting_report_zepto_sku_performance.sku_id",
      "marketting_report_zepto_sku_performance.tenant_id",
      "marketting_report_zepto_sku_performance.roi",
      "marketting_report_zepto_sku_performance.ad_impressions",
      "marketting_report_zepto_sku_performance.date_of_report",
      "marketting_report_zepto_sku_performance.ad_revenue",
      "marketting_report_zepto_sku_performance.other_sku_orders",
      "marketting_report_zepto_sku_performance.category",
      "marketting_report_zepto_sku_performance.ad_clicks",
      "marketting_report_zepto_sku_performance.brand_id",
      "marketting_report_zepto_sku_performance.current_date",
    ],
    order: [["marketting_report_zepto_sku_performance.sku_id", "asc"]],
    timeDimensions: [
      {
        dimension: "marketting_report_zepto_sku_performance.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const campaignPerformanceQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_zepto_campaign_performance.primary_key",
      "marketting_report_zepto_campaign_performance.campaign_name",
      "marketting_report_zepto_campaign_performance.tenant_id",
      "marketting_report_zepto_campaign_performance.status",
      "marketting_report_zepto_campaign_performance.revenue",
      "marketting_report_zepto_campaign_performance.date_of_report",
      "marketting_report_zepto_campaign_performance.cpc",
      "marketting_report_zepto_campaign_performance.clicks",
      "marketting_report_zepto_campaign_performance.curr_daily_budget",
      "marketting_report_zepto_campaign_performance.spend",
      "marketting_report_zepto_campaign_performance.cc_date",
      "marketting_report_zepto_campaign_performance.roi",
      "marketting_report_zepto_campaign_performance.impressions",
      "marketting_report_zepto_campaign_performance.current_date",
    ],
    order: [
      ["marketting_report_zepto_campaign_performance.campaign_name", "asc"],
    ],
    timeDimensions: [
      {
        dimension:
          "marketting_report_zepto_campaign_performance.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const marketingReportQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_instamart.campaign_name",
      "marketting_report_instamart.start_date",
      "marketting_report_instamart.end_date",
      "marketting_report_instamart.campaign_status",
      "marketting_report_instamart.keyword",
      "marketting_report_instamart.total_a2c",
      "marketting_report_instamart.total_gmv",
      "marketting_report_instamart.total_roi",
      "marketting_report_instamart.total_impressions",
      "marketting_report_instamart.total_budget_burnt",
      "marketting_report_instamart.total_budget",
      "marketting_report_instamart.product_name",
      "marketting_report_instamart.current_date",
    ],
    order: [["marketting_report_instamart.campaign_name", "asc"]],
    timeDimensions: [
      {
        dimension: "marketting_report_instamart.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};
