// {
//     "marketting_report_blinkit_product_recommendation.campaign_name": "SP-Product Recommendations-A2 Ghee-23-02-2024-11:41AM",
//     "marketting_report_blinkit_product_recommendation.date": "01-07-2024",
//     "marketting_report_blinkit_product_recommendation.direct_roas": "0",
//     "marketting_report_blinkit_product_recommendation.cpm": "392",
//     "marketting_report_blinkit_product_recommendation.direct_atc": "0",
//     "marketting_report_blinkit_product_recommendation.indirect_atc": "1",
//     "marketting_report_blinkit_product_recommendation.direct_sales": "0",
//     "marketting_report_blinkit_product_recommendation.impressions": "597",
//     "marketting_report_blinkit_product_recommendation.d_q_sold": "0",
//     "marketting_report_blinkit_product_recommendation.in_q_sold": "0",
//     "marketting_report_blinkit_product_recommendation.i_sales": "0",
//     "marketting_report_blinkit_product_recommendation.n_u_a": "0",
//     "marketting_report_blinkit_product_recommendation.e_budg": "234.024"
// }

export const groupedDataByCampaignName = (array) => {
  return array.reduce((acc, item) => {
    const {
      "marketting_report_blinkit_product_recommendation.campaign_name": campaignName,
      "marketting_report_blinkit_product_recommendation.e_budg": estimateBudget,
      "marketting_report_blinkit_product_recommendation.direct_sales": directSales,
      "marketting_report_blinkit_product_recommendation.i_sales": indirectSales,
    } = item;

    // Initialize campaign grouping if it does not exist
    if (!acc[campaignName]) {
      acc[campaignName] = {
        totalSpend: 0,
        totalRevenue: 0,
        ROI: 0,
      };
    }

    // Update totalSpend and totalRevenue
    acc[campaignName].totalSpend += parseFloat(estimateBudget) || 0;
    acc[campaignName].totalRevenue += (parseFloat(directSales) || 0) + (parseFloat(indirectSales) || 0);

    // Update ROI
    const { totalSpend, totalRevenue } = acc[campaignName];
    acc[campaignName].ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    return acc;
  }, {});
};

export const groupedDataByDate = (array) => {
  // Step 1: First, calculate totalSpend, totalRevenue, and ROI for each item
  const calculatedArray = array.map((item) => {
    const {
      "marketting_report_blinkit_product_recommendation.e_budg": estimateBudget,
      "marketting_report_blinkit_product_recommendation.direct_sales": directSales,
      "marketting_report_blinkit_product_recommendation.i_sales": indirectSales,
      "marketting_report_blinkit_product_recommendation.cpm": cpm,
      "marketting_report_blinkit_product_recommendation.impressions": impression,
    } = item;

    // Calculate totalSpend and totalRevenue
    const totalSpend = parseFloat(estimateBudget) || 0;
    const totalRevenue = (parseFloat(directSales) || 0) + (parseFloat(indirectSales) || 0);

    // Calculate ROI
    const ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    // Return the item with the calculated values
    return {
      ...item,
      totalSpend,
      totalRevenue,
      ROI,
      cpm,
      impression,
    };
  });

  // Step 2: Group the data by date without recalculating
  const groupedData = calculatedArray.reduce((acc, item) => {
    const { "marketting_report_blinkit_product_recommendation.date": date } = item;

    // Initialize date grouping if it does not exist
    if (!acc[date]) {
      acc[date] = {
        data: [],
      };
    }

    // Push the item into the respective date array
    acc[date].data.push(item);

    return acc;
  }, {});

  // Step 3: Sort the grouped data by date
  const sortedGroupedData = Object.keys(groupedData)
    .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
    .reduce((acc, date) => {
      acc[date] = groupedData[date]; // Rebuild the sorted object
      return acc;
    }, {});

  return sortedGroupedData;
};
