import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { RxCross1 } from "react-icons/rx";
import { RiMenu2Line } from "react-icons/ri";

// Custom style for the modal container
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  p: 0,
};
// Comparator for descending sort
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// Get comparator based on order direction (asc/desc)
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Stable sort function that handles index tie-breaking
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const KeywordInfoModal = ({ pricingData, keyword, selectedSKU, open, handleClose }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("brand");

  // Function to handle sorting column header clicks
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Define the columns in the table header
  const headCells = [
    { id: "brand", label: "Brand Name" },
    { id: "productname", label: "Product Name" },
    { id: "price", label: "Price" },
    { id: "discountprice", label: "Discount Price" },
    { id: "discountpercent", label: "Discount Percent" },
  ];

  // Filter and map the pricing data to rows for display in the table
  const rows = pricingData
    .filter(
      (item) =>
        item["postgres_third_party_visibility.searchkeyword"] === keyword &&
        item["postgres_third_party_visibility.sku_name"] === selectedSKU
    )
    .map((dataItem) => ({
      brand: dataItem["postgres_third_party_visibility.brand"],
      productname: dataItem["postgres_third_party_visibility.productname"],
      price: dataItem["postgres_third_party_visibility.price"],
      discountprice: dataItem["postgres_third_party_visibility.discountprice"],
      discountpercent:
        ((dataItem["postgres_third_party_visibility.price"] -
          dataItem["postgres_third_party_visibility.discountprice"]) /
          dataItem["postgres_third_party_visibility.price"]) *
        100,
    }));

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="flex justify-between items-center mb-4 bg-black text-white p-4 rounded-t-2xl">
            <h2 className="text-xl font-bold flex items-center gap-2">
              <RiMenu2Line className="text-3xl" />
              SKU - {selectedSKU} Pricing for Keyword - <span className="capitalize">{keyword}</span>
            </h2>
            <RxCross1 className="text-xl cursor-pointer" onClick={handleClose} />
          </div>
          <div className="p-8 m-0 max-h-[70vh] overflow-y-scroll">
            <TableContainer component={Paper} className="shadow-lg">
              <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#000",
                          fontSize: "1rem",
                          textAlign: "left",
                          borderTop: "1px solid rgba(224, 224, 224, 1)",
                          boxShadow: `${headCell.id == "brand" && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"}`,
                          zIndex: `${headCell.id == "brand" && "3"}`,
                          minWidth: "200px",
                          position: `${headCell.id == "brand" && "sticky"}`,
                          left: `${headCell.id == "brand" && 0}`,
                          bgcolor: `${headCell.id == "brand" && "white"}`,
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={(event) => handleRequestSort(event, headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        color: "black",
                        fontSize: "1rem",
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3", // Left shadow for this cell,
                        position: "sticky",
                        bgcolor: "white",
                        right: 0,
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map((row, index) => (
                    <TableRow key={index} className="hover:bg-gray-100">
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#000",
                          fontSize: "1rem",
                          textAlign: "left",
                          borderTop: "1px solid rgba(224, 224, 224, 1)",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          minWidth: "200px",
                          position: "sticky",
                          left: 0,
                          bgcolor: "white",
                        }}
                      >
                        {row.brand}
                      </TableCell>
                      <TableCell>{row.productname}</TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>{row.discountprice}</TableCell>
                      <TableCell>{row.discountpercent.toFixed(2)}%</TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color: "black",
                          fontSize: "1rem",
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3", // Left shadow for this cell,
                          position: "sticky",
                          bgcolor: "white",
                          right: 0,
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default KeywordInfoModal;
