import React, { useState, useEffect } from "react";
import { LuTrendingDown } from "react-icons/lu";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";

const groupedDataAverageTime = (data) => {
  // Function to calculate the difference in days
  function calculateDateDifference(lastInwardDateStr, poDateStr) {
    const lastInwardDate = new Date(lastInwardDateStr);
    const poDate = new Date(poDateStr);
    const differenceInMillis = lastInwardDate - poDate;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.round(differenceInMillis / millisecondsPerDay);
  }

  // Object to hold the results
  const result = {};

  // Process each entry
  data?.forEach((entry) => {
    const lastInwardDate = entry["fulfillment_report_instamart.last_inward_date"];
    const poDate = entry["fulfillment_report_instamart.podate"];
    const pocode = entry["fulfillment_report_instamart.pocode"];

    // Check if dates are available for calculation
    if (lastInwardDate && poDate) {
      const difference = calculateDateDifference(lastInwardDate, poDate);
      if (!result[pocode]) {
        result[pocode] = {
          diff: 0,
          count: 0,
        };
      }
      result[pocode].diff += difference;
      result[pocode].count++;
    }
  });
  return result;
};

const PotentialSalesLossInstamartTable = () => {
  const { pslInstamartFulfillment } = useSelector((state) => state.pslFulfillment);
  const { potentialSalesLossInstamart } = useSelector((state) => state.potentailSalesLoss);
  const [pslData, setPslData] = useState([]);
  const { selectedDate } = useDate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const averageTime = groupedDataAverageTime(pslInstamartFulfillment.data);

  const calculatePSL = (potentialSalesLoss) => {
    const pslResults = potentialSalesLoss
      .map((lossItem, index) => {
        const totalInventory = parseFloat(lossItem["combined_inventory_data_instamart.wh_soh"]);

        const dailyRunRate = parseFloat(lossItem["combined_sales_report_instamart.daily_run_rate"]);

        const inventoryDays = totalInventory / dailyRunRate;
        const itemId = lossItem["combined_inventory_data_instamart.item_code"];
        const itemWareHouseName = lossItem["combined_inventory_data_instamart.wh_name"];
        const fulfillmentData = pslInstamartFulfillment?.data?.filter(
          (fulfillmentItem) =>
            fulfillmentItem["fulfillment_report_instamart.sku"] === itemId &&
            fulfillmentItem["fulfillment_report_instamart.whname"] === itemWareHouseName
        );

        if (fulfillmentData.length === 0) {
          return null;
        }

        const lastPoRaisedDate = fulfillmentData
          .map((item) => item["fulfillment_report_instamart.podate"])
          .reduce((latest, current) => (dayjs(current).isAfter(latest) ? current : latest));

        const currentDate = dayjs(selectedDate);
        const lastPoDate = dayjs(lastPoRaisedDate);
        const poRaisedDays = currentDate.diff(lastPoDate, "day");
        const outOfStockDays = averageTime - (poRaisedDays + inventoryDays);
        // If outOfStockDays is negative, skip this item
        if (outOfStockDays <= 0) {
          return null;
        }
        const mrp = parseFloat(lossItem["combined_sales_report_instamart.mrp"]);

        const psl = outOfStockDays * dailyRunRate * mrp;

        return {
          internal_sku_code: lossItem["combined_inventory_data_instamart.brand_internal_sku_code"],
          city: lossItem["combined_inventory_data_instamart.brands_choice"],
          totalInventory,
          daily_run_rate: dailyRunRate,
          lastPoRaisedDate: lastPoDate,
          AVAT: averageTime.toFixed(2),
          outOfStockDays: outOfStockDays,
          PSL: psl,
        };
      })
      .filter((item) => item !== null);

    setPslData(pslResults);
  };

  useEffect(() => {
    if (pslInstamartFulfillment?.data?.length && potentialSalesLossInstamart?.data?.length) {
      calculatePSL(potentialSalesLossInstamart?.data);
    }
  }, [pslInstamartFulfillment?.data, potentialSalesLossInstamart?.data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="bg-white shadow-md rounded-xl ">
      <div>
        <Typography variant="h6" component="h1" className="flex gap-3 items-center pb-4 border-b p-2">
          <div className="p-2 rounded-full bg-gray-200">
            <LuTrendingDown />
          </div>
          Potential Sales Loss
        </Typography>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    minWidth: "200px",
                    position: "sticky",
                    left: 0,
                    bgcolor: "white",
                  }}
                >
                  SKU Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  City
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Total Inventory
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Daily Run Rate
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Last PO Raised Date
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Average Turn Around Time
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  Out Of Stock Days
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#000",
                    fontSize: "1rem",
                    textAlign: "left",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    minWidth: "200px",
                  }}
                >
                  PSL
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pslData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      color: "#667085black",
                      fontSize: "1rem",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      minWidth: "200px",
                      position: "sticky",
                      left: 0,
                      bgcolor: "white",
                    }}
                  >
                    {row.internal_sku_code}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.city}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.totalInventory}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.daily_run_rate.toFixed(2)}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.lastPoRaisedDate}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.AVAT}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.outOfStockDays}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#667085", fontSize: "1rem" }}>
                    {row.PSL.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "black",
                      fontSize: "1rem",
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3", // Left shadow for this cell,
                      position: "sticky",
                      bgcolor: "white",
                      right: 0,
                    }}
                  ></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pslData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default PotentialSalesLossInstamartTable;
