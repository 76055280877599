import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { MdOutlineRateReview } from "react-icons/md";

const getGroupedData = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const city = item["fulfillment_report_zepto.city"];
    const skuName = item["fulfillment_report_zepto.sku_name"];
    const totalGrnQty = parseFloat(item["fulfillment_report_zepto.grn_quantity"]);
    const poQty = parseFloat(item["fulfillment_report_zepto.po_quantity"]);
    const fillRate = parseFloat(item["fulfillment_report_zepto.sku_level_fill_rate"] || 0);

    // Initialize the city object if it doesn't exist
    if (!groupedData[city]) {
      groupedData[city] = {};
    }

    // Initialize the skuName object if it doesn't exist under the city
    if (!groupedData[city][skuName]) {
      groupedData[city][skuName] = {
        rate: 0,
        count: 0,
      };
    }

    // Calculate the ratio and store it
    groupedData[city][skuName].rate += fillRate;
    groupedData[city][skuName].count++;
  });
  return groupedData;
};

const heading = ["City -> SKU", "Average Fill Rate"];

const FillRateZepto = () => {
  const { zeptoFulfillment } = useSelector((state) => state.fulfillment);
  const [expandedCategories, setExpandedCategories] = useState({});

  const filteredData = getGroupedData(zeptoFulfillment.data);
  console.log("filteredData====", filteredData);

  const handleCategoryClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <Paper
      className="w-full rounded-lg shadow overflow-scroll"
      sx={{
        width: "100%",
        overflowX: "auto",
        mb: "2rem",
      }}
    >
      <div className="flex gap-4 items-center p-4 font-bold text-xl">
        <MdOutlineRateReview />
        Fill Rate
      </div>
      <Table
        className="table-auto border-collapse min-w-full"
        stickyHeader
        aria-label="sticky table"
        sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}
      >
        <TableHead>
          <TableRow sx={{ display: "flex" }}>
            {heading.map((head, index) => (
              <TableCell
                key={`heading-${index}`}
                className="text-left text-[#39393D] px-6 py-4 text-[0.7rem] font-bold font-nunito"
                sx={{
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "1rem",
                  textAlign: "left",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: index === 0 && "3",
                  minWidth: index === 0 ? "400px" : "auto",
                  maxWidth: index === 0 ? "400px" : "auto",
                  flexGrow: index == 1 && 1,
                  position: index === 0 && "sticky",
                  left: index === 0 && 0,
                  bgcolor: index === 0 && "white",
                }}
              >
                {head}
              </TableCell>
            ))}
            <TableCell
              sx={{
                borderTop: "1px solid rgba(224, 224, 224, 1)",
                boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                zIndex: "3",
                position: "sticky",
                right: 0,
                bgcolor: "white",
              }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="bg-white">
          {Object.keys(filteredData).map((city) => {
            const calculateSumRatesAndCounts = (data) => {
              let num = 0;

              // Iterate over each SKU in the city
              Object.keys(data[city]).forEach((sku) => {
                const { rate, count } = data[city][sku];
                num += rate / count;
              });
              return num / Object.keys(data[city]).length;
            };
            return (
              <React.Fragment key={city}>
                <TableRow
                  sx={{ display: "flex" }}
                  className={`cursor-pointer ${expandedCategories[city] ? "bg-[#E5E5E5]" : ""}`}
                  onClick={() => handleCategoryClick(city)}
                >
                  <TableCell
                    className="flex items-center text-left px-3 gap-2 py-4 text-[0.75rem] font-medium font-body text-[#727278]"
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      minWidth: "400px",
                      maxWidth: "400px",
                      position: "sticky",
                      left: 0,
                      display: "flex",
                    }}
                  >
                    {expandedCategories[city] ? <FaCaretUp /> : <FaCaretDown />}
                    {city}
                  </TableCell>
                  <TableCell
                    className="px-6 py-4 whitespace-nowrap text-left text-[0.7rem] font-normal font-body text-[#727278]"
                    sx={{
                      color: "#667085",
                      fontSize: "1rem",
                      flexGrow: 1,
                    }}
                  >
                    {calculateSumRatesAndCounts(filteredData).toFixed(2)}
                  </TableCell>
                  <TableCell
                    sx={{
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3",
                      position: "sticky",
                      right: 0,
                      bgcolor: "white",
                      minWidth: "auto",
                    }}
                  ></TableCell>
                </TableRow>

                {/* Sub-rows for each SKU under the city */}
                {expandedCategories[city] &&
                  Object.keys(filteredData[city]).map((skuName) => (
                    <TableRow key={skuName} className="bg-[#F0F0F0]" sx={{ display: "flex" }}>
                      <TableCell
                        className="pl-6 text-left text-[0.75rem] font-medium font-body text-[#727278]"
                        sx={{
                          color: "#667085",
                          fontSize: "1rem",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          minWidth: "400px",
                          maxWidth: "400px",
                          position: "sticky",
                          left: 0,
                          paddingLeft: "4rem",
                        }}
                      >
                        {skuName}
                      </TableCell>
                      <TableCell
                        className="px-6 py-4  text-left text-[0.75rem] font-normal font-body"
                        sx={{
                          color: "#667085",
                          fontSize: "1rem",
                          minWidth: "auto",
                          flexGrow: 1,
                        }}
                      >
                        {(filteredData[city][skuName].rate / filteredData[city][skuName].count).toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: "white",
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default FillRateZepto;
