import { useState } from "react";
import { addDays } from "date-fns";

const useDateRange = () => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });

  const updateDateRange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  return { dateRange, updateDateRange };
};

export default useDateRange;
