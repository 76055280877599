// {
//     "marketting_report_blinkit_product_listing.date": "02-07-2024",
//     "marketting_report_blinkit_product_listing.campaign_name": "A2 Cow Ghee Recommendation Ads",
//     "marketting_report_blinkit_product_listing.cpm": "500",
//     "marketting_report_blinkit_product_listing.total_roas": "1.794249201",
//     "marketting_report_blinkit_product_listing.direct_atc": "5",
//     "marketting_report_blinkit_product_listing.indirect_atc": "1",
//     "marketting_report_blinkit_product_listing.impressions": "1565",
//     "marketting_report_blinkit_product_listing.indirect_sales": "0",
//     "marketting_report_blinkit_product_listing.d_quantities_sold": "2",
//     "marketting_report_blinkit_product_listing.in_quantities_sold": "0",
//     "marketting_report_blinkit_product_listing.direct_sales": "1404",
//     "marketting_report_blinkit_product_listing.estimate_budget": "782.5",
//     "marketting_report_blinkit_product_listing.targeting_type": "Category",
//     "marketting_report_blinkit_product_listing.targeting_value": "A2 & Cow Ghee",
//     "marketting_report_blinkit_product_listing.new_users_acquired": "2",
//     "marketting_report_blinkit_product_listing.most_viewed_position": "5"
// }

export const groupedDataByCampaignNameAndKeyword = (array) => {
  return array.reduce((acc, item) => {
    const {
      "marketting_report_blinkit_product_listing.campaign_name": campaignName,
      "marketting_report_blinkit_product_listing.targeting_value":
        targetingValue,
      "marketting_report_blinkit_product_listing.estimate_budget":
        estimateBudget,
      "marketting_report_blinkit_product_listing.direct_sales": directSales,
      "marketting_report_blinkit_product_listing.indirect_sales": indirectSales,
    } = item;

    // Initialize campaign grouping if it does not exist
    if (!acc[campaignName]) {
      acc[campaignName] = {};
    }

    // Initialize targeting value grouping if it does not exist
    if (!acc[campaignName][targetingValue]) {
      acc[campaignName][targetingValue] = {
        totalSpend: 0,
        totalRevenue: 0,
        ROI: 0,
      };
    }

    // Update totalSpend and totalRevenue
    acc[campaignName][targetingValue].totalSpend +=
      parseFloat(estimateBudget) || 0;
    acc[campaignName][targetingValue].totalRevenue +=
      (parseFloat(directSales) || 0) + (parseFloat(indirectSales) || 0);

    // Update ROI
    const { totalSpend, totalRevenue } = acc[campaignName][targetingValue];
    acc[campaignName][targetingValue].ROI =
    totalSpend > 0 ? totalRevenue / totalSpend : 0;

    return acc;
  }, {});
};

export const groupedDataByDate = (array) => {
  // Step 1: First, calculate totalSpend, totalRevenue, and ROI for each item
  const calculatedArray = array.map((item) => {
    const {
      "marketting_report_blinkit_product_listing.estimate_budget": estimateBudget,
      "marketting_report_blinkit_product_listing.direct_sales": directSales,
      "marketting_report_blinkit_product_listing.indirect_sales": indirectSales,
      "marketting_report_blinkit_product_listing.cpm": cpm,
      "marketting_report_blinkit_product_listing.most_viewed_position": mvp,
      "marketting_report_blinkit_product_listing.impressions": impression,
    } = item;

    // Calculate totalSpend and totalRevenue
    const totalSpend = parseFloat(estimateBudget) || 0;
    const totalRevenue = (parseFloat(directSales) || 0) + (parseFloat(indirectSales) || 0);

    // Calculate ROI
    const ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    // Return the item with the calculated values
    return {
      ...item,
      totalSpend,
      totalRevenue,
      ROI,
      cpm,
      mvp,
      impression,
    };
  });

  // Step 2: Group the data by date without recalculating
  const groupedData = calculatedArray.reduce((acc, item) => {
    const { "marketting_report_blinkit_product_listing.date": date } = item;

    // Initialize date grouping if it does not exist
    if (!acc[date]) {
      acc[date] = {
        data: [],
      };
    }

    // Push the item into the respective date array
    acc[date].data.push(item);

    return acc;
  }, {});

  // Step 3: Sort the grouped data by date
  const sortedGroupedData = Object.keys(groupedData)
    .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
    .reduce((acc, date) => {
      acc[date] = groupedData[date]; // Rebuild the sorted object
      return acc;
    }, {});

  return sortedGroupedData;
};
