import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
} from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const OverStockedTableZepto = () => {
  // Pagination and Sorting state
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("inventoryDays");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { stockingInsightZepto } = useSelector((state) => state.stocking);

  const { loading, data } = stockingInsightZepto;
  // if (!stockingInsightZepto || stockingInsightZepto.length === 0) {
  //   return <div>No data available</div>;
  // }

  const dataWithTotalInventory = data.map((item) => {
    const totalInventory = parseInt(item["combined_inventory_data_zepto.total_quantity"], 10);
    return {
      ...item,
      totalInventory,
    };
  });

  const groupedData = {};
  dataWithTotalInventory.forEach((item) => {
    const key = `${item["combined_inventory_data_zepto.sku_name"]}_${item["combined_inventory_data_zepto.brand_internal_sku_code"]}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  const newModifiedData = [];
  Object.keys(groupedData).forEach((key) => {
    const group = groupedData[key];

    group.sort(
      (a, b) =>
        new Date(a["combined_inventory_data_zepto.normalized_date"]) -
        new Date(b["combined_inventory_data_zepto.normalized_date"])
    );

    const latestEntry = group[group.length - 1];

    let dailyRunRate = parseFloat(latestEntry["combined_sales_report_zepto.daily_run_rate"]) || 0;

    let inventoryDays = 0;
    if (dailyRunRate > 0) {
      inventoryDays = latestEntry.totalInventory / dailyRunRate;
    }

    newModifiedData.push({
      ...latestEntry,
      dailyRunRate,
      inventoryDays: isNaN(inventoryDays) ? 0 : inventoryDays,
    });
  });


  const filteredData = newModifiedData.filter((item) => item.inventoryDays > 10);



  // Sorting logic
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === "inventoryDays" || orderBy === "totalInventory") {
      return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    } else {
      return order === "asc" ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <Paper className="w-full rounded-lg shadow" sx={{ width: "100%", overflow: "hidden", mb: "2rem" }}>
      <>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
            <TableHead className="bg-white">
              <TableRow>
                {[
                  { id: "product_name", label: "Zepto SKU Name" },
                  { id: "brands_choice", label: "City" },
                  { id: "store_name", label: "Facility Name" },
                  { id: "item_code", label: "Item Code" },
                  { id: "dailyRunRate", label: "Daily Run Rate" },
                  { id: "totalInventory", label: "Total Inventory" },
                  { id: "inventoryDays", label: "Inventory Days" },
                ].map((column) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    sx={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "1rem",
                      textAlign: "left",
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                      boxShadow: `${column.id === "product_name" && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"}`,
                      zIndex: `${column.id === "product_name" && "3"}`,
                      minWidth: "200px",
                      position: `${column.id === "product_name" && "sticky"}`,
                      left: `${column.id === "product_name" && 0}`,
                      bgcolor: `${column.id === "product_name" && "white"}`,
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length > 0 ? (
                paginatedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      className="text-[0.75rem] text-[#727278]"
                      sx={{
                        color: "black",
                        fontSize: "1rem",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        minWidth: "200px",
                        position: "sticky",
                        left: 0,
                        bgcolor: "white",
                      }}
                    >
                      {item["combined_inventory_data_zepto.sku_name"]}
                    </TableCell>
                    <TableCell className="text-[0.75rem] text-[#727278]" sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item["combined_inventory_data_zepto.brands_choice"]}
                    </TableCell>

                    <TableCell className="text-[0.75rem] text-[#727278]" sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item["combined_inventory_data_zepto.store_name"]}
                    </TableCell>

                    <TableCell className="text-[0.75rem] text-[#727278]" sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item["combined_inventory_data_zepto.sku_id"]}
                    </TableCell>
                    <TableCell className="text-[0.75rem] text-[#727278]" sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item.dailyRunRate}
                    </TableCell>
                    <TableCell className="text-[0.75rem] text-[#727278]" sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item.totalInventory}
                    </TableCell>
                    <TableCell className="text-[0.75rem] text-[#727278]" sx={{ color: "#667085", fontSize: "1rem" }}>
                      {item.inventoryDays}
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "white",
                      }}
                    ></TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="text-center text-[0.75rem] text-[#727278]">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    </Paper>
  );
};

export default OverStockedTableZepto;
