import {
  STOCKING_INSIGHTS_BLINKIT_REQUEST,
  STOCKING_INSIGHTS_BLINKIT_SUCCESS,
  STOCKING_INSIGHTS_BLINKIT_FAILURE,
  STOCKING_INSIGHTS_INSTAMART_REQUEST,
  STOCKING_INSIGHTS_INSTAMART_SUCCESS,
  STOCKING_INSIGHTS_INSTAMART_FAILURE,
  STOCKING_INSIGHTS_ZEPTO_REQUEST,
  STOCKING_INSIGHTS_ZEPTO_FAILURE,
  STOCKING_INSIGHTS_ZEPTO_SUCCESS,
} from "../constants/stockingInsightsConstants";

const initialState = {
  stockingInsightBlinkit: {
    loading: false,
    data: [],
    error: null,
  },
  stockingInsightInstamart: {
    loading: false,
    data: [],
    error: null,
  },
  stockingInsightZepto: {
    loading: false,
    data: [],
    error: null,
  },
};

export const stockingInsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCKING_INSIGHTS_BLINKIT_REQUEST:
      return {
        ...state,
        stockingInsightBlinkit: {
          ...state.stockingInsightBlinkit,
          loading: true,
          data: [],
          error: null,
        },
      };

    case STOCKING_INSIGHTS_BLINKIT_SUCCESS:
      return {
        ...state,
        stockingInsightBlinkit: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case STOCKING_INSIGHTS_BLINKIT_FAILURE:
      return {
        ...state,
        stockingInsightBlinkit: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case STOCKING_INSIGHTS_INSTAMART_REQUEST:
      return {
        ...state,
        stockingInsightInstamart: {
          ...state.stockingInsightInstamart,
          loading: true,
          data: [],
          erorr: null,
        },
      };

    case STOCKING_INSIGHTS_INSTAMART_SUCCESS:
      return {
        ...state,
        stockingInsightInstamart: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case STOCKING_INSIGHTS_INSTAMART_FAILURE:
      return {
        ...state,
        stockingInsightInstamart: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case STOCKING_INSIGHTS_ZEPTO_REQUEST:
      return {
        ...state,
        stockingInsightZepto: {
          ...state.stockingInsightZepto,
          loading: true,
          data: [],
          erorr: null,
        },
      };

    case STOCKING_INSIGHTS_ZEPTO_SUCCESS:
      return {
        ...state,
        stockingInsightZepto: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case STOCKING_INSIGHTS_ZEPTO_FAILURE:
      return {
        ...state,
        stockingInsightZepto: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};
