import React from "react";
import { useSelector } from "react-redux";
import FunnelChart from "./FunnelChart";
import { AiFillFunnelPlot } from "react-icons/ai";

const TopTenKeywordsByBudget = ({ groupedData }) => {
  // Extract the values from the object and convert them into an array
  const valuesArray = Object.values(groupedData);

  // Sort the array in descending order based on the budget
  const sortedArray = valuesArray?.sort((a, b) => b.estimateBudget - a.estimateBudget);

  // Get the top 10 objects with the highest budget
  const top10 = sortedArray.slice(0, 10);

  return (
    <div className="bg-white shadow-md rounded-xl ">
      <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
        <div className="p-2 rounded-full bg-gray-200">
          <AiFillFunnelPlot />
        </div>
        Top 10 keywords By Budget
      </h1>
      <div className="flex p-4 flex-wrap justify-between gap-4">
        {top10?.map((item, index) => (
          <FunnelChart obj={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default TopTenKeywordsByBudget;
