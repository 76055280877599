// {
//     "marketting_report_zepto_campaign_performance.primary_key": "27",
//     "marketting_report_zepto_campaign_performance.campaign_name": "A2 Ghee",
//     "marketting_report_zepto_campaign_performance.tenant_id": "2",
//     "marketting_report_zepto_campaign_performance.status": "ACTIVE",
//     "marketting_report_zepto_campaign_performance.revenue": "41635",
//     "marketting_report_zepto_campaign_performance.date_of_report": "09-08-2024",
//     "marketting_report_zepto_campaign_performance.cpc": "57.30625",
//     "marketting_report_zepto_campaign_performance.clicks": "259",
//     "marketting_report_zepto_campaign_performance.curr_daily_budget": "35000",
//     "marketting_report_zepto_campaign_performance.spend": "14842.32",
//     "marketting_report_zepto_campaign_performance.cc_date": "2022-11-02",
//     "marketting_report_zepto_campaign_performance.roi": "2.8052",
//     "marketting_report_zepto_campaign_performance.impressions": "34616",
//     "marketting_report_zepto_campaign_performance.current_date": "2024-08-12T00:00:00.000"
// }

export const groupedDataByCampaignName = (array) => {
  return array.reduce((acc, item) => {
    const {
      "marketting_report_zepto_campaign_performance.campaign_name":
        campaignName,
      "marketting_report_zepto_campaign_performance.spend": estimateBudget,
      "marketting_report_zepto_campaign_performance.revenue": revenue,
    } = item;

    // Initialize campaign grouping if it does not exist
    if (!acc[campaignName]) {
      acc[campaignName] = {
        totalSpend: 0,
        totalRevenue: 0,
        ROI: 0,
      };
    }

    // Update totalSpend and totalRevenue with revenue parsed as a float
    acc[campaignName].totalSpend += parseFloat(estimateBudget) || 0;
    acc[campaignName].totalRevenue += parseFloat(revenue) || 0;

    // Update ROI
    const { totalSpend, totalRevenue } = acc[campaignName];
    acc[campaignName].ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    return acc;
  }, {});
};


export const groupedDataByDate = (array) => {
  // Step 1: First, calculate totalSpend, totalRevenue, and ROI for each item
  const calculatedArray = array.map((item) => {
    const {
      "marketting_report_zepto_campaign_performance.spend": estimateBudget,
      "marketting_report_zepto_campaign_performance.revenue": revenue,
      "marketting_report_zepto_campaign_performance.impressions": impression,
    } = item;

    // Calculate totalSpend and totalRevenue
    const totalSpend = parseFloat(estimateBudget) || 0;
    const totalRevenue = revenue;
    // Calculate ROI
    const ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    // Return the item with the calculated values
    return {
      ...item,
      totalSpend,
      totalRevenue,
      ROI,
      impression,
    };
  });

  // Step 2: Group the data by date without recalculating
  const groupedData = calculatedArray.reduce((acc, item) => {
    const {
      "marketting_report_zepto_campaign_performance.cc_date": date,
    } = item;

    // Initialize date grouping if it does not exist
    if (!acc[date]) {
      acc[date] = {
        data: [],
      };
    }

    // Push the item into the respective date array
    acc[date].data.push(item);

    return acc;
  }, {});

  // Step 3: Sort the dates by proper ISO format parsing
  const sortedData = Object.keys(groupedData)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()) // Ensure sorting by time in milliseconds
    .reduce((sortedAcc, date) => {
      sortedAcc[date] = groupedData[date];
      return sortedAcc;
    }, {});

  return sortedData;
};
