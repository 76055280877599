import React from "react";

const CardComponent = ({ totalAmount, title, icon, unit }) => {
  return (
    <div className="p-4 border bg-white rounded-lg shadow-md flex items-center space-x-4">
      <div className="text-3xl">{icon}</div>
      <div>
        <h2 className="text-xl font-semibold">{title}</h2>
        <p className="text-lg">
          {unit} {totalAmount?.toFixed(0)}
        </p>
      </div>
    </div>
  );
};

export default CardComponent;
