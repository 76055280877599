import React, { useCallback, useEffect, useState } from "react";
import { BsGraphUpArrow } from "react-icons/bs";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const ProductListingChart = ({ groupedData }) => {
  const [hiddenDatasets, setHiddenDatasets] = useState([]);
  const [showLeftYAxis, setShowLeftYAxis] = useState(true);
  const [showRightYAxis, setShowRightYAxis] = useState(true);

  const data = groupedData;

  // Sort the dates in ascending order
  const sortedDates = Object.keys(data).sort((a, b) => {
    const [dayA, monthA, yearA] = a.split("-").map(Number);
    const [dayB, monthB, yearB] = b.split("-").map(Number);
    return (
      new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB)
    );
  });

  

  const addToCartData = sortedDates.map((date) => data[date].addToCart);
  const totalQuantitiesSoldData = sortedDates.map(
    (date) => data[date].totalQuantitiesSold
  );
  const estimateBudgetData = sortedDates.map(
    (date) => data[date].estimateBudget
  );
  const cpmData = sortedDates.map((date) => data[date].cpm / data[date].count);

  const chartData = {
    labels: sortedDates,
    datasets: [
      {
        label: "Add to Cart",
        data: addToCartData,
        borderColor: "#4BC0C0",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        pointBorderColor: "#4BC0C0",
        pointBackgroundColor: "#4BC0C0",
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("Add to Cart"),
      },
      {
        label: "Total Quantities Sold",
        data: totalQuantitiesSoldData,
        borderColor: "#9966FF",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        pointBorderColor: "#9966FF",
        pointBackgroundColor: "#9966FF",
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("Total Quantities Sold"),
      },
      {
        label: "Total Spent",
        data: estimateBudgetData,
        borderColor: "#FF9F40",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        pointBorderColor: "#FF9F40",
        pointBackgroundColor: "#FF9F40",
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: false,
        tension: 0.4,
        yAxisID: "left-y-axis",
        hidden: hiddenDatasets.includes("Total Spent"),
      },
      {
        label: "CPM",
        data: cpmData,
        borderColor: "#36A2EB",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        pointBorderColor: "#36A2EB",
        pointBackgroundColor: "#36A2EB",
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("CPM"),
      },
    ],
  };

  const getAxisTitles = useCallback(() => {
    const leftAxisLabels = chartData.datasets
      .filter((dataset) => dataset.yAxisID === "left-y-axis" && !dataset.hidden)
      .map((dataset) => dataset.label)
      .join(" & ");

    const rightAxisLabels = chartData.datasets
      .filter(
        (dataset) => dataset.yAxisID === "right-y-axis" && !dataset.hidden
      )
      .map((dataset) => dataset.label)
      .join(" & ");

    return {
      left: leftAxisLabels || "Total Quantities Spent",
      right: rightAxisLabels || "Add to Cart, CPM & Sold Quantities",
    };
  }, [hiddenDatasets, chartData.datasets]);

  const updateAxisVisibility = useCallback(() => {
    const leftYAxisHidden = chartData.datasets.every(
      (dataset) =>
        dataset.yAxisID === "left-y-axis" &&
        hiddenDatasets.includes(dataset.label)
    );

    const rightYAxisHidden = chartData.datasets.every(
      (dataset) =>
        dataset.yAxisID === "right-y-axis" &&
        hiddenDatasets.includes(dataset.label)
    );

    setShowLeftYAxis(!leftYAxisHidden);
    setShowRightYAxis(!rightYAxisHidden);
  }, [hiddenDatasets, chartData.datasets]);

  useEffect(() => {
    updateAxisVisibility();
  }, [hiddenDatasets, updateAxisVisibility]);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to be more responsive
    plugins: {
      title: {
        display: true,
        text: "Sales Metrics",
        font: {
          size: 18,
          weight: "bold",
        },
        color: "#333",
      },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true, // Makes legend items more modern with point styles
          padding: 20,
        },
        onClick: (event, legendItem) => {
          const datasetLabel = legendItem.text;
          setHiddenDatasets((prev) =>
            prev.includes(datasetLabel)
              ? prev.filter((label) => label !== datasetLabel)
              : [...prev, datasetLabel]
          );
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0,0,0,0.7)",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 12,
        },
        cornerRadius: 4,
        padding: 10,
      },
    },
    scales: {
      "left-y-axis": {
        display: showLeftYAxis,
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: getAxisTitles().left,
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#333",
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
        },
        grid: {
          drawBorder: false,
        },
      },
      "right-y-axis": {
        display: showRightYAxis,
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: getAxisTitles().right,
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#333",
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
        },
        grid: {
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Dates",
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#333",
        },
        ticks: {
          color: "#333",
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
      line: {
        borderWidth: 3,
      },
    },
  };

  return (
    <div className="shadow-md rounded-xl mb-8 mt-4 bg-white">
      <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
        <div className="p-2 rounded-full bg-gray-200">
          <BsGraphUpArrow />
        </div>
        Datewise Overview
      </h1>

      <div className="relative h-96 p-6">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ProductListingChart;
