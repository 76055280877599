import React, { useEffect, useState } from "react";
import Toolbar from "../../components/Toolbar/Toolbar";

import {
  fetchBlinkitData,
  fetchInstamartData,
  fetchZeptoData,
} from "../../services/revenueServices/salesReportServices";
import { useDispatch, useSelector } from "react-redux";
import { getAllSalesSuccess, getAllSalesFailure, getAllSalesRequest } from "../../redux/actions/salesActions";
import { MdAreaChart } from "react-icons/md";
import { useDateRange } from "../../hooks/CustomDateRange/CustomDateRangePickerProvider";
import ChartSkeleton from "../../components/common/Skeletons/ChartSkeleton";
import NoDataFound from "../../components/common/NoDataFound/NoDataFound";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import CategorySkuSplitSectionsContainer from "../../components/revenue/salesReportScreen/CategorySkuSplitSectionsContainer";
import CityWiseSkuSplitSectionsContainer from "../../components/revenue/salesReportScreen/CityWiseSkuSplitSectionsContainer";
import DailySalesAndOrdersPerDay from "../../components/revenue/salesReportScreen/DailySalesAndOrdersPerDay";
import CategoryFilterSelector from "../../components/revenue/salesReportScreen/CategoryFilterSelector";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";

// Array containing the different options for viewing the data
const options = ["Category Wise SKU Split", "City Wise SKU Split", "Orders Per Day", "Sales Per Day"];

// Object mapping platform names to their respective data fetch functions
const dataFetchFunctions = {
  Blinkit: fetchBlinkitData,
  Instamart: fetchInstamartData,
  Zepto: fetchZeptoData,
};

const SalesReportScreen = () => {
  const { totalSales } = useSelector((state) => state.sales); // Accessing sales data from Redux store
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const { selectedDateRange } = useDateRange();

  const dispatch = useDispatch();

  // Reset selectedOption to 0 (Category Wise SKU Split) whenever the platformName changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  // Fetch sales data whenever platformName, selectedDateRange, or selectedOption changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getAllSalesRequest());
        const result = await dataFetchFunctions[platformName](selectedDateRange);
        dispatch(getAllSalesSuccess(result));
      } catch (error) {
        dispatch(getAllSalesFailure(error));
        console.error(`Error loading sales report data for ${platformName}`, error);
      }
    };

    fetchData();
  }, [platformName, selectedDateRange, selectedOption]);

  return (
    <>
      {/* HeadingBar allows platform and date selection */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDateRange} />

      <Toolbar selectedDate={selectedDateRange}>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Render category-wise SKU split if selectedOption is 0 */}
        {selectedOption === 0 && <CategorySkuSplitSectionsContainer platformName={platformName} />}

        {/* Render city-wise SKU split if selectedOption is 1 */}
        {selectedOption === 1 && <CityWiseSkuSplitSectionsContainer platformName={platformName} />}

        {/* Render orders per day if selectedOption is 2 */}
        {selectedOption === 2 && (
          <>
            {/* Show loading skeleton if the data is still loading from api */}
            {totalSales.loading ? (
              <ChartSkeleton />
            ) : !totalSales.data || totalSales.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <div className="bg-white mt-4 shadow-md rounded-xl mb-4">
                <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
                  {/* Orders Per Day section with icon */}
                  <div>
                    <MdAreaChart />
                  </div>
                  Orders Per Day
                </h1>
                {/* Category filter for the Orders Per Day view */}
                <div className="ml-4">
                  <CategoryFilterSelector platformName={platformName} />
                </div>
                <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
                  <DailySalesAndOrdersPerDay selectedOption={selectedOption} platformName={platformName} />
                </div>
              </div>
            )}
          </>
        )}

        {/* Render sales per day if selectedOption is 3 */}
        {selectedOption === 3 && (
          <>
            {/* Show loading skeleton if the data is still loading from api */}
            {totalSales.loading ? (
              <ChartSkeleton />
            ) : !totalSales.data || totalSales.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <div className="bg-white mt-4 shadow-md rounded-xl mb-4">
                <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
                  {/* Sales Per Day section with icon */}
                  <div>
                    <MdAreaChart />
                  </div>
                  Sales Per Day
                </h1>
                {/* Category filter for the Sales Per Day view */}
                <div className="ml-4">
                  <CategoryFilterSelector platformName={platformName} />
                </div>
                <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
                  <DailySalesAndOrdersPerDay selectedOption={selectedOption} platformName={platformName} />
                </div>
              </div>
            )}
          </>
        )}
      </Toolbar>
    </>
  );
};

export default SalesReportScreen;

// Use Case:
/*
This code defines a SalesReportScreen component that allows users to view sales data for different platforms (Blinkit, Zepto, Instamart). 
Users can toggle between different views such as "Category Wise SKU Split" and "Sales Per Day", while fetching and displaying data based on the selected platform and date range.
*/
