import {
  ADD_CATEGORIES,
  ADD_CHART_DATA,
  ADD_FINAL_CATEGORY,
  ADD_FINAL_CHART_DATA,
  ADD_FINAL_DATE,
  ADD_FINAL_MONTH,
  RESET_FILTER,
} from "../constants/chartConstants";

const initialState = {
  categories: [],
  finalDate: "",
  finalMonth: "June",
  finalCategory: "",
  finalChartData: {},
 
};

export const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORIES:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case ADD_CHART_DATA:
      return {
        ...state,
        laoding: false,
        chartData: action.payload,
        error: null,
      };

    case ADD_FINAL_DATE:
      return {
        ...state,
        loading: false,
        finalDate: action.payload,
      };
    case ADD_FINAL_MONTH:
      return {
        ...state,
        loading: false,
        finalMonth: action.payload,
      };
    case ADD_FINAL_CATEGORY:
      return {
        ...state,
        loading: false,
        finalCategory: action.payload,
      };
    case ADD_FINAL_CHART_DATA:
      return {
        ...state,
        loading: false,
        finalCategory: action.payload,
      };
    case RESET_FILTER:
      return { ...state, finalCategory: "", finalDate: "", finalMonth: "" };
    default:
      return state;
  }
};
