import {
  LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_REQUEST,
  LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_SUCCESS,
  LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_FAILURE,
  LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST,
  LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS,
  LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE,
  LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_REQUEST,
  LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_SUCCESS,
  LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_FAILURE,
  LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_REQUEST,
  LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_SUCCESS,
  LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_FAILURE,
  LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_REQUEST,
  LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_SUCCESS,
  LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_FAILURE,
} from "../constants/leastPerformingConstants";

const initialState = {
  leastPerformingBlinkitProductListing: {
    loading: false,
    data: null,
    error: null,
  },
  leastPerformingBlinkitProductRecommendation: {
    loading: false,
    data: null,
    error: null,
  },
  leastPerformingZeptoSkuPerformance: {
    loading: false,
    data: null,
    error: null,
  },
  leastPerformingCampaignPerformanceSku: {
    loading: false,
    data: null,
    error: null,
  },
  leastPerformingInstamartMarketingReport: {
    loading: false,
    data: null,
    error: null,
  },
};

export const leastPerformingReducer = (state = initialState, action) => {
  switch (action.type) {
    // Blinkit Product Listing
    case LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_REQUEST:
      return {
        ...state,
        leastPerformingBlinkitProductListing: {
          loading: true,
          error: null,
        },
      };
    case LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_SUCCESS:
      return {
        ...state,
        leastPerformingBlinkitProductListing: {
          loading: false,
          data: action.payload,
        },
      };
    case LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_FAILURE:
      return {
        ...state,
        leastPerformingBlinkitProductListing: {
          loading: false,
          error: action.payload,
        },
      };

    // Blinkit Product Recommendation
    case LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST:
      return {
        ...state,
        leastPerformingBlinkitProductRecommendation: {
          loading: true,
          error: null,
        },
      };
    case LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        leastPerformingBlinkitProductRecommendation: {
          loading: false,
          data: action.payload,
        },
      };
    case LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE:
      return {
        ...state,
        leastPerformingBlinkitProductRecommendation: {
          loading: false,
          error: action.payload,
        },
      };

    // Zepto SKU Performance
    case LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_REQUEST:
      return {
        ...state,
        leastPerformingZeptoSkuPerformance: {
          loading: true,
          error: null,
        },
      };
    case LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_SUCCESS:
      return {
        ...state,
        leastPerformingZeptoSkuPerformance: {
          loading: false,
          data: action.payload,
        },
      };
    case LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_FAILURE:
      return {
        ...state,
        leastPerformingZeptoSkuPerformance: {
          loading: false,
          error: action.payload,
        },
      };

    // Campaign Performance SKU
    case LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_REQUEST:
      return {
        ...state,
        leastPerformingCampaignPerformanceSku: {
          loading: true,
          error: null,
        },
      };
    case LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_SUCCESS:
      return {
        ...state,
        leastPerformingCampaignPerformanceSku: {
          loading: false,
          data: action.payload,
        },
      };
    case LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_FAILURE:
      return {
        ...state,
        leastPerformingCampaignPerformanceSku: {
          loading: false,
          error: action.payload,
        },
      };

    // Instamart Marketing Report
    case LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_REQUEST:
      return {
        ...state,
        leastPerformingInstamartMarketingReport: {
          loading: true,
          error: null,
        },
      };
    case LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_SUCCESS:
      return {
        ...state,
        leastPerformingInstamartMarketingReport: {
          loading: false,
          data: action.payload,
        },
      };
    case LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_FAILURE:
      return {
        ...state,
        leastPerformingInstamartMarketingReport: {
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
