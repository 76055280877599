import React from "react";

const Toolbar = ({ children }) => {
  return (
    <div>
      <div className=" rounded-lg flex-1 flex-col items-center mt-[1rem] mx-8">
        <div className="mb-4"></div>
        {children}
      </div>
    </div>
  );
};

export default Toolbar;
