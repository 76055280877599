import {
  productListingQuery,
  productRecommendationQuery,
  skuPerformanceQuery,
  campaignPerformanceQuery,
  marketingReportQuery,
} from "../../utils/queries/performanceMarketingAdsQuery";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchPerformanceMarketingAdsProductListingDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(productListingQuery(selectedDate));
  return result.tablePivot();
};

export const fetchPerformanceMarketingAdsProductRecommendationDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(productRecommendationQuery(selectedDate));
  return result.tablePivot();
};

export const fetchPerformanceMarketingAdsSkuPerformanceDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(skuPerformanceQuery(selectedDate));
  return result.tablePivot();
};

export const fetchPerformanceMarketingAdsCampaignPerformanceDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(campaignPerformanceQuery(selectedDate));
  return result.tablePivot();
};

export const fetchPerformanceMarketingAdsMarketingReportDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(marketingReportQuery(selectedDate));
  return result.tablePivot();
};
