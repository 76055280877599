// top 10 campaigns by keyword
export const groupedDataByKeywordsInstamart = (arr) => {
  const groupedData = arr?.reduce((acc, item) => {
    const title = item["marketting_report_instamart.keyword"];
    const impressions =
      parseInt(item["marketting_report_instamart.total_impressions"], 10) || 0;
    const addToCarts =
      parseInt(item["marketting_report_instamart.total_a2c"], 10) || 0;
    const totalQuantitiesSold = 0;
    const estimateBudget =
      parseFloat(item["marketting_report_instamart.total_budget_burnt"], 10) ||
      0;

    const cpm = 0;

    if (!acc[title]) {
      acc[title] = {
        cpm: 0,
        impressions: 0,
        addToCarts: 0,
        totalQuantitiesSold: 0,
        estimateBudget: 0,
        title: title,
        // count: 0,
      };
    }

    acc[title].impressions += impressions;
    acc[title].addToCarts += addToCarts;
    acc[title].totalQuantitiesSold += totalQuantitiesSold;
    acc[title].estimateBudget += estimateBudget;
    acc[title].cpm += cpm;
    // acc[title].count += 1;

    return acc;
  }, {});
  return groupedData;
};

// chart grouping
export const groupedDataByDateInstamart = (arr) => {
  const obj = arr?.reduce((acc, curr) => {
    const date = curr["marketting_report_instamart.start_date"];

    if (!acc[date]) {
      acc[date] = {
        impressions: 0,
        totalQuantitiesSold: 0,
        addToCart: 0,
        estimateBudget: 0,
        cpm: 0,
        count: 0,
      };
    }

    acc[date].impressions +=
      parseInt(curr["marketting_report_instamart.total_impressions"], 10) || 0;
    acc[date].addToCart +=
      parseInt(curr["marketting_report_instamart.total_a2c"], 10) || 0;
    // acc[date].totalQuantitiesSold +=
    //   parseInt(
    //     curr["marketting_report_blinkit_product_listing.d_quantities_sold"],
    //     10
    //   ) +
    //   parseInt(
    //     curr["marketting_report_blinkit_product_listing.in_quantities_sold"],
    //     10
    //   );

    // acc[date].cpm += parseInt(
    //   curr["marketting_report_blinkit_product_listing.cpm"],
    //   10
    // );

    acc[date].estimateBudget +=
      parseFloat(curr["marketting_report_instamart.total_budget"], 10) || 0;
    // acc[date].count += 1;

    return acc;
  }, {});
  return obj;
};

// top 10 keyword by spend grouping
export const groupedDataByCampaignNameInstamart = (data) => {
  return data?.reduce((acc, item) => {
    const title = item["marketting_report_instamart.campaign_name"];

    if (!acc[title]) {
      acc[title] = {
        title,
 
        totaRoas: 0,
        directAddToCart: 0,
        indirectAddToCart: 0,
        addToCarts: 0,
        impressions: 0,
        indirectSales: 0,
        directQuantitiesSold: 0,
        indirectQuantitiesSold: 0,
        totalQuantitiesSold: 0,
        directSales: 0,
        estimateBudget: 0,
        newUsersAcquired: 0,
        count: 0,
      };
    }

    // Aggregating values
    // acc[title].cpm += parseFloat(
    //   item["marketting_report_instamart.total_a2c"] || 0
    // ); // This is just an example, adjust based on actual fields
    acc[title].totaRoas += parseFloat(
      item["marketting_report_instamart.total_roi"] || 0
    );
    acc[title].directAddToCart += parseInt(
      item["marketting_report_instamart.total_a2c"] || 0,
      10
    ); // Assuming total_a2c represents directAddToCart
    acc[title].indirectAddToCart += 0; // No direct equivalent in provided data
    acc[title].addToCarts =
      acc[title].directAddToCart + acc[title].indirectAddToCart;
    acc[title].impressions += parseInt(
      item["marketting_report_instamart.total_impressions"] || 0,
      10
    );
    acc[title].indirectSales += 0; // No direct equivalent in provided data
    acc[title].directQuantitiesSold += 0; // No direct equivalent in provided data
    acc[title].indirectQuantitiesSold += 0; // No direct equivalent in provided data
    acc[title].totalQuantitiesSold =
      acc[title].directQuantitiesSold + acc[title].indirectQuantitiesSold;
    acc[title].directSales += parseFloat(
      item["marketting_report_instamart.total_gmv"] || 0
    ); // Assuming total_gmv represents directSales
    acc[title].estimateBudget += parseFloat(
      item["marketting_report_instamart.total_budget_burnt"] || 0
    );
    acc[title].newUsersAcquired += 0; // No direct equivalent in provided data

    acc[title].count += 1;

    return acc;
  }, {});
};

// accordian table grouping
export const groupedDataByCampaignName = (data) => {
  return data?.reduce((acc, item) => {
    const campaignName = item["marketting_report_instamart.campaign_name"];

    if (!acc[campaignName]) {
      acc[campaignName] = [];
    }

    acc[campaignName].push({
      productName: item["marketting_report_instamart.product_name"],
      startDate: item["marketting_report_instamart.start_date"],
      endDate: item["marketting_report_instamart.end_date"],
      campaignStatus: item["marketting_report_instamart.campaign_status"],
      keyword: item["marketting_report_instamart.keyword"],
      totalA2C: item["marketting_report_instamart.total_a2c"],
      totalGmv: item["marketting_report_instamart.total_gmv"],
      totalRoi: item["marketting_report_instamart.total_roi"],
      totalImpressions: item["marketting_report_instamart.total_impressions"],
      totalBudgetBurnt: item["marketting_report_instamart.total_budget_burnt"],
      totalBudget: item["marketting_report_instamart.total_budget"],
    });

    return acc;
  }, {});
};
