// LoginScreen.js
import React, { useState } from "react";
import "./loginScreen.css";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField/InputField";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { authService } from "../../services/authentication/authService";
import { useAlert } from "../../components/common/Alert/AlertProvider ";

const LoginScreen = () => {
  const [formData, setFormData] = useState({ userId: "", password: "" });
  const [alert, setAlert] = useState(null);
  const { showAlert } = useAlert();
  const [errors, setErrors] = useState({
    userId: "",
    password: "",
    invalid: "",
  });

  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = { userId: "", password: "" };

    if (!formData.userId) {
      newErrors.userId = "User ID is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.userId)) {
      newErrors.userId = "Invalid User ID format.";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const response = await authService.signinUser({
      email: formData.userId,
      password: formData.password,
    });
    if (response.success) {
      showAlert("success", response.message);
      navigate("/revenue/sales-report"); // Navigate to the desired route
      localStorage.setItem("cubeToken", response.cubeToken);
      localStorage.setItem("token", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));
    } else {
      // showAlert("error", response.message);
      setErrors((prevErrors) => ({
        ...prevErrors,
        invalid: response.message,
      }));
    }
  };
  const handleForgotPassword = () => {
    window.location.href = "https://fillflow.us/login";
  };

  const handleContactMuul = () => {
    window.location.href = "https://fillflow.us/login";
  };

  return (
    <div className="flex h-[100vh] w-[100vw] justify-between">
      {/* left container */}
      {alert}
      <div className="w-[50vw] flex flex-col">
        <div>
          <img
            className="p-[1.75rem]"
            src="/logos/brandLogo.svg"
            alt="Brand Logo"
          />
        </div>
        <div className="flex justify-center text-[#343844] mt-[5.598rem] text-loginMainHeading font-heading">
          <span>Welcome</span>
        </div>
        <div className="p-[2rem]">
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="flex flex-col  justify-center items-center">
              <div className="flex flex-col">
                <label className="text-[0.875rem] font-medium not-italic">
                  Email
                </label>
                <InputField
                  type="text"
                  name="userId"
                  value={formData.userId}
                  onChange={handleChange}
                  placeholder="Enter your email id"
                  sx={{
                    width: "24.0625rem",
                    Height: "5rem",
                    border: "1px solid var(--Neutral-N500, #9EA5BD)",
                    borderRadius: "0.375rem",
                  }}
                />
                {errors.userId && (
                  <div className="">
                    <span className="text-xs text-red-400">
                      {errors.userId}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col mt-[0.6rem]">
                <div className="flex justify-between  font-normal">
                  <label className="text-[0.875rem] font-medium not-italic">
                    Password
                  </label>
                  <span
                  // onClick={handleForgotPassword}
                  // className="hover:cursor-pointer text-[0.625rem]"
                  >
                    {/* Forgot password? */}
                  </span>
                </div>
                <InputField
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your Password"
                  sx={{
                    width: "24.0625rem",
                    Height: "5rem",
                    border: "1px solid var(--Neutral-N500, #9EA5BD)",
                    borderRadius: "0.375rem",
                  }}
                />

                {errors.password && (
                  <div>
                    <span className="text-xs text-red-400">
                      {errors.password}
                    </span>
                  </div>
                )}
              </div>
              {errors.invalid && (
                <div>
                  <span className="text-xs text-red-400">{errors.invalid}</span>
                </div>
              )}
              <div className="mt-[1.5rem]">
                <PrimaryButton
                  buttonText="Log In"
                  sx={{ width: "24.0625rem", fontSize: "0.87rem" }}
                  type="submit"
                  onClick={handleSubmit}
                />
              </div>
              {/* <div className="hover:cursor-pointer text-[0.625rem] mt-[0.5rem] font-normal">
                <span>Don’t have an account ? </span>
                <span
                  onClick={handleContactMuul}
                  className="text-mainBrandColour"
                >
                  Contact Muul
                </span>
              </div> */}
            </div>
          </form>
        </div>
      </div>

      {/* right container */}
      <div className="flex flex-col gap-[3.375rem] justify-center items-center w-[50vw] bg-navBarColour">
        <div>
          <img src="/logos/3D_brand_logo.svg" alt="3D Brand Logo" />
        </div>
        <div className="">
          <div className="loginHeading flex flex-col items-center justify-center">
            <span className="text-loginHeading drop-shadow font-heading text-backgroundWhite text-stroke-1 text-stroke-black">
              AI Insights: Real-Time Data for
            </span>
            <span className="text-loginHeading drop-shadow-lg font-heading text-backgroundWhite text-stroke-1 text-stroke-black">
              Enhanced Operations and Growth
            </span>
          </div>
          <div className="flex mt-[1rem] flex-col items-center justify-center">
            <span className="text-loginHeading2 font-body text-backgroundWhite">
              Accelerate data-driven decisions tenfold with
            </span>
            <span className="text-loginHeading2 font-body text-backgroundWhite">
              advanced analytics.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
