import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross1 } from "react-icons/rx";
import { RiMenu2Line } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  p: 0,
  overflow: "hidden", // Ensures the modal contents don't overflow
};

const SkuCityLevelAvailabilityModal = ({ open, handleClose, data, selectedSku }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjusted opacity here
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* Heading container */}
          <div className="flex justify-between items-center mb-4 bg-black text-white p-4 rounded-t-2xl">
            <h2 className="text-xl font-bold flex items-center gap-2">
              <RiMenu2Line className="text-3xl" />
              Pincode Level Availability for SKU - {selectedSku}
            </h2>
            <RxCross1 className="text-xl cursor-pointer" onClick={handleClose} />
          </div>

          {/* Content container */}
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "24px",
              backgroundColor: "white",
              borderRadius: "0 0 16px 16px",
            }}
            className="p-6 bg-white rounded-b-2xl"
          >
            <h2 className="text-xl font-bold mb-4">Details for {data?.city}</h2>
            <ul className="list-none">
              {data?.details?.map((detail, index) => (
                <li key={index} className="flex gap-4 items-center mb-2">
                  <span className="w-40">Pincode: {detail?.pincode}</span>
                  <span className="w-40">Area Name: {detail?.areaName}</span>
                  <div className="p-2">
                    <span
                      className={`w-40 p-1 gap-2 rounded text-center ${
                        detail?.stockListing !== 1
                          ? "bg-gray-300 text-white" // You can change this color to something that represents "Not Listed"
                          : detail?.stockBinary === "1"
                          ? "bg-green-300 text-white"
                          : "bg-red-300 text-white"
                      }`}
                    >
                      {detail?.stockListing !== 1
                        ? "Not Listed"
                        : detail?.stockBinary === "1"
                        ? "Available"
                        : "Not Available"}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SkuCityLevelAvailabilityModal;
